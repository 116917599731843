import React, {useEffect} from 'react';
import {motion} from 'framer-motion';
import {AuroraBackground} from '../ui/AuroraBackground';
import {
    FaAward,
    FaBolt,
    FaChartLine,
    FaCogs,
    FaHandsHelping,
    FaHeart,
    FaLightbulb,
    FaRecycle,
    FaShieldAlt,
    FaUsers,
} from 'react-icons/fa';
import {Cloudinary} from '@cloudinary/url-gen';
import {AdvancedImage} from '@cloudinary/react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import About2xWDSection from "../components/About2xWDSection.tsx";
import TrackableButton from "../components/TrackableButton.tsx";
import {Helmet} from "react-helmet-async";
import i18n from "i18next";

gsap.registerPlugin(ScrollTrigger);

interface CustomCSSProperties extends React.CSSProperties {
    '--quantity'?: number;
    '--position'?: number;
}

const AboutUs: React.FC = () => {
    const {t} = useTranslation();

    const cld = new Cloudinary({
        cloud: {
            cloudName: import.meta.env.VITE_CLOUDINARY_CLOUD_NAME,
        },
    });

    const myImage = cld.image(import.meta.env.VITE_CLOUDINARY_ABOUT_PHOTO_PUBLIC_ID);

    const values = [
        {
            icon: <FaLightbulb className="text-6xl text-green_brand mb-4"/>,
            title: t("about-values-card-title-1"),
            description: t("about-values-card-text-1"),
        },
        {
            icon: <FaUsers className="text-6xl text-green_brand mb-4"/>,
            title: t("about-values-card-title-2"),
            description: t("about-values-card-text-2"),
        },
        {
            icon: <FaAward className="text-6xl text-green_brand mb-4"/>,
            title: t("about-values-card-title-3"),
            description: t("about-values-card-text-3"),
        },
        {
            icon: <FaHeart className="text-6xl text-green_brand mb-4"/>,
            title: t("about-values-card-title-4"),
            description: t("about-values-card-text-4"),
        },
        {
            icon: <FaBolt className="text-6xl text-green_brand mb-4"/>,
            title: t("about-values-card-title-5"),
            description: t("about-values-card-text-5"),
        },
        {
            icon: <FaChartLine className="text-6xl text-green_brand mb-4"/>,
            title: t("about-values-card-title-6"),
            description: t("about-values-card-text-6"),
        },
        {
            icon: <FaShieldAlt className="text-6xl text-green_brand mb-4"/>,
            title: t("about-values-card-title-7"),
            description: t("about-values-card-text-7"),
        },
        {
            icon: <FaHandsHelping className="text-6xl text-green_brand mb-4"/>,
            title: t("about-values-card-title-8"),
            description: t("about-values-card-text-8"),
        },
        {
            icon: <FaRecycle className="text-6xl text-green_brand mb-4"/>,
            title: t("about-values-card-title-9"),
            description: t("about-values-card-text-9"),
        },
        {
            icon: <FaCogs className="text-6xl text-green_brand mb-4"/>,
            title: t("about-values-card-title-10"),
            description: t("about-values-card-text-10"),
        },
    ];

    useEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".employee-spotlight",
                start: "top center",
                end: "bottom center",
                scrub: true,
            },
        });
        tl.fromTo(
            ".zigzag-arrow path",
            {strokeDashoffset: 1000},
            {strokeDashoffset: 0, duration: 3, ease: "none"}
        );
    }, []);

    return (
        <AuroraBackground>
            <Helmet>
                {/* General SEO */}
                <title>{t('helmet-about-hero-title')}</title>
                <meta name="description" content={t('helmet-about-hero-description')}/>
                <meta name="keywords" content={t('helmet-about-hero-keywords')}/>

                {/* Open Graph Tags for Social Sharing */}
                <meta property="og:title" content={t('helmet-about-hero-title')}/>
                <meta property="og:description" content={t('helmet-about-hero-description')}/>
                <meta property="og:image" content={myImage.toURL()}/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={t("helmet-about-content-url")}/>

                {/* Twitter Card Tags */}
                <meta name="twitter:card" content={t("helmet-about-twitter-card")}/>
                <meta name="twitter:title" content={t('helmet-about-hero-title')}/>
                <meta name="twitter:description" content={t('helmet-about-hero-description')}/>
                <meta name="twitter:image" content={myImage.toURL()}/>

                {/* Additional SEO and Meta Tags */}
                <meta name="robots" content="index, follow"/>
                <link rel="canonical"
                      href={i18n.language === 'de'
                          ? 'https://www.2xwd.de/ueber-uns'
                          : 'https://www.2xwd.com/about'}/>

                {/* hreflang tags for alternate language versions */}
                <link rel="alternate" href="https://www.2xwd.de/ueber-uns" hrefLang="de"/>
                <link rel="alternate" href="https://www.2xwd.com/about" hrefLang="en"/>
            </Helmet>

            <motion.div
                initial={{opacity: 0.0, y: 40}}
                whileInView={{opacity: 1, y: 0}}
                transition={{
                    delay: 0.3,
                    duration: 0.8,
                    ease: 'easeInOut',
                }}
                className="relative flex flex-col gap-8 items-center justify-center w-full"
            >
                {/* Hero Section */}
                <div
                    className="about-hero-section w-full h-[700px] sm:h-[500px] md:h-[600px] lg:h-[700px] bg-cover bg-center flex items-center text-white">
                    <div className="w-full max-w-[1600px] mx-auto px-4 md:px-10">
                        <div className="ml-10 max-w-md md:max-w-lg xl:ml-20 2xl:ml-40">
                            <h1 className="text-3xl md:text-5xl font-semibold pb-4 h-full md:leading-[3.5rem]">
                                {t("about-hero-text-1")}
                                <span
                                    className="text-green_brand"> {t("about-hero-text-2")}</span>{t("about-hero-text-4")}
                            </h1>
                            <p className="text-md mb-10">{t("about-hero-title-text")}</p>
                            <Link to={`/${t("route-contact")}`}>
                                <TrackableButton
                                    category="CTA"
                                    action="Click"
                                    label="About CTA button"
                                    className="btn">
                                    {t("cta-button-text")}
                                </TrackableButton>
                            </Link>
                        </div>
                    </div>
                </div>

                <About2xWDSection/>

                {/* Company Values */}
                {/*<Helmet>*/}
                {/*    <title>{t("helmet-about-values-title")}</title>*/}
                {/*    <meta name="description"*/}
                {/*          content={t("helmet-about-values-description")}/>*/}
                {/*    <meta name="keywords"*/}
                {/*          content={t("helmet-about-values-keywords")}/>*/}
                {/*</Helmet>*/}
                <div className="banner mt-10 text-center px-4 pt-16">
                    <h2 className="text-3xl md:text-5xl font-semibold dark:text-black">{t("about-values-title")}</h2>
                    <div className="slider mt-32" style={{'--quantity': 10} as CustomCSSProperties}>
                        {values.map((value, index) => (
                            <div
                                key={index}
                                className="item flex flex-col items-center absolute bg-white p-4 sm:p-3 md:p-4 lg:p-6 rounded-lg shadow-lg"
                                style={{'--position': index + 1} as CustomCSSProperties}
                            >
                                <div className="text-4xl sm:text-3xl md:text-5xl lg:text-6xl text-green_brand mb-4">
                                    {value.icon}
                                </div>
                                <h3 className="text-xl sm:text-lg md:text-2xl lg:text-2xl text-black font-bold dark:text-black text-center">
                                    {value.title}
                                </h3>
                                <p className="text-sm sm:text-xs md:text-sm lg:text-[0.75rem] text-neutral-400 dark:text-neutral-300 text-center max-w-xs">
                                    {value.description}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>


                {/* Employee Spotlight */}
                {/*<Helmet>*/}
                {/*    <title>{t("helmet-about-employee-title")}</title>*/}
                {/*    <meta name="description"*/}
                {/*          content={t("helmet-about-employee-description")}/>*/}
                {/*    <meta name="keywords"*/}
                {/*          content={t("helmet-about-employee-keywords")}/>*/}
                {/*</Helmet>*/}
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    whileInView={{opacity: 1, y: 0}}
                    transition={{delay: 0.4, duration: 1, ease: "easeInOut"}}
                    className="employee-spotlight mt-20 text-center px-4 py-16">
                    <h2 className="text-3xl md:text-5xl text-black font-semibold dark:text-black mb-24">{t("about-employee-header")}</h2>
                    <div className="flex flex-col lg:flex-row w-full items-center justify-center">
                        <div className="flex flex-col items-center max-w-2xl">
                            <AdvancedImage cldImg={myImage} alt="my image"
                                           className="w-full bg-cover rounded-lg mb-4"/>
                            <div className="text-left">
                                <h3 className="text-3xl font-bold mb-2 dark:text-black text-center">{t("about-employee-name-2")}</h3>
                                {/*<p className="text-lg font-semibold text-green_brand text-center">{t("about-employee-title-2")}</p>*/}
                            </div>
                        </div>
                        <div>
                            <p className="flex text-base md:text-lg dark:text-neutral-400 text-left max-w-2xl lg:pl-10">
                                {t("about-employee-text-2")}
                            </p>
                        </div>
                    </div>
                    <div className="mt-8">
                        <svg className="w-full h-36 zigzag-arrow" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 10 1000 100">
                            <path d="M0 70 Q 250 20, 500 80 T 1100 0" fill="transparent" stroke="#00ADB5"
                                  strokeWidth="5"/>
                        </svg>
                    </div>
                </motion.div>

                {/* Call to Action */}
                {/*<Helmet>*/}
                {/*    <title>{t("helmet-about-cta-title")}</title>*/}
                {/*    <meta name="description"*/}
                {/*          content={t("helmet-about-cta-description")}/>*/}
                {/*    <meta name="keywords"*/}
                {/*          content={t("helmet-about-cta-keywords")}/>*/}
                {/*</Helmet>*/}
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    whileInView={{opacity: 1, y: 0}}
                    transition={{delay: 0.4, duration: 1, ease: "easeInOut"}}
                    className="my-20 text-center px-4 py-16">
                    <h2 className="text-3xl text-black md:text-5xl font-semibold dark:text-black mb-10">{t("about-cta-title-1")}
                        <span className="text-green_brand"> {t("about-cta-title-2")}</span> {t("about-cta-title-3")}
                    </h2>
                    <p className="text-xl text-black md:text-2xl dark:text-black mt-2 mb-10 max-w-6xl">
                        {t("about-cta-text-1")} <span
                        className="text-green_brand">{t("about-cta-text-2")}</span> {t("about-cta-text-3")} <span
                        className="text-green_brand">{t("about-cta-text-4")}</span> {t("about-cta-text-5")} <span
                        className="text-green_brand">{t("about-cta-text-6")}</span>{t("about-cta-text-7")}
                    </p>
                    <Link to={`/${t("route-contact")}`}>
                        <TrackableButton
                            category="CTA"
                            action="Click"
                            label="About CTA bottom section button"
                            className="btn">
                            {t("cta-button-text")}
                        </TrackableButton>
                    </Link>
                </motion.div>
            </motion.div>
        </AuroraBackground>
    );
};

export default AboutUs;
