import React, {createContext, ReactNode, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

interface LanguageContextType {
    language: string;
    setLanguage: (language: string) => void;
}

const defaultContext: LanguageContextType = {
    language: 'en',
    setLanguage: () => {
    },
};

const LanguageContext = createContext<LanguageContextType>(defaultContext);

interface LanguageProviderProps {
    children: ReactNode;
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({children}) => {
    const {i18n} = useTranslation();
    const [language, setLanguage] = useState(localStorage.getItem('i18nextLng') || 'en');

    useEffect(() => {
        const savedLanguage = localStorage.getItem('i18nextLng') || (navigator.language.startsWith('de') ? 'de' : 'en');
        setLanguage(savedLanguage);
        i18n.changeLanguage(savedLanguage);
    }, [i18n]);

    const changeLanguage = (lng: string) => {
        localStorage.setItem('i18nextLng', lng);
        setLanguage(lng);
        i18n.changeLanguage(lng);
    };

    return (
        <LanguageContext.Provider value={{language, setLanguage: changeLanguage}}>
            {children}
        </LanguageContext.Provider>
    );
};
