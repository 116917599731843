import {useEffect, useRef, useState} from "react";
import {animate, useInView} from "framer-motion";
import {useTranslation} from "react-i18next";
import {t} from "i18next";

export const CountUpStats = () => {
    const {t} = useTranslation();

    return (
        <>
            {/*<Helmet>*/}
            {/*    /!*<title>{t("helmet-home-CountUpStats-title")}</title>*!/*/}
            {/*    <meta*/}
            {/*        name="description"*/}
            {/*        content={t("helmet-home-CountUpStats-description")}*/}
            {/*    />*/}
            {/*    <meta*/}
            {/*        name="keywords"*/}
            {/*        content={t("helmet-home-CountUpStats-keywords")}*/}
            {/*    />*/}
            {/*</Helmet>*/}

            <div className="german-count-up mx-auto max-w-3xl px-4 py-20 md:py-24 font-poppins">
                <h2 className="mb-8 text-center text-base text-green_dark_brand sm:text-4xl md:mb-16">
                    {t("count-up-stats-header-1")}<br/>
                    <span className="text-green_brand"> {t("count-up-stats-header-2")}</span>
                </h2>

                <div className="flex flex-col items-center justify-center sm:flex-row">
                    <Stat
                        num={94}
                        suffix="%"
                        subheading={t("count-up-stats-text-1")}
                    />
                    <div className="h-[1px] w-12 bg-green_brand sm:h-12 sm:w-[1px]"/>
                    <Stat
                        num={45}
                        suffix="%"
                        subheading={t("count-up-stats-text-2")}
                    />
                    <div className="h-[1px] w-12 bg-green_brand sm:h-12 sm:w-[1px]"/>
                    <Stat
                        num={78}
                        suffix="%"
                        subheading={t("count-up-stats-text-3")}
                    />
                </div>
            </div>
        </>
    );
};

interface Props {
    num: number;
    suffix: string;
    decimals?: number;
    subheading: string;
}

const Stat = ({num, suffix, decimals = 0, subheading}: Props) => {
    const ref = useRef<HTMLSpanElement | null>(null);
    const isInView = useInView(ref);
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => setIsOpen(!isOpen);

    useEffect(() => {
        if (!isInView) return;

        animate(0, num, {
            duration: 2.5,
            onUpdate(value) {
                if (!ref.current) return;

                ref.current.textContent = value.toFixed(decimals);
            },
        });
    }, [num, decimals, isInView]);

    const getSource = (num: number) => {
        switch (num) {
            case 94:
                return (
                    <div className="collapsible-content">
                        <a href="https://www.forrester.com/report/Website-Design-Impacts-Customer-Experience"
                           target="_blank" rel="noopener noreferrer">
                            Forrester Research
                        </a>
                    </div>
                );
            case 45:
                return (
                    <div className="collapsible-content">
                        <a href="https://www.searchenginejournal.com/45-of-consumers-search-google-before-purchasing/315071/"
                           target="_blank" rel="noopener noreferrer">
                            Search Engine Journal
                        </a>
                    </div>
                );
            case 78:
                return (
                    <div className="collapsible-content">
                        <a href="https://www.brightlocal.com/research/local-consumer-review-survey/" target="_blank"
                           rel="noopener noreferrer">
                            BrightLocal
                        </a>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="flex w-72 flex-col items-center py-8 sm:py-0">
            <p className="mb-2 text-center text-7xl font-semibold sm:text-6xl">
                <span ref={ref}></span>
                {suffix}
            </p>
            <p className="max-w-48 text-center text-neutral-600">{subheading}</p>
            <button onClick={toggleOpen} className="stat-toggle text-xs text-blue-500">
                {isOpen ? t("count-up-stats-source-no-show") : t("count-up-stats-source-show")}
            </button>
            {isOpen && getSource(num)}
        </div>
    );
};