import {useTranslation} from "react-i18next";
import {RiSeoLine} from "react-icons/ri";
import {DiGoogleAnalytics} from "react-icons/di";
import {GrConnect} from "react-icons/gr";

const Seo3Cards = () => {
    const {t} = useTranslation();

    return (
        <section className="py-36 bg-[#ededed] font-poppins">
            <div className="container mx-auto px-4">
                <h1 className="text-black text-center pb-12">{t("seo-title-1")} <span
                    className="text-green_brand">{t("seo-title-2")}</span></h1>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div
                        className="bg-white p-6 rounded-lg shadow-lg text-center transition duration-300 ease-in-out hover:shadow:lg hover:shadow-green_brand">
                        <a href={"#seo"} className="smooth-scroll">
                            <RiSeoLine className="text-5xl text-green_brand mb-4 mx-auto"/>
                            <h2 className="text-2xl text-black font-bold mb-4">{t("seo-card-title-1")}</h2>
                            <p className="text-neutral-400">{t("seo-card-text-1")}
                            </p>
                        </a>
                    </div>
                    <div
                        className="bg-white p-6 rounded-lg shadow-lg text-center transition duration-300 ease-in-out hover:shadow:lg hover:shadow-green_brand">
                        <DiGoogleAnalytics className="text-5xl text-green_brand mb-4 mx-auto"/>
                        <h2 className="text-2xl text-black font-bold mb-4">{t("seo-card-title-2")}</h2>
                        <p className="text-neutral-400">{t("seo-card-text-2")}</p>
                    </div>
                    <div
                        className="bg-white p-6 rounded-lg shadow-lg text-center transition duration-300 ease-in-out hover:shadow:lg hover:shadow-green_brand">
                        <GrConnect className="text-5xl text-green_brand mb-4 mx-auto"/>
                        <h2 className="text-2xl text-black font-bold mb-4">{t("seo-card-title-3")}</h2>
                        <p className="text-neutral-400">{t("seo-card-text-3")}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Seo3Cards
