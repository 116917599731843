import {motion} from "framer-motion";
import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {useTranslation} from "react-i18next";

gsap.registerPlugin(ScrollTrigger);

const TopLeftCircle = () => {
    return (
        <motion.div
            initial={{rotate: "0deg"}}
            animate={{rotate: "360deg"}}
            transition={{duration: 100, ease: "linear", repeat: Infinity}}
            className="w-[450px] h-[450px] rounded-full border-2 border-green_brand border-dotted absolute z-0 -left-[250px] -top-[200px]"
        />
    );
};

const BottomRightCircle = () => {
    return (
        <motion.div
            initial={{rotate: "0deg"}}
            animate={{rotate: "-360deg"}}
            transition={{duration: 100, ease: "linear", repeat: Infinity}}
            className="w-[450px] h-[450px] rounded-full border-2 border-green_brand border-dotted absolute z-0 -right-[250px] -bottom-[200px]"
        />
    );
};

const About2xWDSection = () => {
    const {t} = useTranslation();

    return (
        <section className="w-full py-20 px-10 relative overflow-hidden">
            <TopLeftCircle/>
            <BottomRightCircle/>
            <div className="container mx-auto relative z-10 max-w-screen-2xl">
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    whileInView={{opacity: 1, y: 0}}
                    transition={{delay: 0.1, duration: 0.8, ease: "easeInOut"}}
                    className="text-center"
                >
                    <h2 className="text-3xl md:text-5xl mb-4"><span
                        className="text-green_brand">{t("about-hero-text-2")}</span>{t("about-hero-text-3")} {t("about-2xwd-title")}
                    </h2>
                </motion.div>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    whileInView={{opacity: 1, y: 0}}
                    transition={{delay: 0.1, duration: 0.8, ease: "easeInOut"}}
                    className="max-w-7xl mx-auto"
                >
                    <h1 className="text-2xl md:text-4xl font-semibold pb-4 leading-snug md:leading-relaxed text-center">

                    </h1>
                    <p className="text-base text-black md:text-lg mb-10 text-left">
                        {t("about-2xwd-text-1")}
                        <br/><br/>
                        {t("about-2xwd-text-2")}
                        <br/><br/>
                        {t("about-2xwd-text-3")}
                    </p>
                </motion.div>
            </div>
        </section>
    );
};

export default About2xWDSection;
