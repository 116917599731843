import {motion} from "framer-motion";
import {AuroraBackground} from "../ui/AuroraBackground";
import {FaRegClock, FaShieldAlt, FaTachometerAlt} from "react-icons/fa";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TrackableButton from "./TrackableButton.tsx";

export function AuroraBg() {
    const {t} = useTranslation();

    return (
        <AuroraBackground>
            <motion.div
                initial={{opacity: 0.0, y: 40}}
                whileInView={{opacity: 1, y: 0}}
                transition={{
                    delay: 0.3,
                    duration: 0.8,
                    ease: "easeInOut",
                }}
                className="relative flex flex-col gap-4 items-center justify-center px-4 pt-20"
            >
                <div className="mt-10 text-center">
                    <h1 className="text-4xl md:text-6xl font-semibold text-black dark:text-black">
                        {t("support-aurora-title")}
                    </h1>
                    <p className="text-xl md:text-2xl text-neutral-700 dark:text-neutral-700 pt-12">
                        {t("support-aurora-text")}
                    </p>
                </div>

                <div
                    className="mt-16 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-8 pb-28 text-left items-center justify-center">
                    <motion.div
                        initial={{opacity: 0, y: 20}}
                        whileInView={{opacity: 1, y: 0}}
                        transition={{delay: 0.4, duration: 0.8, ease: "easeInOut"}}
                        className="bg-white animated-support-card2 dark:bg-zinc-800 h-[320px] w-[330px] lg:w-[300px] xl:w-[330px] rounded-lg p-8 shadow-lg flex flex-col items-center"
                    >
                        <FaRegClock className="text-4xl md:text-6xl text-green_brand mb-4"/>
                        <h2 className="text-2xl text-black font-bold mb-4 dark:text-black text-center">
                            {t("support-aurora-card-title-1")}
                        </h2>
                        <p className="text-base text-left md:text-lg text-neutral-400 dark:text-neutral-400">
                            {t("support-aurora-card-text-1")}
                        </p>
                    </motion.div>

                    <motion.div
                        initial={{opacity: 0, y: 20}}
                        whileInView={{opacity: 1, y: 0}}
                        transition={{delay: 0.6, duration: 0.8, ease: "easeInOut"}}
                        className="bg-white animated-support-card2 dark:bg-zinc-800 h-[320px] w-[330px] lg:w-[300px] xl:w-[330px] rounded-lg p-8 shadow-lg flex flex-col items-center"
                    >
                        <FaTachometerAlt className="text-4xl md:text-6xl text-green_brand mb-4"/>
                        <h2 className="text-2xl text-black font-bold mb-4 dark:text-black text-center">
                            {t("support-aurora-card-title-2")}
                        </h2>
                        <p className="text-base text-left md:text-lg text-neutral-400 dark:text-neutral-400">
                            {t("support-aurora-card-text-2")}
                        </p>
                    </motion.div>

                    <motion.div
                        initial={{opacity: 0, y: 20}}
                        whileInView={{opacity: 1, y: 0}}
                        transition={{delay: 0.8, duration: 0.8, ease: "easeInOut"}}
                        className="bg-white animated-support-card2 dark:bg-zinc-800 h-[320px] w-[330px] lg:w-[300px] xl:w-[330px] rounded-lg p-8 shadow-lg flex flex-col items-center"
                    >
                        <FaShieldAlt className="text-4xl md:text-6xl text-green_brand mb-4"/>
                        <h2 className="text-2xl text-black font-bold mb-4 dark:text-black text-center">
                            {t("support-aurora-card-title-3")}
                        </h2>
                        <p className="text-base text-left md:text-lg text-neutral-400 dark:text-neutral-400">
                            {t("support-aurora-card-text-3")}
                        </p>
                    </motion.div>
                </div>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    whileInView={{opacity: 1, y: 0}}
                    transition={{delay: 0.8, duration: 0.8, ease: "easeInOut"}}
                    className="flex flex-col items-center mb-24"
                >
                    <p className="text-base mb-6 text-black dark:text-black text-center">
                        {t("support-aurora-text-2")}
                    </p>
                    <h2 className="text-2xl font-bold text-black dark:text-black text-center pb-6">
                        {t("support-aurora-price")} <small>{t("support-aurora-price-text")}</small>
                    </h2>
                    <Link to={`/${t("route-contact")}`}>
                        <TrackableButton
                            category="CTA"
                            action="Click"
                            label="Support CTA button"
                            className="btn">
                            {t("support-aurora-button")}
                        </TrackableButton>
                    </Link>
                </motion.div>
            </motion.div>
        </AuroraBackground>
    );
}
