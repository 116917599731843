import {useTranslation} from 'react-i18next';
import {useEffect} from 'react';
import {IoIosArrowDown} from 'react-icons/io';
import {useAutoAnimate} from '@formkit/auto-animate/react';

const languages = [
    {code: 'en', lang: '🇺🇸', url: 'http://2xwd.com'},
    {code: 'de', lang: '🇩🇪', url: 'https://2xwd.de'},
];

const LanguageSelector = ({closeSideMenu}: { closeSideMenu?: () => void }) => {
    const {i18n} = useTranslation();

    const handleChange = async (lng: string, url: string) => {
        // Ensure the language is changed first
        await i18n.changeLanguage(lng);

        // Set the language explicitly in local storage so it persists
        localStorage.setItem('i18nextLng', lng);

        // Only redirect to a different domain if you're switching between .com and .de
        if (window.location.hostname !== new URL(url).hostname) {
            window.location.href = url;
        } else {
            // If already on the correct domain, reload the page to reflect the new language
            window.location.reload();
        }

        if (closeSideMenu) {
            closeSideMenu(); // Close the mobile nav when a language is selected
        }
    };

    useEffect(() => {
        document.body.dir = i18n.dir();
    }, [i18n, i18n.language]);

    const [animationParent] = useAutoAnimate();

    return (
        <div
            ref={animationParent}
            className="relative group px-3 py-2 flex items-center text-lg uppercase font-bold leading-snug hover:text-green_brand poppins-medium"
        >
            <button
                className="flex cursor-pointer items-center gap-2 text-neutral-400 group-hover:text-green_darker_brand"
            >
                <span className="text-base lg:text-sm text-neutral-400 lg:text-white hover:text-green_brand">
                    {i18n.t('language')}
                </span>
                <IoIosArrowDown className="text-neutral-400 lg:text-white transition-all group-hover:rotate-180"/>
            </button>
            <div
                className="absolute right-12 top-10 hidden w-auto flex-col gap-1 rounded-lg bg-white py-3 shadow-md transition-all group-hover:flex items-center"
            >
                {languages.map((lng) => (
                    <div
                        key={lng.code}
                        className="block px-4 py-2 text-2xl whitespace-nowrap hover:bg-white text-neutral-400 poppins-medium"
                        onClick={() => handleChange(lng.code, lng.url)}
                    >
                        {lng.lang}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LanguageSelector;
