import React from 'react';
import ReactGA from 'react-ga4';

interface TrackableButtonProps {
    category: string;
    action: string;
    label?: string;
    onClick?: () => void;
    className?: string;
    style?: React.CSSProperties;
    children: React.ReactNode;
}

const TrackableButton: React.FC<TrackableButtonProps> = ({
                                                             category,
                                                             action,
                                                             label,
                                                             onClick,
                                                             className,
                                                             style,
                                                             children,
                                                         }) => {
    const handleClick = () => {
        ReactGA.event({
            category,
            action,
            label,
        });

        if (onClick) {
            onClick();
        }
    };

    return (
        <button
            onClick={handleClick}
            className={className}
            style={style}
        >
            {children}
        </button>
    );
};

export default TrackableButton;
