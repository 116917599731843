import React, {useState} from "react";
import {motion} from "framer-motion";
import {cn} from "../utils/cn";

interface TextRevealCardProps {
    text: string;
    revealText: string;
    className?: string;
}

export const TextRevealCard: React.FC<TextRevealCardProps> = ({
                                                                  text,
                                                                  revealText,
                                                                  className,
                                                              }) => {
    const [isRevealed, setIsRevealed] = useState(false);

    return (
        <motion.div
            className={cn(
                "relative overflow-hidden bg-[#1d1c20] border border-white/[0.08] w-full h-56 rounded-lg p-6 flex items-center justify-center",
                className
            )}
            whileHover="hover"
            initial="initial"
            animate={isRevealed ? "hover" : "initial"}
            onTap={() => setIsRevealed(!isRevealed)}
        >
            {/* Text (smaller text with gradient) */}
            <motion.div
                className="relative z-10 text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-b from-transparent via-white to-transparent text-center"
                variants={{
                    initial: {x: 0, opacity: 1},
                    hover: {x: "100%", opacity: 0},
                }}
                transition={{type: "tween", ease: "easeInOut", duration: 0.4}}
            >
                {text}
            </motion.div>

            {/* RevealText (p tag, revealed on hover/tap) */}
            <motion.p
                className="absolute inset-0 flex items-center justify-center text-lg text-white z-0 p-6 text-left"
                variants={{
                    initial: {x: "-100%", opacity: 0},
                    hover: {x: 0, opacity: 1},
                }}
                transition={{type: "tween", ease: "easeInOut", duration: 0.4}}
            >
                {revealText}
            </motion.p>
        </motion.div>
    );
};
