import {useTranslation} from "react-i18next";
import {IoCloudDoneOutline, IoCloudOutline} from "react-icons/io5";
import {LiaHandsHelpingSolid} from "react-icons/lia";
import {useGSAP} from "@gsap/react";
import {animateWithGsap} from "../utils/animations.tsx";
import {SlSpeedometer} from "react-icons/sl";
import {BsSafe} from "react-icons/bs";
import {RxUpdate} from "react-icons/rx";

const SupportCards = () => {
    const {t} = useTranslation();

    useGSAP(() => {
        animateWithGsap(".support-title", {y: -10, opacity: 1, delay: 0.3})
        animateWithGsap('.support-card', {y: -10, opacity: 1, delay: 0.5, stagger: 0.3})
    }, [])

    return (
        <section className="bg-[#ededed] font-poppins w-full flex items-center justify-center py-36">
            <div className="container mx-auto px-4">
                <div className="flex flex-col items-center text-center w-full mb-10">
                    <h1 className="support-title opacity-0 text-4xl md:text-6xl text-black font-semibold dark:text-black">
                        {t("support-cards-title")}
                    </h1>
                </div>
                <div className="flex justify-center">
                    <div
                        className="support-card opacity-0 translate-y-[10px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 pt-12 max-w-[1600px] w-full">
                        <div className="animated-support-card2 bg-white p-6 rounded-lg shadow-lg text-center">
                            <BsSafe className="text-5xl text-green_brand mb-4 mx-auto"/>
                            <h2 className="text-2xl text-black font-bold mb-4">
                                {t("support-card-title-1")}
                            </h2>
                            <p className="text-neutral-400 text-left">
                                {t("support-card-text-1")}
                            </p>
                        </div>
                        <div className="animated-support-card2 bg-white p-6 rounded-lg shadow-lg text-center">
                            <SlSpeedometer className="text-5xl text-green_brand mb-4 mx-auto"/>
                            <h2 className="text-2xl text-black font-bold mb-4">
                                {t("support-card-title-2")}
                            </h2>
                            <p className="text-neutral-400 text-left">
                                {t("support-card-text-2")}
                            </p>
                        </div>
                        <div className="animated-support-card2 bg-white p-6 rounded-lg shadow-lg text-center">
                            <IoCloudOutline className="text-5xl text-green_brand mb-4 mx-auto"/>
                            <h2 className="text-2xl text-black font-bold mb-4">
                                {t("support-card-title-3")}
                            </h2>
                            <p className="text-neutral-400 text-left">
                                {t("support-card-text-3")}
                            </p>
                        </div>
                        <div className="animated-support-card2 bg-white p-6 rounded-lg shadow-lg text-center">
                            <RxUpdate className="text-5xl text-green_brand mb-4 mx-auto"/>
                            <h2 className="text-2xl text-black font-bold mb-4">
                                {t("support-card-title-4")}
                            </h2>
                            <p className="text-neutral-400 text-left">
                                {t("support-card-text-4")}
                            </p>
                        </div>
                        <div className="animated-support-card2 bg-white p-6 rounded-lg shadow-lg text-center">
                            <IoCloudDoneOutline className="text-5xl text-green_brand mb-4 mx-auto"/>
                            <h2 className="text-2xl text-black font-bold mb-4">
                                {t("support-card-title-5")}
                            </h2>
                            <p className="text-neutral-400 text-left">
                                {t("support-card-text-5")}
                            </p>
                        </div>
                        <div className="animated-support-card2 bg-white p-6 rounded-lg shadow-lg text-center">
                            <LiaHandsHelpingSolid className="text-5xl text-green_brand mb-4 mx-auto"/>
                            <h2 className="text-2xl text-black font-bold mb-4">
                                {t("support-card-title-6")}
                            </h2>
                            <p className="text-neutral-400 text-left">
                                {t("support-card-text-6")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}
export default SupportCards
