// HeroHomepage.tsx

import React, {useEffect} from "react";
import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {useTranslation} from "react-i18next";
import {animateWithGsapText} from "../utils/animations";

gsap.registerPlugin(ScrollTrigger);

interface HeroHomepageProps {
    windowWidth: number;
}

const HeroHomepage: React.FC<HeroHomepageProps> = ({windowWidth}) => {
    const {t} = useTranslation();

    useEffect(() => {
        animateWithGsapText(".hero-text", {duration: 2}, {
            start: "bottom 30%",
            end: "bottom",
            scrub: true,
        });

        animateWithGsapText(".hero-text-1", {duration: 2}, {
            start: "bottom 50%",
            end: "bottom",
            scrub: true,
        });
    }, [windowWidth]);

    return (
        <>
            {/*<Helmet>*/}
            {/*    <meta*/}
            {/*        name="description"*/}
            {/*        content={t("helmet-home-HeroHomePage-description")}*/}
            {/*    />*/}
            {/*    <meta*/}
            {/*        name="keywords"*/}
            {/*        content={t("helmet-home-HeroHomePage-keywords")}*/}
            {/*    />*/}
            {/*</Helmet>*/}

            <section
                id="smooth-wrapper"
                className="flex flex-col bg-black text-white items-center w-full"
            >
                <div className="overflow-hidden w-screen">
                    <div className="flex flex-col items-center mx-auto h-[40rem] md:h-[75rem] w-full pt-48 md:pt-64">
                        <div className="hero-text animated-hero-text">
                            {t("hero-homepage-text-1")}
                        </div>
                        <div className="hero-text-1 animated-hero-text-1">
                            {t("hero-homepage-text-2")}
                        </div>
                        <div className="hero-text animated-hero-text">
                            {t("hero-homepage-text-3")}
                        </div>
                        <div className="hero-text-1 animated-hero-text-1">
                            {t("hero-homepage-text-4")}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HeroHomepage;
