import {Navigate, Route, Routes} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import React, {useEffect, useState} from 'react';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Builds from './pages/pricingAndBuilds/Builds';
import Visibility from './pages/Visibility';
import Support from './pages/Support';
import Contact from './pages/Contact';
import StarterBuild from './pages/pricingAndBuilds/StarterBuild';
import StandardBuild from './pages/pricingAndBuilds/StandardBuild';
import ProfessionalBuild from './pages/pricingAndBuilds/ProfessionalBuild';
import EnterpriseBuild from './pages/pricingAndBuilds/EnterpriseBuild';
import SEO from './pages/SEO';
import LanguageNavigationHandler from './components/LanguageNavigationHandler';
import './index.css';
import Footer from "./components/Footer.tsx";
import ScrollToTop from "./ui/ScrollToTop.tsx";
import Imprint from "./pages/Imprint.tsx";
import TermsConditions from "./pages/Terms & Conditions.tsx";
import PrivacyPolicy from "./pages/PrivacyPolicy.tsx";
import useAnalytics from "./hooks/useAnalytics.ts";
import {useCookies} from "react-cookie";
import {Thankyou} from "./pages/Thankyou.tsx";

interface RouteObject {
    path: string;
    element: JSX.Element;
}

function generateRoutes(t: (key: string) => string): RouteObject[] {
    return [
        {path: '/', element: <Home/>},
        {path: `/${t('route-about')}`, element: <About/>},
        {path: `/${t('route-builds')}`, element: <Builds/>},
        {path: `/${t('route-builds-starter')}`, element: <StarterBuild/>},
        {path: `/${t('route-builds-standard')}`, element: <StandardBuild/>},
        {path: `/${t('route-builds-professional')}`, element: <ProfessionalBuild/>},
        {path: `/${t('route-builds-enterprise')}`, element: <EnterpriseBuild/>},
        {path: `/${t('route-visibility')}`, element: <Visibility/>},
        {path: `/${t('route-visibility-seo')}`, element: <SEO/>},
        {path: `/${t('route-support')}`, element: <Support/>},
        {path: `/${t('route-contact')}`, element: <Contact/>},
        {path: `/${t('route-imprint')}`, element: <Imprint/>},
        {path: `/${t('route-terms-conditions')}`, element: <TermsConditions/>},
        {path: `/${t('route-privacy-policy')}`, element: <PrivacyPolicy/>},
        {path: `/${t('route-thank-you')}`, element: <Thankyou/>},
        {path: '*', element: <Home/>},
    ];
}

const DynamicRoutes: React.FC<{ routes: RouteObject[] }> = ({routes}) => {
    return (
        <Routes>
            {routes.map((route, index) => (
                <Route key={index} path={route.path} element={route.element}/>
            ))}
            {/* Catch-all route */}
            <Route path="*" element={<Navigate to="/" replace/>}/>
        </Routes>
    );
};

function App() {
    const {t, i18n} = useTranslation();
    const [routes, setRoutes] = useState<RouteObject[]>([]);
    const [isI18nInitialized, setIsI18nInitialized] = useState(false);
    const [cookies] = useCookies(['cookieConsent']);

    useEffect(() => {
        const handleLanguageChange = () => {
            const generatedRoutes = generateRoutes(t);
            setRoutes(generatedRoutes);
        };

        i18n.on('languageChanged', handleLanguageChange);
        if (i18n.isInitialized) {
            setIsI18nInitialized(true);
            handleLanguageChange();
        } else {
            i18n.on('initialized', () => {
                setIsI18nInitialized(true);
                handleLanguageChange();
            });
        }

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
            i18n.off('initialized');
        };

    }, [i18n, t]);

    // Use analytics only if cookies are accepted
    const shouldUseAnalytics = cookies.cookieConsent === 'accepted';
    useAnalytics(shouldUseAnalytics);

    // H1 visibility control
    useEffect(() => {
        const updateH1Visibility = () => {
            const isHomePage = window.location.pathname === '/';
            const h1Wrapper = document.getElementById('h1-wrapper') as HTMLElement;

            if (h1Wrapper) {
                h1Wrapper.style.display = isHomePage ? 'block' : 'none';
            }
        };

        // Update visibility when the component loads
        updateH1Visibility();

        // Listen to route changes and language changes
        const handleRouteChange = () => {
            updateH1Visibility();
        };

        window.addEventListener('popstate', handleRouteChange);
        i18n.on('languageChanged', handleRouteChange);

        // Clean up listeners
        return () => {
            window.removeEventListener('popstate', handleRouteChange);
            i18n.off('languageChanged', handleRouteChange);
        };
    }, [i18n]); // Removed 'location' from the dependency array


    if (!isI18nInitialized || routes.length === 0) return null;

    return (
        <main className="bg-black h-full">
            <ScrollToTop/>
            <Navbar/>
            <LanguageNavigationHandler/>
            <DynamicRoutes routes={routes}/>
            <Footer/>
        </main>
    );
}

export default App;
