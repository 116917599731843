import React, {useEffect, useRef} from "react";
import {motion, useAnimation, useInView} from "framer-motion";

type AnimationTextProps = {
    text: string | string[];
    el?: keyof JSX.IntrinsicElements; // Allows any intrinsic HTML element (like div, p, h1, etc.)
    className?: string;
    once?: boolean;
};

const defaultAnimations = {
    hidden: {
        opacity: 0,
        y: 20,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.1,
        },
    },
};

export const AnimatedText: React.FC<AnimationTextProps> = ({
                                                               text,
                                                               el: Wrapper = "p", // Default wrapper is paragraph
                                                               className,
                                                               once,
                                                           }: AnimationTextProps) => {
    const controls = useAnimation(); // Animation controls
    const textArray = Array.isArray(text) ? text : [text]; // Convert text to array if not already
    const ref = useRef(null); // Ref to track the element's visibility
    const isInView = useInView(ref, {amount: 0.5, once}); // Track visibility (0.5 is 50% of the element in view)

    useEffect(() => {
        const animate = async () => {
            if (isInView) {
                await controls.start("visible");
            } else {
                await controls.start("hidden");
            }
        };

        animate();
    }, [isInView, controls]);


    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <Wrapper className={className as string}> {/* Cast className to string */}
            <span className="sr-only">{text}</span> {/* Screen reader text */}
            <motion.span
                ref={ref}
                initial="hidden"
                animate={controls}
                transition={{staggerChildren: 0.1}}
                aria-hidden="true" // Hide the text visually, for accessibility
            >
                {textArray.map((line, arrayIndex) => (
                    <span key={arrayIndex} className="black">
                        {line.split(" ").map((word, index) => (
                            <span key={index} className="inline-block">
                                {word.split("").map((char, charIndex) => (
                                    <motion.span
                                        key={charIndex}
                                        className="inline-block"
                                        style={
                                            charIndex === 0
                                                ? {color: "#00ADB5"}
                                                : {}
                                        }
                                        variants={defaultAnimations}
                                    >
                                        {char}
                                    </motion.span>
                                ))}
                                <span className="inline-block">&nbsp;</span>
                            </span>
                        ))}
                    </span>
                ))}
            </motion.span>
        </Wrapper>
    );
};
