import {Link} from "react-router-dom";
import {useGSAP} from "@gsap/react";
import gsap from "gsap";
import {Cloudinary, CloudinaryImage} from "@cloudinary/url-gen";
import {useTranslation} from "react-i18next";
import {format, quality} from "@cloudinary/url-gen/actions/delivery";
import {animateWithGsap} from "../utils/animations.tsx";
import {AdvancedImage} from "@cloudinary/react";
import TrackableButton from "./TrackableButton.tsx";

const ResponsiveWebsite = () => {
    const {t} = useTranslation();
    useGSAP(() => {
        gsap.to("#title2", {opacity: 1, y: 0, delay: 0.2})
        animateWithGsap(".card-wrapper", {opacity: 1, y: 0, delay: 0.5, stagger: 0.25})
        animateWithGsap('.stagger3', {y: -10, opacity: 1, delay: 0.3})
        animateWithGsap('.stagger4', {y: -10, opacity: 1, delay: 0.35})
        gsap.to(".cta2", {opacity: 1, y: -50, delay: 0.4})
    }, [])

    const cld = new Cloudinary({
        cloud: {
            cloudName: import.meta.env.VITE_CLOUDINARY_CLOUD_NAME,
        }
    });

    const myImage = cld.image(import.meta.env.VITE_CLOUDINARY_RESPONSIVE_LARGER_PUBLIC_ID)
    const monitorImage: CloudinaryImage = cld.image(import.meta.env.VITE_CLOUDINARY_RESPONSIVE_MONITOR_PUBLIC_ID)
    const laptopImage: CloudinaryImage = cld.image(import.meta.env.VITE_CLOUDINARY_RESPONSIVE_LAPTOP_PUBLIC_ID)
    const tabletImage: CloudinaryImage = cld.image(import.meta.env.VITE_CLOUDINARY_RESPONSIVE_TABLET_PUBLIC_ID)
    const mobileImage: CloudinaryImage = cld.image(import.meta.env.VITE_CLOUDINARY_RESPONSIVE_MOBILE_PUBLIC_ID)
    myImage
        .delivery(format('auto'))
        .delivery(quality('auto'));

    return (
        <>
            {/*<Helmet>*/}
            {/*    /!*<title>{t("helmet-home-ResponsiveWebsite-title")}</title>*!/*/}
            {/*    <meta*/}
            {/*        name="description"*/}
            {/*        content={t("helmet-home-ResponsiveWebsite-description")}*/}
            {/*    />*/}
            {/*    <meta*/}
            {/*        name="keywords"*/}
            {/*        content={t("helmet-home-ResponsiveWebsite-keywords")}*/}
            {/*    />*/}
            {/*</Helmet>*/}

            <section
                className="parallax w-full overflow-hidden font-poppins bg-white flex items-center justify-center common-padding-no-bottom-padding">
                <div className="flex flex-col screen-max-width mx-auto">
                    <div className="flex flex-col items-center w-full">
                        <h1 id="title2" className="section-heading">{t("what-we-do-header-text")}</h1>
                    </div>
                    <div className="flex pt-12 2xl:pb-28 flex-col xl:flex-row xl:w-full">
                        <div className="flex flex-col xl:flex-row xl:w-full">
                            <div className="flex flex-wrap xl:w-1/2 justify-center xl:justify-start order-2 md:order-2">
                                <div
                                    className="card-wrapper opacity-0 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-8 mt-8 xl:mt-0 mb-16 mx-auto justify-items-center items-center">
                                    <div
                                        className="card-responsive h-80 w-80 rounded-2xl text-center flex flex-col justify-center items-center">
                                        <h3 className="my-4">Desktop</h3>
                                        <AdvancedImage className="" cldImg={monitorImage}/>
                                    </div>
                                    <div
                                        className="card-responsive h-80 w-80 rounded-2xl text-center flex flex-col justify-center items-center">
                                        <h3 className="my-4">Laptop</h3>
                                        <AdvancedImage className="" cldImg={laptopImage}/>
                                    </div>
                                    <div
                                        className="card-responsive h-80 w-80 rounded-2xl text-center flex flex-col justify-center items-center">
                                        <h3 className="my-4">Tablet</h3>
                                        <AdvancedImage className="" cldImg={tabletImage}/>
                                    </div>
                                    <div
                                        className="card-responsive h-80 w-80 rounded-2xl text-center flex flex-col justify-center items-center">
                                        <h3 className="my-4">Mobile</h3>
                                        <AdvancedImage className="" cldImg={mobileImage}/>
                                    </div>
                                </div>

                                <div
                                    className="cta2 xl:hidden flex opacity-0 w-full items-center justify-center text-white translate-y-14 my-28 xl:mt-40">
                                    <Link to={`/${t("route-contact")}`} className="btn">{t("cta-button-text")}</Link>
                                </div>
                            </div>
                            <div
                                className="flex flex-col text-center xl:text-left items-center xl:items-start w-full xl:w-[50%] xl:mr-[5rem] 2xl:pl-10 text-green_brand order-1 md:order-1">
                                <h2 className="stagger3 opacity-0 poppins-semibold pb-1 xl:pb-6 mt-[-1rem] break-words">{t("responsive-design-sub-header-text")}</h2>
                                <p className="stagger4 opacity-0 text-left text-gray mt-2 xl:mt-5 text-md xl:text-xl leading-loose xl:leading-[3.25rem]">{t("responsive-design-text")}</p>
                                <div
                                    className="cta2 hidden xl:flex opacity-0 w-full items-start text-white translate-y-14 my-28 xl:mt-40">
                                    <Link to={`/${t("route-contact")}`}>
                                        <TrackableButton category="CTA" action="Click" label="Responsive cta button"
                                                         className="btn">{t("cta-button-text")}</TrackableButton>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default ResponsiveWebsite
