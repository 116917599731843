import {Stars} from "@react-three/drei";
import {Canvas} from "@react-three/fiber";
import React, {ReactNode, useEffect} from "react";
import {FiArrowRight} from "react-icons/fi";
import {animate, motion, MotionProps, useMotionTemplate, useMotionValue,} from "framer-motion";
import {Cloudinary} from "@cloudinary/url-gen";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {AdvancedVideo} from "@cloudinary/react";
import anime from "animejs";
import ReactGA from "react-ga4";

const COLORS_TOP = ["#00ADB5", "#004548", "#4cc5cb", "#00797e", "#99dee1"];

interface TrackableButtonProps extends MotionProps {
    category: string;
    action: string;
    label: string;
    className?: string;
    children: ReactNode;
}

const TrackableButton: React.FC<TrackableButtonProps> = ({
                                                             category,
                                                             action,
                                                             label,
                                                             children,
                                                             ...props
                                                         }) => {
    const handleClick = () => {
        ReactGA.event({
            category: 'User',
            action: 'AuroraHero CTA Click',
        });
    };

    return (
        <motion.button
            onClick={handleClick}
            {...props}
        >
            {children}
        </motion.button>
    );
};

export const AuroraHero = () => {
    const color = useMotionValue(COLORS_TOP[0]);

    useEffect(() => {
        animate(color, COLORS_TOP, {
            ease: "easeInOut",
            duration: 10,
            repeat: Infinity,
            repeatType: "mirror",
        });

        anime({
            targets: "#animatedBlob",
            d: [
                {value: "M0.35,0.2 C0.45,0.15, 0.55,0.1, 0.7,0.25 C0.8,0.4, 0.75,0.55, 0.65,0.7 C0.55,0.85, 0.4,0.9, 0.3,0.7 C0.2,0.5, 0.25,0.35, 0.3,0.3 C0.35,0.25, 0.3,0.25, 0.35,0.2"}, {value: "M0.4,0.05 C0.55,0, 0.75,0.15, 0.85,0.35 C0.95,0.55, 0.85,0.7, 0.75,0.9 C0.65,1, 0.45,1, 0.3,0.85 C0.15,0.7, 0.25,0.45, 0.35,0.3 C0.45,0.15, 0.35,0.1, 0.4,0.05"},
                {value: "M0.38,0.08 C0.52,0.03, 0.72,0.13, 0.82,0.33 C0.92,0.53, 0.82,0.68, 0.72,0.83 C0.62,0.98, 0.42,1.03, 0.27,0.83 C0.12,0.63, 0.22,0.43, 0.32,0.33 C0.42,0.23, 0.27,0.18, 0.38,0.08"},
                {value: "M0.35,0.1 C0.5,0.05, 0.7,0.2, 0.8,0.4 C0.9,0.6, 0.8,0.75, 0.7,0.85 C0.6,0.95, 0.4,1, 0.25,0.85 C0.1,0.7, 0.2,0.5, 0.3,0.35 C0.4,0.2, 0.3,0.15, 0.35,0.1"},
                {value: "M0.32,0.15 C0.47,0.1, 0.67,0.25, 0.77,0.45 C0.87,0.65, 0.77,0.75, 0.67,0.85 C0.57,0.95, 0.37,1, 0.22,0.85 C0.07,0.7, 0.17,0.55, 0.27,0.4 C0.37,0.25, 0.27,0.2, 0.32,0.15"},
                {value: "M0.4,0.1 C0.55,0.05, 0.75,0.2, 0.85,0.4 C0.95,0.6, 0.85,0.75, 0.75,0.85 C0.65,0.95, 0.45,1, 0.3,0.85 C0.15,0.7, 0.25,0.55, 0.35,0.4 C0.45,0.25, 0.35,0.2, 0.4,0.1"},
                {value: "M0.36,0.12 C0.51,0.07, 0.71,0.22, 0.81,0.42 C0.91,0.62, 0.81,0.72, 0.71,0.82 C0.61,0.92, 0.41,1, 0.26,0.82 C0.11,0.72, 0.21,0.52, 0.31,0.37 C0.41,0.22, 0.31,0.17, 0.36,0.12"},
                {value: "M0.34,0.1 C0.49,0.05, 0.69,0.2, 0.79,0.4 C0.89,0.6, 0.79,0.75, 0.69,0.85 C0.59,0.95, 0.39,1, 0.24,0.85 C0.09,0.7, 0.19,0.5, 0.29,0.35 C0.39,0.2, 0.29,0.15, 0.34,0.1"},
                {value: "M0.4,0.1 C0.55,0.05, 0.75,0.2, 0.85,0.4 C0.95,0.6, 0.85,0.75, 0.75,0.85 C0.65,0.95, 0.45,1, 0.3,0.85 C0.15,0.7, 0.25,0.55, 0.35,0.4 C0.45,0.25, 0.35,0.2, 0.4,0.1"},
                {value: "M0.36,0.12 C0.51,0.07, 0.71,0.22, 0.81,0.42 C0.91,0.62, 0.81,0.72, 0.71,0.82 C0.61,0.92, 0.41,1, 0.26,0.82 C0.11,0.72, 0.21,0.52, 0.31,0.37 C0.41,0.22, 0.31,0.17, 0.36,0.12"},
                {value: "M0.34,0.1 C0.49,0.05, 0.69,0.2, 0.79,0.4 C0.89,0.6, 0.79,0.75, 0.69,0.85 C0.59,0.95, 0.39,1, 0.24,0.85 C0.09,0.7, 0.19,0.5, 0.29,0.35 C0.39,0.2, 0.29,0.15, 0.34,0.1"},
            ],
            easing: 'easeInOutSine',
            duration: 18000,
            loop: true
        });
    }, [color]);

    const {t} = useTranslation();

    const backgroundImage = useMotionTemplate`radial-gradient(125% 125% at 50% 0%, #020617 50%, ${color})`;
    const border = useMotionTemplate`1px solid ${color}`;
    const boxShadow = useMotionTemplate`0px 4px 24px ${color}`;

    const cld = new Cloudinary({
        cloud: {
            cloudName: import.meta.env.VITE_CLOUDINARY_CLOUD_NAME as string,
        },
    });

    const heroVideo = cld.video(import.meta.env.VITE_CLOUDINARY_HERO_BLOB_VIDEO_PUBLIC_ID).quality('auto');
    return (
        <>
            {/*<Helmet>*/}
            {/*    /!*<title>{t("helmet-home-AuroraHero-title")}</title>*!/*/}
            {/*    <meta*/}
            {/*        name="description"*/}
            {/*        content={t("helmet-home-AuroraHero-description")}*/}
            {/*    />*/}
            {/*    <meta*/}
            {/*        name="keywords"*/}
            {/*        content={t("helmet-home-AuroraHero-keywords")}*/}
            {/*    />*/}
            {/*</Helmet>*/}


            <motion.section
                style={{
                    backgroundImage,
                }}
                className="relative min-h-screen flex items-center overflow-hidden bg-gray-950 px-4 text-gray-200"
            >
                <div
                    className="relative z-10 flex flex-col md:flex-row items-center justify-between w-full max-w-7xl mx-auto glass p-10 rounded-lg">
                    <div className="flex flex-col md:items-start md:text-left text-center md:w-1/2">

                        <h1 className="max-w-3xl bg-gradient-to-br from-white to-gray-400 bg-clip-text text-center text-3xl font-medium leading-tight text-transparent sm:text-5xl sm:leading-tight md:text-4xl lg:text-7xl md:text-left md:leading-tight">
                            {t("aurora-hero-header")}
                        </h1>
                        <p className="my-6 max-w-xl text-left text-base leading-relaxed md:text-lg md:leading-relaxed">
                            {t("aurora-hero-text-1")}
                        </p>
                        <ul className="mb-6 max-w-xl text-left text-base leading-relaxed md:text-lg md:leading-relaxed list-disc pl-5">
                            <li>{t("aurora-hero-bullet-point-1")}</li>
                            <li>{t("aurora-hero-bullet-point-2")}</li>
                            <li>{t("aurora-hero-bullet-point-3")}</li>
                            <li>{t("aurora-hero-bullet-point-4")}</li>
                        </ul>
                        <div className="hidden md:flex">
                            <Link to={`/${t("route-contact")}`}>
                                <TrackableButton
                                    category="CTA"
                                    action="Click"
                                    label="Contact Us"
                                    style={{
                                        border,
                                        boxShadow,
                                    }}
                                    whileHover={{
                                        scale: 1.015,
                                    }}
                                    whileTap={{
                                        scale: 0.985,
                                    }}
                                    className="group relative flex w-fit items-center gap-1.5 rounded-lg bg-green_brand px-4 py-4 text-gray-50 transition-colors hover:bg-gradient-to-b from-green_darker_brand to-green_lighter_brand"
                                >
                                    {t("cta-button-text")}
                                    <FiArrowRight
                                        className="transition-transform group-hover:-rotate-45 group-active:-rotate-12"/>
                                </TrackableButton>
                            </Link>
                        </div>
                    </div>
                    <div className="relative flex items-center justify-center md:w-1/2 mt-10 md:mt-0">
                        <svg className="absolute inset-0 w-full h-full" viewBox="0 0 1 1">
                            <defs>
                                <clipPath id="blobClip" clipPathUnits="objectBoundingBox">
                                    <path
                                        id="animatedBlob"
                                        d="M0.5,0.1 C0.7,0.1, 0.9,0.3, 0.9,0.5 C0.9,0.7, 0.7,0.9, 0.5,0.9 C0.3,0.9, 0.1,0.7, 0.1,0.5 C0.1,0.3, 0.3,0.1, 0.5,0.1"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                        <AdvancedVideo
                            cldVid={heroVideo}
                            autoPlay={true}
                            muted={true}
                            loop={true}
                            style={{clipPath: "url(#blobClip)"}}
                        />
                    </div>
                    <div className="flex w-full items-center justify-center md:items-start md:hidden py-12">
                        <Link to={`/${t("route-contact")}`}>
                            <TrackableButton
                                category="CTA"
                                action="Click"
                                label="Contact Us"
                                style={{
                                    border,
                                    boxShadow,
                                }}
                                whileHover={{
                                    scale: 1.015,
                                }}
                                whileTap={{
                                    scale: 0.985,
                                }}
                                className="group relative flex w-fit items-center gap-1.5 rounded-lg bg-green_brand px-4 py-4 text-gray-50 transition-colors hover:bg-gradient-to-b from-green_darker_brand to-green_lighter_brand"
                            >
                                {t("cta-button-text")}
                                <FiArrowRight
                                    className="transition-transform group-hover:-rotate-45 group-active:-rotate-12"/>
                            </TrackableButton>
                        </Link>
                    </div>
                </div>

                <div className="absolute inset-0 z-0">
                    <Canvas>
                        <Stars radius={50} count={2500} factor={4} fade speed={2}/>
                    </Canvas>
                </div>
            </motion.section>
        </>
    );
};