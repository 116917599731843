import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useGSAP} from "@gsap/react";
import gsap from "gsap";
import {animateWithGsap} from "../utils/animations.tsx";
import TrackableButton from "./TrackableButton.tsx";

interface ProjectStep {
    title: string;
    title_subheading: string;
    icon: string;
    iconBg: string;
    points: string[];
}

const ProjectFlow = () => {
    const {t} = useTranslation();
    const projectSteps = t('projectSteps', {returnObjects: true}) as ProjectStep[];

    useGSAP(() => {
        gsap.to("#title", {opacity: 1, y: 0, delay: 1})
        gsap.to(".stagger-steps", {y: -10, opacity: 1, delay: 1})
        animateWithGsap('.stagger1', {y: -10, opacity: 1, delay: 0.5,})
        gsap.to(".cta", {opacity: 1, y: -50, delay: 2})
    }, [])

    return (
        <>
            {/*<Helmet>*/}
            {/*    /!*<title>{t("helmet-home-ProjectFlow-title")}</title>*!/*/}
            {/*    <meta*/}
            {/*        name="description"*/}
            {/*        content={t("helmet-home-ProjectFlow-description")}*/}
            {/*    />*/}
            {/*    <meta*/}
            {/*        name="keywords"*/}
            {/*        content={t("helmet-home-ProjectFlow-keywords")}*/}
            {/*    />*/}
            {/*</Helmet>*/}

            <section className="max-container-no-top-padding font-poppins">
                <div className="pb-16">
                    <div className="mt-12 flex">
                        <VerticalTimeline>
                            {projectSteps.map((steps) => (
                                <VerticalTimelineElement
                                    key={steps.title_subheading}
                                    icon={<div className="flex justify-center items-center w-full h-full">
                                        <div
                                            className="w-[60%] h-[60%] flex items-center justify-center poppins-bold object-contain"> {steps.icon}
                                        </div>
                                    </div>}
                                    iconStyle={{background: steps.iconBg}}
                                    contentStyle={{
                                        borderBottom: '8px',
                                        borderStyle: 'solid',
                                        borderBottomColor: steps.iconBg,
                                        boxShadow: 'none',
                                    }}
                                >
                                    <div className="stagger-steps">
                                        <h3 className="text-green_brand text-xl font-poppins font-semibold">
                                            {steps.title}
                                        </h3>
                                        <p className="text-gray font-medium font-base" style={{margin: 0}}>
                                            {steps.title_subheading}
                                        </p>
                                    </div>
                                    <ul className="my-5 list-disc ml-5 space-y-2">
                                        {steps.points.map((point, index) => (
                                            <li key={`experience-point-${index}`}
                                                className="stagger-steps text-black font-normal pl-1 text-sm">
                                                {point}
                                            </li>
                                        ))}
                                    </ul>
                                </VerticalTimelineElement>
                            ))}
                        </VerticalTimeline>
                    </div>
                </div>

                <hr className="border-slate-200"/>

                <div
                    className="cta flex opacity-0 justify-center items-center text-white xl:items-left xl:w-full translate-y-20 mt-28 xl:my-40">
                    <Link to={t("route-contact")}>
                        <TrackableButton category="CTA" action="Click" label="Responsive cta button"
                                         className="btn">{t("cta-button-text")}</TrackableButton>
                    </Link>
                </div>
            </section>
        </>
    )
}
export default ProjectFlow
