import {AnimatePresence, motion, MotionProps} from "framer-motion";
import React, {Dispatch, ReactNode, SetStateAction, useEffect, useState} from "react";
import {Cloudinary} from "@cloudinary/url-gen";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {Link, useNavigate} from "react-router-dom";
import ReactGA from "react-ga4";

const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:8080';

interface TrackableButtonProps extends MotionProps {
    category: string;
    action: string;
    label: string;
    type?: string;
    className?: string;
    children: ReactNode;
}

const TrackableButton: React.FC<TrackableButtonProps> = ({
                                                             category,
                                                             action,
                                                             label,
                                                             type,
                                                             children,
                                                             ...props
                                                         }) => {
    const handleClick = () => {
        ReactGA.event({
            category: 'User',
            action: 'AuroraHero CTA Click',
        });
    };

    return (
        <motion.button
            onClick={handleClick}
            {...props}
        >
            {children}
        </motion.button>
    );
};

const ContactForm = () => {
    const [selected, setSelected] = useState<"company" | "individual">("individual");
    return (
        <>
            {/*<Helmet>*/}
            {/*    /!*<title>{t("helmet-home-ContactForm-title")}</title>*!/*/}
            {/*    <meta*/}
            {/*        name="description"*/}
            {/*        content={t("helmet-home-ContactForm-description")}*/}
            {/*    />*/}
            {/*    <meta*/}
            {/*        name="keywords"*/}
            {/*        content={t("helmet-home-ContactForm-keywords")}*/}
            {/*    />*/}
            {/*</Helmet>*/}

            <section className="md:p-4 md:bg-slate-100 w-full font-poppins">
                <div
                    className="w-full max-w-full mx-auto shadow-lg flex flex-col-reverse lg:flex-row rounded-lg overflow-hidden">
                    <Form selected={selected} setSelected={setSelected}/>
                    <Images selected={selected}/>
                </div>
            </section>
        </>
    );
};

const Form = ({
                  selected,
                  setSelected,
              }: {
    selected: "company" | "individual";
    setSelected: Dispatch<SetStateAction<"company" | "individual">>;
}) => {
    const {t} = useTranslation();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [message, setMessage] = useState("");

    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [companyError, setCompanyError] = useState("");
    const [messageError, setMessageError] = useState("");

    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // Reset validation messages
        setFirstNameError("");
        setLastNameError("");
        setEmailError("");
        setCompanyError("");
        setMessageError("");

        // Custom validation logic
        let isValid = true;
        if (firstName.trim() === "") {
            setFirstNameError(t("contact-us-form-first-name-error-message"));
            isValid = false;
        }
        if (lastName.trim() === "") {
            setLastNameError(t("contact-us-form-last-name-error-message"));
            isValid = false;
        }
        if (email.trim() === "") {
            setEmailError(t("contact-us-form-email-error-message"));
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            setEmailError("Email is invalid.");
            isValid = false;
        }
        if (selected === "company" && company.trim() === "") {
            setCompanyError(t("contact-us-form-company-error-message"));
            isValid = false;
        }
        if (message.trim() === "") {
            setMessageError(t("contact-us-form-message-error-message"));
            isValid = false;
        }

        if (!isValid) return;

        setIsSubmitting(true);

        const formData = {
            firstName,
            lastName,
            email,
            company: selected === "company" ? company : "",
            message,
            language: navigator.language.startsWith('de') ? 'de' : 'en'
        };

        try {
            const response = await axios.post(`${API_URL}/api/email`, formData);
            alert(response.data);

            // Clear the form after successful submission
            setFirstName("");
            setLastName("");
            setEmail("");
            setCompany("");
            setMessage("");

            // Redirect to the thank you page
            navigate(`/${t("route-thank-you")}`);
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                console.error('Error sending email:', error.response ? error.response.data : error.message);
                alert('Failed to send email. ' + (error.response ? error.response.data : error.message));
            } else {
                console.error('Unexpected error:', error);
                alert('An unexpected error occurred. Please try again later.');
            }
        } finally {
            setIsSubmitting(false);
        }
    };


    return (
        <form
            onSubmit={handleSubmit}
            className={`p-8 w-full transition-colors duration-[750ms] ${selected === "company" ? "bg-green_dark_brand text-white_smoke" : "bg-green_brand text-white_smoke"}`}
        >
            <h3 className="text-xl sm:text-4xl font-semibold mb-6">
                {t("contact-us-form-title")}
            </h3>
            <div className="flex flex-col mb-6">
                <label htmlFor="firstName" className="text-base md:text-2xl mb-2 block">
                    {t("contact-us-form-name-text")} <span className="text-neutral-400">*</span>
                </label>
                <div className="w-full flex flex-col md:flex-row md:space-x-4">
                    {/* First Name input */}
                    <div className="mb-6 md:mb-0 md:flex-1">
                        <input
                            id="firstName"
                            type="text"
                            name="firstName"
                            autoComplete="given-name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            className={`${
                                selected === "company" ? "bg-green_darker_brand" : "bg-green_light_brand"
                            } mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-green_brand text-white_smoke transition-colors duration-[750ms] placeholder-white_smoke`}
                            placeholder={t("contact-us-form-first-name-placeholder")}
                        />
                        {firstNameError && <p className="text-red-300 mt-2 animate-pulse">{firstNameError}</p>}
                    </div>

                    {/* Last Name input */}
                    <div className="md:flex-1">
                        <label htmlFor="lastName" className="sr-only">
                            {t("contact-us-form-last-name-placeholder")}
                        </label>
                        <input
                            id="lastName"
                            type="text"
                            name="lastName"
                            autoComplete="family-name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            className={`${
                                selected === "company" ? "bg-green_darker_brand" : "bg-green_light_brand"
                            } mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-green_brand text-white_smoke transition-colors duration-[750ms] placeholder-white_smoke`}
                            placeholder={t("contact-us-form-last-name-placeholder")}
                        />
                        {lastNameError && <p className="text-red-300 mt-2 animate-pulse">{lastNameError}</p>}
                    </div>
                </div>
            </div>

            {/* Email input */}
            <div className="mb-6">
                <label htmlFor="email" className="text-base md:text-2xl mb-2 block">
                    {t("contact-us-form-email-text")} <span className="text-neutral-400">*</span>
                </label>
                <input
                    id="email"
                    type="email"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={`${selected === "company" ? "bg-green_darker_brand" : "bg-green_light_brand"} mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-green_brand text-white_smoke transition-colors duration-[750ms] placeholder-white_smoke`}
                    placeholder={t("contact-us-form-email-placeholder")}
                />
                {emailError && <p className="text-red-300 mt-2 animate-pulse">{emailError}</p>}
            </div>

            {/* Company/individual toggle */}
            <div className="mb-6">
                <p className="text-base md:text-2xl mb-2">{t("contact-us-form-company-text")}</p>
                <FormSelect selected={selected} setSelected={setSelected}/>
            </div>

            {/* Company name */}
            <AnimatePresence initial={false}>
                {selected === "company" && (
                    <motion.div
                        initial={{opacity: 0, height: 0}}
                        animate={{opacity: 1, height: 'auto'}}
                        exit={{opacity: 0, height: 0}}
                        transition={BASE_TRANSITION}
                        className="mb-6"
                    >
                        <label htmlFor="company" className="text-base md:text-2xl mb-2 block">
                            {t("contact-us-form-company-company-text")} <span className="text-neutral-400">*</span>
                        </label>
                        <input
                            id="company"
                            type="text"
                            name="company"
                            autoComplete="organization"
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            className={`${selected === "company" ? "bg-green_darker_brand" : "bg-green_light_brand"} mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-green_brand text-white_smoke transition-colors duration-[750ms] placeholder-white_smoke`}
                            placeholder={t("contact-us-form-company-placeholder")}
                        />
                        {companyError && <p className="text-red-300 mt-2 animate-pulse">{companyError}</p>}
                    </motion.div>
                )}
            </AnimatePresence>

            {/* Info */}
            <div className="mb-6">
                <label htmlFor="message" className="text-base md:text-2xl mb-2 block">
                    {t("contact-us-form-message-text")} <span className="text-neutral-400">*</span>
                </label>
                <textarea
                    id="message"
                    name="message"
                    autoComplete="off"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className={`${selected === "company" ? "bg-green_darker_brand" : "bg-green_light_brand"} mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-green_brand text-white_smoke transition-colors duration-[750ms] min-h-[150px] resize-none placeholder-white_smoke`}
                    placeholder={t("contact-us-form-message-placeholder")}
                />
                {messageError && <p className="text-red-300 mt-2 animate-pulse">{messageError}</p>}
            </div>

            {/* Consent text */}
            <div className="mb-6">
                <p className="text-xs md:text-sm mb-2">
                    {t("contact-us-form-consent")}
                </p>
                <div className="flex justify-center text-xs">
                    <Link to={`/${t("route-privacy-policy")}`}>
                        <TrackableButton
                            category="CTA"
                            action="Click"
                            label="Contact Privacy Policy button"
                            className="underline text-xs hover:text-blue-500">{t("footer-column-2-policy")}
                        </TrackableButton>
                    </Link>
                    <Link to={`/${t("route-terms-conditions")}`}>
                        <TrackableButton
                            category="CTA"
                            action="Click"
                            label="Contact T&C button"
                            className="underline text-xs ml-2 hover:text-blue-500">{t("footer-column-2-terms-conditions")}
                        </TrackableButton>
                    </Link>
                </div>
            </div>

            {/* Submit */}
            <TrackableButton
                category="CTA"
                action="Click"
                label="Contact form submit button"
                whileHover={{scale: 1.01}}
                whileTap={{scale: 0.99}}
                type="submit"
                className={`${selected === "company" ? "bg-white_smoke text-green_dark_brand" : "bg-white_smoke text-green_brand"} transition-colors duration-[750ms] text-lg text-center rounded-lg w-full py-3 font-semibold`}
            >
                {isSubmitting ? t("contact-us-form-button") : t("contact-us-form-button")}
            </TrackableButton>
        </form>
    );
};

const FormSelect = ({
                        selected,
                        setSelected,
                    }: {
    selected: "company" | "individual";
    setSelected: Dispatch<SetStateAction<"company" | "individual">>;
}) => {
    const {t} = useTranslation();

    return (
        <div className="flex flex-col sm:flex border-[1px] rounded border-white overflow-hidden font-medium w-full">
            <button
                className={`${selected === "individual" ? "text-green_light_brand" : "text-white_smoke"} text-sm px-3 py-1.5 transition-colors duration-[750ms] relative w-full`}
                onClick={() => setSelected("individual")}
            >
                <span className="relative z-10">{t("contact-us-form-individual")}</span>
                {selected === "individual" && (
                    <motion.div
                        transition={BASE_TRANSITION}
                        layoutId="form-tab"
                        className="absolute inset-0 bg-white_smoke z-0"
                    />
                )}
            </button>
            <button
                className={`${selected === "company" ? "text-green_dark_brand" : "text-white_smoke"} text-sm px-3 py-1.5 transition-colors duration-[750ms] relative w-full`}
                onClick={() => setSelected("company")}
            >
                <span className="relative z-10">{t("contact-us-form-a-company")}</span>
                {selected === "company" && (
                    <motion.div
                        transition={BASE_TRANSITION}
                        layoutId="form-tab"
                        className="absolute inset-0 bg-white_smoke z-0"
                    />
                )}
            </button>
        </div>
    );
};

const Images = ({selected}: { selected: "company" | "individual" }) => {
    const {i18n} = useTranslation(); // Hook to get current language
    const [individualImageUrl, setIndividualImageUrl] = useState('');
    const [companyImageUrl, setCompanyImageUrl] = useState('');

    useEffect(() => {
        const cld = new Cloudinary({
            cloud: {
                cloudName: import.meta.env.VITE_CLOUDINARY_CLOUD_NAME,
            }
        });

        // Load the images
        const deIndividualImage = cld.image(import.meta.env.VITE_CLOUDINARY_CONTACT_INDIVIDUAL_PHOTO_PUBLIC_ID);
        const deCompanyImage = cld.image(import.meta.env.VITE_CLOUDINARY_CONTACT_COMPANY_PHOTO_PUBLIC_ID);
        const enIndividualImage = cld.image(import.meta.env.VITE_CLOUDINARY_CONTACT_INDIVIDUAL_ENGLISH_PHOTO_PUBLIC_ID);
        const enCompanyImage = cld.image(import.meta.env.VITE_CLOUDINARY_CONTACT_COMPANY_ENGLISH_PHOTO_PUBLIC_ID);

        // Select the appropriate images based on the current language
        if (i18n.language === 'en') {
            setIndividualImageUrl(enIndividualImage.toURL());
            setCompanyImageUrl(enCompanyImage.toURL());
        } else {
            setIndividualImageUrl(deIndividualImage.toURL());
            setCompanyImageUrl(deCompanyImage.toURL());
        }
    }, [i18n.language]);

    const scaleStyle = {
        transform: "scale(0.8)", // Adjust scale factor to desired level
        transformOrigin: "center"
    };

    return (
        <div className="bg-white relative overflow-hidden w-full min-h-[100px]">
            <motion.div
                initial={false}
                animate={{x: selected === "individual" ? "0%" : "100%"}}
                transition={BASE_TRANSITION}
                className="absolute inset-0 bg-slate-200"
                style={{
                    backgroundImage: `url(${individualImageUrl})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            />
            <motion.div
                initial={false}
                animate={{x: selected === "company" ? "0%" : "-100%"}}
                transition={BASE_TRANSITION}
                className="absolute inset-0 bg-slate-200"
                style={{
                    ...scaleStyle,
                    backgroundImage: `url(${companyImageUrl})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                }}
            />
        </div>
    );
};

export default ContactForm;

const BASE_TRANSITION = {ease: "anticipate", duration: 0.75};
