import {SupportChangingText} from "../components/SupportChangingText.tsx";
import SupportCards from "../components/SupportCards.tsx";
import {AuroraBg} from "../components/AuroraBg.tsx";
import BasicFAQ from "../components/BasicFAQ.tsx";
import {Helmet} from "react-helmet-async";
import i18n, {t} from "i18next";

const Support = () => {
    return (
        <>
            <Helmet>
                {/* General SEO */}
                <title>{t("helmet-support-title")}</title>
                <meta name="description" content={t("helmet-support-description")}/>
                <meta name="keywords" content={t("helmet-support-keywords")}/>

                {/* Open Graph for social media sharing */}
                <meta property="og:title" content={t("helmet-support-title")}/>
                <meta property="og:description" content={t("helmet-support-description")}/>
                <meta property="og:type" content="website"/>
                <meta property="og:url"
                      content={i18n.language === 'de'
                          ? 'https://www.2xwd.de/support'
                          : 'https://www.2xwd.com/support'}/>
                <meta property="og:image" content={t("helmet-support-content-url-image")}/>

                {/* Twitter Card for Twitter sharing */}
                <meta name="twitter:card" content={t("helmet-support-twitter-card")}/>
                <meta name="twitter:title" content={t("helmet-support-title")}/>
                <meta name="twitter:description" content={t("helmet-support-description")}/>
                <meta name="twitter:image" content={t("helmet-support-content-url-image")}/>

                {/* Additional SEO and Meta Tags */}
                <meta name="robots" content="index, follow"/>
                <link rel="canonical"
                      href={i18n.language === 'de'
                          ? 'https://www.2xwd.de/support'
                          : 'https://www.2xwd.com/support'}/>

                {/* hreflang tags for alternate language versions */}
                <link rel="alternate" href="https://www.2xwd.de/support" hrefLang="de"/>
                <link rel="alternate" href="https://www.2xwd.com/support" hrefLang="en"/>
            </Helmet>


            <section className="font-poppins w-[100%] bg-white">
                <div className="relative w-full h-[700px] sm:h-[500px] md:h-[600px] lg:h-[700px] support-bg px-4">
                </div>
                <div className="">
                    <SupportChangingText/>
                    <SupportCards/>
                    <AuroraBg/>
                    <BasicFAQ/>
                </div>
            </section>
        </>
    )
}
export default Support
