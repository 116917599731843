import {useLayoutEffect} from 'react';
import {useLocation} from 'react-router-dom';

const ScrollToTop = () => {
    const {pathname} = useLocation();

    useLayoutEffect(() => {
        window.history.scrollRestoration = 'manual';
        const timeoutId = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100); // Delay of 100 milliseconds to ensure the page is fully loaded

        return () => clearTimeout(timeoutId);
    }, [pathname]);

    return null;
};

export default ScrollToTop;
