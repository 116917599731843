import {ReactNode, useRef} from "react";
import {motion, useScroll, useTransform} from "framer-motion";
import {FiArrowUpRight} from "react-icons/fi";
import {Cloudinary} from "@cloudinary/url-gen";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TrackableButton from "./TrackableButton.tsx";
import {Helmet} from "react-helmet-async";

export const VisibilityScrollSection = () => {
    const {t} = useTranslation();


    const cld = new Cloudinary({
        cloud: {
            cloudName: import.meta.env.VITE_CLOUDINARY_CLOUD_NAME,
        }
    });

    const getImageURL = (publicId: string | undefined) => {
        return cld.image(publicId).toURL()
    }

    const VisibilityImage1 = getImageURL(import.meta.env.VITE_CLOUDINARY_VISIBILITY_GLOBAL_PUBLIC_ID)
    const TrustImage1 = getImageURL(import.meta.env.VITE_CLOUDINARY_VISIBILITY_TRUST_PUBLIC_ID)
    const SocialImage1 = getImageURL(import.meta.env.VITE_CLOUDINARY_VISIBILITY_SOCIAL_MEDIA_PUBLIC_ID)

    return (
        <>
            <Helmet>
                {/*<title>{t("helmet-visibility-sgs-title")}</title>*/}
                <meta name="description"
                      content={t("helmet-visibility-sgs-description")}/>
                <meta name="keywords"
                      content={t("helmet-visibility-sgs-keywords")}/>
            </Helmet>

            <div className="bg-white font-poppins w-full flex justify-center">
                <div className="max-w-[1600px] w-full">
                    <TextParallaxContent
                        imgUrl={VisibilityImage1}
                        subheading={t("visibility-scroll-section-1-subheading-1")}
                        heading={t("visibility-scroll-section-1-heading-1")}
                    >
                        <ExampleContent/>
                    </TextParallaxContent>
                    <TextParallaxContent
                        imgUrl={TrustImage1}
                        subheading={t("visibility-scroll-section-2-subheading-1")}
                        heading={t("visibility-scroll-section-2-heading-1")}
                    >
                        <TrustContent/>
                    </TextParallaxContent>
                    <TextParallaxContent
                        imgUrl={SocialImage1}
                        subheading={t("visibility-scroll-section-3-subheading-1")}
                        heading={t("visibility-scroll-section-3-heading-1")}
                    >
                        <SocialContent/>
                    </TextParallaxContent>
                </div>
            </div>
        </>
    );
};

const IMG_PADDING = 12;

const TextParallaxContent = ({
                                 imgUrl,
                                 subheading,
                                 heading,
                                 children,
                             }: {
    imgUrl: string;
    subheading: string;
    heading: string;
    children: ReactNode;
}) => {
    return (
        <div
            style={{
                paddingLeft: IMG_PADDING,
                paddingRight: IMG_PADDING,
            }}
        >
            <div className="relative h-[150vh]">
                <StickyImage imgUrl={imgUrl}/>
                <OverlayCopy heading={heading} subheading={subheading}/>
            </div>
            {children}
        </div>
    );
};

const StickyImage = ({imgUrl}: { imgUrl: string }) => {
    const targetRef = useRef(null);
    const {scrollYProgress} = useScroll({
        target: targetRef,
        offset: ["end end", "end start"],
    });

    const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
    const opacity = useTransform(scrollYProgress, [0, 1], [1, 0]);

    return (
        <motion.div
            style={{
                backgroundImage: `url(${imgUrl})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: `calc(100vh - ${IMG_PADDING * 2}px)`,
                top: IMG_PADDING,
                scale,
            }}
            ref={targetRef}
            className="sticky z-0 overflow-hidden rounded-3xl"
        >
            <motion.div
                className="absolute inset-0 bg-neutral-950/70"
                style={{
                    opacity,
                }}
            />
        </motion.div>
    );
};

const OverlayCopy = ({
                         subheading,
                         heading,
                     }: {
    subheading: string;
    heading: string;
}) => {
    const targetRef = useRef(null);
    const {scrollYProgress} = useScroll({
        target: targetRef,
        offset: ["start end", "end start"],
    });

    const y = useTransform(scrollYProgress, [0, 1], [250, -250]);
    const opacity = useTransform(scrollYProgress, [0.25, 0.5, 0.75], [0, 1, 0]);

    return (
        <motion.div
            style={{
                y,
                opacity,
            }}
            ref={targetRef}
            className="absolute left-0 top-0 flex h-screen w-full flex-col items-center justify-center text-white"
        >
            <p className="mb-2 text-center text-xl md:mb-4 md:text-3xl">
                {subheading}
            </p>
            <p className="text-center text-4xl font-bold md:text-7xl">{heading}</p>
        </motion.div>
    );
};

const ExampleContent = () => {
    const {t} = useTranslation();
    return (
        <div className="mx-auto grid max-w-5xl grid-cols-1 gap-8 px-4 pb-24 pt-12 md:grid-cols-12">
            <h2 className="col-span-1 text-3xl font-bold md:col-span-4 text-neutral-600">
                {t("visibility-scroll-section-1-image-text-1")}
            </h2>
            <div className="col-span-1 md:col-span-8">
                <p className="mb-4 text-xl text-neutral-600 md:text-2xl">
                    {t("visibility-scroll-section-1-text-1")}
                </p>
                <p className="mb-8 text-xl text-neutral-600 md:text-2xl">
                    {t("visibility-scroll-section-1-text-2")}
                </p>
                <p className="mb-8 text-xl text-neutral-600 md:text-2xl">
                    {t("visibility-scroll-section-1-text-3")}
                </p>
                <Link to={`/${t("route-visibility-seo")}`}>
                    <TrackableButton
                        category="CTA"
                        action="Click"
                        label="Visibility SEO button"
                        className="btn group">
                        {t("visibility-button")} <FiArrowUpRight
                        className="inline group-hover:animate-pulse"/>
                    </TrackableButton>
                </Link>
            </div>
        </div>
    )
};

const TrustContent = () => {
    const {t} = useTranslation();

    return (
        <div className="mx-auto grid max-w-5xl grid-cols-1 gap-8 px-4 pb-24 pt-12 md:grid-cols-12">
            <h2 className="col-span-1 text-3xl font-bold md:col-span-4 text-neutral-600">
                {t("visibility-scroll-section-2-image-text-1")}
            </h2>
            <div className="col-span-1 md:col-span-8">
                <p className="mb-4 text-xl text-neutral-600 md:text-2xl">
                    {t("visibility-scroll-section-2-text-1")}
                </p>
                <p className="mb-8 text-xl text-neutral-600 md:text-2xl">
                    {t("visibility-scroll-section-2-text-2")}
                </p>
                <p className="mb-8 text-xl text-neutral-600 md:text-2xl">
                    {t("visibility-scroll-section-2-text-3")}</p>
                <Link to={`/${t("route-visibility-seo")}`}>
                    <TrackableButton
                        category="CTA"
                        action="Click"
                        label="Visibility Google Analytics button"
                        className="btn group">
                        {t("visibility-button")} <FiArrowUpRight className="inline group-hover:animate-pulse"/>
                    </TrackableButton>
                </Link>

            </div>
        </div>
    )
};


const SocialContent = () => {
    const {t} = useTranslation();

    return (
        <div className="mx-auto grid max-w-5xl grid-cols-1 gap-8 px-4 pb-24 pt-12 md:grid-cols-12">
            <h2 className="col-span-1 text-3xl font-bold md:col-span-4 text-neutral-600">
                {t("visibility-scroll-section-3-image-text-1")}
            </h2>
            <div className="col-span-1 md:col-span-8">
                <p className="mb-4 text-xl text-neutral-600 md:text-2xl">
                    {t("visibility-scroll-section-3-text-1")}
                </p>
                <p className="mb-8 text-xl text-neutral-600 md:text-2xl">
                    {t("visibility-scroll-section-3-text-2")}
                </p>
                <p className="mb-8 text-xl text-neutral-600 md:text-2xl">
                    {t("visibility-scroll-section-3-text-3")}</p>
                <Link to={`/${t("route-visibility-seo")}`}>
                    <TrackableButton
                        category="CTA"
                        action="Click"
                        label="Visibility Social Media button"
                        className="btn group">
                        {t("visibility-button")} <FiArrowUpRight className="inline group-hover:animate-pulse"/>
                    </TrackableButton>
                </Link>
            </div>
        </div>
    )
};

