// hooks/useAnalytics.ts
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import ReactGA from 'react-ga4';

const useAnalytics = (shouldUseAnalytics: boolean) => {
    const location = useLocation();

    useEffect(() => {
        if (!shouldUseAnalytics) return;

        // Track page view
        ReactGA.send({hitType: 'pageview', page: location.pathname});

        const start = Date.now();

        const handleScroll = () => {
            ReactGA.event({
                category: 'User',
                action: 'Scrolled',
                label: location.pathname,
                value: window.scrollY
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            const end = Date.now();
            const timeSpent = end - start;
            ReactGA.event({
                category: 'User',
                action: 'Time Spent',
                label: location.pathname,
                value: timeSpent
            });

            window.removeEventListener('scroll', handleScroll);
        };
    }, [location, shouldUseAnalytics]);
};

export default useAnalytics;
