import React, {useMemo, useRef} from 'react';
import {animateWithGsap, useGsapChangingCards} from '../utils/animations.tsx';
import {useGSAP} from "@gsap/react";
import {useTranslation} from "react-i18next";

const colors = [
    '#004548',
    '#00797e',
    '#00ADB5',
    '#4cc5cb',
    '#99dee1',
];

const WhyWeHelpSmallBusinesses: React.FC = () => {
    const {t} = useTranslation();
    const reasons = useMemo(() => [
        t("main-focus-card-1"),
        t("main-focus-card-2"),
        t("main-focus-card-3"),
        t("main-focus-card-4"),
        t("main-focus-card-5"),
        t("main-focus-card-6"),
    ], [t]);

    const refs = useRef(reasons.map(() => React.createRef<HTMLDivElement>()));

    useGSAP(() => {
        animateWithGsap('.title', {y: -10, opacity: 1, delay: 0.5});
    }, []);

    useGsapChangingCards(refs.current, colors);


    return (
        <>
            {/*<Helmet>*/}
            {/*    /!*<title>{t("helmet-home-WhyWeHelpSmallBusinesses-title")}</title>*!/*/}
            {/*    <meta*/}
            {/*        name="description"*/}
            {/*        content={t("helmet-home-WhyWeHelpSmallBusinesses-description")}*/}
            {/*    />*/}
            {/*    <meta*/}
            {/*        name="keywords"*/}
            {/*        content={t("helmet-home-WhyWeHelpSmallBusinesses-keywords")}*/}
            {/*    />*/}
            {/*</Helmet>*/}

            <div
                className="why-help flex justify-center items-center text-center px-4 lg:px-8 py-36 bg-white font-poppins">
                <div className="max-w-[1600px]">
                    <h2 className="title opacity-0 text-3xl md:text-5xl text-gray font-semibold dark:text-black mb-28">
                        {t("main-focus-header-1")} <span
                        className="text-green_brand font-semibold">{t("main-focus-header-2")}</span>
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {reasons.map((text, index) => (
                            <div
                                key={index}
                                className="flex justify-center items-center text-center h-[200px] p-6 rounded-lg shadow-lg text-white"
                                ref={refs.current[index]}
                                style={{
                                    backgroundColor: colors[index % colors.length],
                                }}
                            >
                                <p className="text-lg">{text}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default WhyWeHelpSmallBusinesses;
