import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";
import i18n from "i18next";

const PrivacyPolicy = () => {
    const {t} = useTranslation();

    return (
        <>
            <Helmet>
                {/* General SEO */}
                <title>{t("helmet-privacy-title")}</title>
                <meta name="description" content={t("helmet-privacy-description")}/>
                <meta name="keywords" content={t("helmet-privacy-keywords")}/>

                {/* Open Graph for social media sharing */}
                <meta property="og:title" content={t("helmet-privacy-title")}/>
                <meta property="og:description" content={t("helmet-privacy-description")}/>
                <meta property="og:type" content="website"/>
                <meta property="og:url"
                      content={i18n.language === 'de'
                          ? 'https://www.2xwd.de/datenschutz'
                          : 'https://www.2xwd.com/privacy-policy'}/>
                <meta property="og:image" content={t("helmet-privacy-content-url-image")}/>

                {/* Twitter Card for Twitter sharing */}
                <meta name="twitter:card" content={t("helmet-privacy-twitter-card")}/>
                <meta name="twitter:title" content={t("helmet-privacy-title")}/>
                <meta name="twitter:description" content={t("helmet-privacy-description")}/>
                <meta name="twitter:image" content={t("helmet-privacy-content-url-image")}/>

                {/* Additional SEO and Meta Tags */}
                <meta name="robots" content="index, follow"/>
                <link rel="canonical"
                      href={i18n.language === 'de'
                          ? 'https://www.2xwd.de/datenschutz'
                          : 'https://www.2xwd.com/privacy-policy'}/>

                {/* hreflang tags for alternate language versions */}
                <link rel="alternate" href="https://www.2xwd.de/datenschutz" hrefLang="de"/>
                <link rel="alternate" href="https://www.2xwd.com/privacy-policy" hrefLang="en"/>
            </Helmet>

            <div className="common-padding bg-white flex flex-col items-center text-black w-full">

                <h1 className="py-12 font-semibold break-words">{t("privacy-main-header")}</h1>
                <div className="items-start w-full">
                    <div className="text-3xl font-semibold py-8">{t("privacy-header-section-1")}</div>
                    <div className="text-2xl font-semibold">{t("privacy-title-section-1")}</div>
                    <p>{t("privacy-text-section-1")}</p>

                    <div className="text-2xl font-semibold pt-8">{t("privacy-title-section-1.2")}</div>
                    <p>{t("privacy-text-section-1.2")}</p>

                    <div className="text-lg font-semibold pt-8">{t("privacy-small-title-section-1.2-1")}</div>
                    <p>{t("privacy-small-text-section-1.2-1")}</p>
                    <p className="pt-8">{t("privacy-small-text-section-1.2-2")}</p>

                    <div className="text-lg font-semibold pt-8">{t("privacy-small-title-section-1.3-1")}</div>
                    <p>{t("privacy-small-text-section-1.3-1")}</p>

                    <div className="text-lg font-semibold pt-8">{t("privacy-small-title-section-1.4-1")}</div>
                    <p>{t("privacy-small-text-section-1.4-1")}</p>
                    <p className="pt-8">{t("privacy-small-text-section-1.4-2")}</p>

                    <div className="text-lg font-semibold pt-8">{t("privacy-small-title-section-1.5-1")}</div>
                    <p>{t("privacy-small-text-section-1.5-1")}</p>

                    {/*Hosting*/}
                    <div className="text-3xl font-semibold py-8">{t("privacy-hosting-header-section")}</div>
                    <p className="pb-8">{t("privacy-hosting-text-section-1")}</p>
                    <p className="pb-8">{t("privacy-hosting-text-section-2")}</p>
                    <p className="pb-8">{t("privacy-hosting-text-section-3")}</p>
                    <p className="pb-8">{t("privacy-hosting-text-section-4")}</p>
                    <p className="pb-8">{t("privacy-hosting-text-section-5")}</p>

                    {/*General information and mandatory information*/}
                    <div className="text-3xl font-semibold py-8">{t("privacy-general-info-header-section")}</div>
                    <div className="text-2xl font-semibold pb-8">{t("privacy-general-info-title-section-1")}</div>
                    <p className="pb-8">{t("privacy-general-info-text-section-1")}</p>
                    <div className="text-2xl font-semibold pb-8">{t("privacy-general-info-title-section-1.2")}
                    </div>
                    <p className="pb-8">{t("privacy-general-info-text-section-1.2")}</p>
                    <div className="text-2xl font-semibold pb-8">{t("privacy-general-info-title-section-1.3")}
                    </div>
                    <p className="pb-8">{t("privacy-general-info-text-section-1.3")}</p>
                    <div className="text-2xl font-semibold pb-8">{t("privacy-general-info-title-section-1.4")}</div>
                    <p className="pb-8">{t("privacy-general-info-text-section-1.4")}</p>
                    <div className="text-2xl font-semibold pb-8">{t("privacy-general-info-title-section-1.5")}
                    </div>
                    <p className="pb-8">{t("privacy-general-info-text-section-1.5-1")}
                    </p>
                    <p className="pb-8">{t("privacy-general-info-text-section-1.5-2")}</p>
                    <div className="text-2xl font-semibold pb-8">{t("privacy-general-info-title-section-1.6")}
                    </div>
                    <p className="pb-8">{t("privacy-general-info-text-section-1.6")}</p>
                    <div className="text-2xl font-semibold pb-8">{t("privacy-general-info-title-section-1.7")}</div>
                    <p className="pb-8">{t("privacy-general-info-text-section-1.7")}</p>
                    <div className="text-2xl font-semibold pb-8">{t("privacy-general-info-title-section-1.8")}</div>
                    <div className="text-base pb-8">{t("privacy-general-info-text-section-1.8-1")}
                        <ul className="list-disc pl-5">
                            <li className="py-8">{t("privacy-general-info-text-section-1.8-2")}
                            </li>
                            <li className="pb-8">{t("privacy-general-info-text-section-1.8-3")}
                            </li>
                            <li className="pb-8">{t("privacy-general-info-text-section-1.8-4")}
                            </li>
                            <li>{t("privacy-general-info-text-section-1.8-5")}
                            </li>
                        </ul>
                    </div>

                    <p className="pb-8">{t("privacy-general-info-text-section-1.8-6")}</p>
                    <div className="text-2xl font-semibold pb-8">{t("privacy-general-info-title-section-1.9")}</div>
                    <p className="pb-8">{t("privacy-general-info-text-section-1.9")}</p>
                    <div className="text-2xl font-semibold pb-8">{t("privacy-general-info-title-section-1.10")}</div>
                    <p className="pb-8">{t("privacy-general-info-text-section-1.10-1")}</p>
                    <p className="pb-8">{t("privacy-general-info-text-section-1.10-2")}</p>
                    <p className="pb-8">{t("privacy-general-info-text-section-1.10-3")}</p>
                    <div className="text-3xl font-semibold py-8">{t("privacy-data-collection-header-section")}</div>
                    <div className="text-2xl font-semibold pb-8">{t("privacy-data-collection-title-section-1")}</div>
                    <p className="pb-8">{t("privacy-data-collection-text-section-1")}</p>
                    <p className="pb-8">{t("privacy-data-collection-text-section-1-2")}</p>
                    <p className="pb-8">{t("privacy-data-collection-text-section-1-3")}</p>
                    <p className="pb-8">{t("privacy-data-collection-text-section-1-4")}</p>
                    <p className="pb-8">{t("privacy-data-collection-text-section-1-5")}</p>
                    <p className="pb-8">{t("privacy-data-collection-text-section-1-6")}</p>
                    <p className="pb-8">{t("privacy-data-collection-google-analytics-table-title-1")}</p>
                    <div className="overflow-x-auto mx-auto max-w-full">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t("privacy-data-collection-google-analytics-table-column-header-1")}</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t("privacy-data-collection-google-analytics-table-column-header-2")}</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t("privacy-data-collection-google-analytics-table-column-header-3")}</th>
                            </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                            <tr>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{t("privacy-data-collection-google-analytics-table-row-1-1")}</td>
                                <td className="px-6 py-4 text-sm text-gray-500 break-words">{t("privacy-data-collection-google-analytics-table-row-1-2")}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{t("privacy-data-collection-google-analytics-table-row-1-3")}</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{t("privacy-data-collection-google-analytics-table-row-2-1")}</td>
                                <td className="px-6 py-4 text-sm text-gray-500 break-words">{t("privacy-data-collection-google-analytics-table-row-2-2")}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{t("privacy-data-collection-google-analytics-table-row-2-3")}</td>
                            </tr>
                            {/*<tr>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{t("privacy-data-collection-google-analytics-table-row-3-1")}</td>*/}
                            {/*    <td className="px-6 py-4 text-sm text-gray-500 break-words">{t("privacy-data-collection-google-analytics-table-row-3-2")}*/}
                            {/*    </td>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{t("privacy-data-collection-google-analytics-table-row-3-3")}</td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{t("privacy-data-collection-google-analytics-table-row-4-1")}</td>*/}
                            {/*    <td className="px-6 py-4 text-sm text-gray-500 break-words">{t("privacy-data-collection-google-analytics-table-row-4-2")}*/}
                            {/*    </td>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{t("privacy-data-collection-google-analytics-table-row-4-3")}</td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{t("privacy-data-collection-google-analytics-table-row-5-1")}</td>*/}
                            {/*    <td className="px-6 py-4 text-sm text-gray-500 break-words">{t("privacy-data-collection-google-analytics-table-row-5-2")}*/}
                            {/*    </td>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{t("privacy-data-collection-google-analytics-table-row-5-3")}</td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{t("privacy-data-collection-google-analytics-table-row-6-1")}</td>*/}
                            {/*    <td className="px-6 py-4 text-sm text-gray-500 break-words">{t("privacy-data-collection-google-analytics-table-row-6-2")}*/}
                            {/*    </td>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{t("privacy-data-collection-google-analytics-table-row-6-3")}</td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{t("privacy-data-collection-google-analytics-table-row-7-1")}</td>*/}
                            {/*    <td className="px-6 py-4 text-sm text-gray-500 break-words">{t("privacy-data-collection-google-analytics-table-row-7-2")}*/}
                            {/*    </td>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{t("privacy-data-collection-google-analytics-table-row-7-3")}</td>*/}
                            {/*</tr>*/}
                            </tbody>
                        </table>
                    </div>
                    <p className="py-8">{t("privacy-data-collection-google-analytics-table-2-title-1")}</p>
                    <div className="overflow-x-auto mx-auto max-w-full">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t("privacy-data-collection-google-analytics-table-2-column-header-1")}</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t("privacy-data-collection-google-analytics-table-2-column-header-2")}</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t("privacy-data-collection-google-analytics-table-2-column-header-3")}</th>
                            </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                            <tr>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{t("privacy-data-collection-google-analytics-table-2-row-1-1")}</td>
                                <td className="px-6 py-4 text-sm text-gray-500 break-words">{t("privacy-data-collection-google-analytics-table-2-row-1-2")}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{t("privacy-data-collection-google-analytics-table-2-row-1-3")}</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{t("privacy-data-collection-google-analytics-table-2-row-2-1")}</td>
                                <td className="px-6 py-4 text-sm text-gray-500 break-words">{t("privacy-data-collection-google-analytics-table-2-row-2-2")}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{t("privacy-data-collection-google-analytics-table-2-row-2-3")}</td>
                            </tr>
                            {/*<tr>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{t("privacy-data-collection-google-analytics-table-2-row-3-1")}</td>*/}
                            {/*    <td className="px-6 py-4 text-sm text-gray-500 break-words">{t("privacy-data-collection-google-analytics-table-2-row-3-2")}*/}
                            {/*    </td>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{t("privacy-data-collection-google-analytics-table-2-row-3-3")}</td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{t("privacy-data-collection-google-analytics-table-2-row-4-1")}</td>*/}
                            {/*    <td className="px-6 py-4 text-sm text-gray-500 break-words">{t("privacy-data-collection-google-analytics-table-2-row-4-2")}*/}
                            {/*    </td>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{t("privacy-data-collection-google-analytics-table-2-row-4-3")}</td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{t("privacy-data-collection-google-analytics-table-2-row-5-1")}</td>*/}
                            {/*    <td className="px-6 py-4 text-sm text-gray-500 break-words">{t("privacy-data-collection-google-analytics-table-2-row-5-2")}*/}
                            {/*    </td>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{t("privacy-data-collection-google-analytics-table-2-row-5-3")}</td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{t("privacy-data-collection-google-analytics-table-2-row-6-1")}</td>*/}
                            {/*    <td className="px-6 py-4 text-sm text-gray-500 break-words">{t("privacy-data-collection-google-analytics-table-2-row-6-2")}*/}
                            {/*    </td>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{t("privacy-data-collection-google-analytics-table-2-row-6-3")}</td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{t("privacy-data-collection-google-analytics-table-2-row-7-1")}</td>*/}
                            {/*    <td className="px-6 py-4 text-sm text-gray-500 break-words">{t("privacy-data-collection-google-analytics-table-2-row-7-2")}*/}
                            {/*    </td>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{t("privacy-data-collection-google-analytics-table-2-row-7-3")}</td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{t("privacy-data-collection-google-analytics-table-2-row-8-1")}</td>*/}
                            {/*    <td className="px-6 py-4 text-sm text-gray-500 break-words">{t("privacy-data-collection-google-analytics-table-2-row-8-2")}*/}
                            {/*    </td>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{t("privacy-data-collection-google-analytics-table-2-row-8-3")}</td>*/}
                            {/*</tr>*/}
                            </tbody>
                        </table>
                    </div>
                    <p className="py-8">{t("privacy-data-collection-google-analytics-table-3-title-1")}</p>
                    <div className="overflow-x-auto mx-auto max-w-full">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t("privacy-data-collection-google-analytics-table-3-column-header-1")}</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t("privacy-data-collection-google-analytics-table-3-column-header-2")}</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t("privacy-data-collection-google-analytics-table-3-column-header-3")}</th>
                            </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                            <tr>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{t("privacy-data-collection-google-analytics-table-3-row-1-1")}</td>
                                <td className="px-6 py-4 text-sm text-gray-500 break-words">{t("privacy-data-collection-google-analytics-table-3-row-1-2")}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{t("privacy-data-collection-google-analytics-table-3-row-1-3")}</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{t("privacy-data-collection-google-analytics-table-3-row-2-1")}</td>
                                <td className="px-6 py-4 text-sm text-gray-500 break-words">{t("privacy-data-collection-google-analytics-table-3-row-2-2")}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{t("privacy-data-collection-google-analytics-table-3-row-2-3")}</td>
                            </tr>
                            {/*<tr>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{t("privacy-data-collection-google-analytics-table-3-row-3-1")}</td>*/}
                            {/*    <td className="px-6 py-4 text-sm text-gray-500 break-words">{t("privacy-data-collection-google-analytics-table-3-row-3-2")}*/}
                            {/*    </td>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{t("privacy-data-collection-google-analytics-table-3-row-3-3")}</td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{t("privacy-data-collection-google-analytics-table-3-row-4-1")}</td>*/}
                            {/*    <td className="px-6 py-4 text-sm text-gray-500 break-words">{t("privacy-data-collection-google-analytics-table-3-row-4-2")}*/}
                            {/*    </td>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{t("privacy-data-collection-google-analytics-table-3-row-4-3")}</td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{t("privacy-data-collection-google-analytics-table-3-row-5-1")}</td>*/}
                            {/*    <td className="px-6 py-4 text-sm text-gray-500 break-words">{t("privacy-data-collection-google-analytics-table-3-row-5-2")}*/}
                            {/*    </td>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{t("privacy-data-collection-google-analytics-table-3-row-5-3")}</td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{t("privacy-data-collection-google-analytics-table-3-row-6-1")}</td>*/}
                            {/*    <td className="px-6 py-4 text-sm text-gray-500 break-words">{t("privacy-data-collection-google-analytics-table-3-row-6-2")}*/}
                            {/*    </td>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{t("privacy-data-collection-google-analytics-table-3-row-6-3")}</td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{t("privacy-data-collection-google-analytics-table-3-row-7-1")}</td>*/}
                            {/*    <td className="px-6 py-4 text-sm text-gray-500 break-words">{t("privacy-data-collection-google-analytics-table-3-row-7-2")}*/}
                            {/*    </td>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{t("privacy-data-collection-google-analytics-table-3-row-7-3")}</td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{t("privacy-data-collection-google-analytics-table-3-row-8-1")}</td>*/}
                            {/*    <td className="px-6 py-4 text-sm text-gray-500 break-words">{t("privacy-data-collection-google-analytics-table-3-row-8-2")}*/}
                            {/*    </td>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{t("privacy-data-collection-google-analytics-table-3-row-8-3")}</td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{t("privacy-data-collection-google-analytics-table-3-row-9-1")}</td>*/}
                            {/*    <td className="px-6 py-4 text-sm text-gray-500 break-words">{t("privacy-data-collection-google-analytics-table-3-row-9-2")}*/}
                            {/*    </td>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{t("privacy-data-collection-google-analytics-table-3-row-9-3")}</td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{t("privacy-data-collection-google-analytics-table-3-row-10-1")}</td>*/}
                            {/*    <td className="px-6 py-4 text-sm text-gray-500 break-words">{t("privacy-data-collection-google-analytics-table-3-row-10-2")}*/}
                            {/*    </td>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{t("privacy-data-collection-google-analytics-table-3-row-10-3")}</td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{t("privacy-data-collection-google-analytics-table-3-row-11-1")}</td>*/}
                            {/*    <td className="px-6 py-4 text-sm text-gray-500 break-words">{t("privacy-data-collection-google-analytics-table-3-row-11-2")}*/}
                            {/*    </td>*/}
                            {/*    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{t("privacy-data-collection-google-analytics-table-3-row-11-3")}</td>*/}
                            {/*</tr>*/}
                            </tbody>
                        </table>
                    </div>
                    <div className="text-2xl font-semibold py-8">{t("privacy-data-collection-contact-form-title")}</div>
                    <p className="pb-8">{t("privacy-data-collection-contact-form-text-1")}</p>
                    <p className="pb-8">{t("privacy-data-collection-contact-form-text-2")}</p>
                    <p className="pb-8">{t("privacy-data-collection-contact-form-text-3")}</p>
                    <div className="text-2xl font-semibold pb-8">{t("privacy-data-collection-applicants-title")}</div>
                    <div className="text-base pb-8">{t("privacy-data-collection-applicants-text-1")}
                        <ul className="list-disc pl-5">
                            <li className="py-8">{t("privacy-data-collection-applicants-text-2")}
                            </li>
                            <li className="pb-8">{t("privacy-data-collection-applicants-text-3")}
                            </li>
                            <li className="pb-8">{t("privacy-data-collection-applicants-text-4")}
                            </li>
                            <li>{t("privacy-data-collection-applicants-text-5")}
                            </li>
                        </ul>
                    </div>
                    <div className="text-2xl font-semibold pb-8">{t("privacy-data-collection-storage-title")}</div>
                    <p className="pb-8">{t("privacy-data-collection-storage-text-1")}</p>
                    <p className="pb-8">{t("privacy-data-collection-storage-text-2")}</p>
                    <p className="pb-8">{t("privacy-data-collection-storage-text-3")}</p>
                    <p className="pb-8">{t("privacy-data-collection-storage-text-4")}</p>
                    <div className="text-2xl font-semibold pb-8">{t("privacy-data-collection-consent-title")}</div>
                    <p className="pb-8">{t("privacy-data-collection-consent-text-1")}</p>
                    <p className="pb-8">{t("privacy-data-collection-consent-text-2")}</p>

                    {/*Analysis, remarketing and retargeting tools*/}
                    <div className="text-3xl font-semibold py-8">{t("privacy-web-analytics-header-section")}</div>
                    <p className="pb-8">{t("privacy-web-analytics-header-section-text-1")}</p>
                    <p className="pb-8">{t("privacy-web-analytics-header-section-text-2")}</p>
                    <div className="text-2xl font-semibold pb-8">{t("privacy-web-analytics-google-tag-title")}</div>
                    <p className="pb-8">{t("privacy-web-analytics-google-tag-text-1")}</p>
                    <p className="pb-8">{t("privacy-web-analytics-google-tag-text-2")}</p>
                    <p className="pb-8">{t("privacy-web-analytics-google-tag-text-3")}</p>
                    <div
                        className="text-2xl font-semibold pb-8">{t("privacy-web-analytics-google-analytics-title")}</div>
                    <p className="pb-8">{t("privacy-web-analytics-google-analytics-text-1")}</p>
                    <p className="pb-8">{t("privacy-web-analytics-google-analytics-text-2")}</p>
                    <p className="pb-8">{t("privacy-web-analytics-google-analytics-text-3")}</p>
                    <p className="pb-8">{t("privacy-web-analytics-google-analytics-text-4")}</p>
                    <p className="pb-8">{t("privacy-web-analytics-google-analytics-text-5")}</p>
                    <p className="pb-8">{t("privacy-web-analytics-google-analytics-text-6")}</p>
                    <div
                        className="text-2xl font-semibold pb-8">{t("privacy-web-analytics-browser-plug-in-title")}</div>
                    <p className="pb-8">{t("privacy-web-analytics-browser-plug-in-text")}</p>

                    {/*Analysis, remarketing and retargeting tools*/}
                    <div className="text-3xl font-semibold py-8">{t("privacy-plug-in-and-tools-header-section")}</div>
                    <div className="text-2xl font-semibold pb-8">{t("privacy-plug-in-and-tools-title-section-1")}</div>
                    <p className="pb-8">{t("privacy-plug-in-and-tools-text-1-section-1")}</p>
                    <p className="pb-8">{t("privacy-plug-in-and-tools-text-1-section-2")}</p>
                    <p className="pb-8">{t("privacy-plug-in-and-tools-text-1-section-3")}</p>
                    <p className="pb-8">{t("privacy-plug-in-and-tools-text-1-section-4")}</p>
                    <p className="pb-8">{t("privacy-plug-in-and-tools-text-1-section-5")}</p>
                    <p className="pb-8">{t("privacy-plug-in-and-tools-text-1-section-6")}<a className="underline"
                                                                                            href="https://policies.google.com/privacy">{t("privacy-plug-in-and-tools-text-1-section-7")}</a>
                    </p>
                    <div className="text-2xl font-semibold pb-8">{t("privacy-google-map-title-section-1")}</div>
                    <p className="pb-8">{t("privacy-google-map-text-1-section-1")}</p>
                    <p className="pb-8">{t("privacy-google-map-text-1-section-2")}</p>
                    <p className="pb-8">{t("privacy-google-map-text-1-section-3")}</p>
                    <p className="pb-8">{t("privacy-google-map-text-1-section-4")}<a className="underline"
                                                                                     href="https://business.safety.google/controllerterms/">{t("privacy-google-map-text-1-section-5")}</a>{t("privacy-google-map-text-1-section-6")}
                        <a className="underline"
                           href="https://business.safety.google/controllerterms/sccs/">{t("privacy-google-map-text-1-section-7")}</a>
                    </p>
                    <p className="pb-8">{t("privacy-google-map-text-1-section-8")}<a className="underline"
                                                                                     href="https://policies.google.com/privacy">{t("privacy-google-map-text-1-section-9")}</a>
                    </p>
                    <div className="text-2xl font-semibold pb-8">{t("privacy-google-fonts-title-section-1")}</div>
                    <p className="pb-8">{t("privacy-google-fonts-text-1-section-1")}</p>
                    <p className="pb-8">{t("privacy-google-fonts-text-1-section-2")}</p>
                    <p className="pb-8">{t("privacy-google-fonts-text-1-section-3")}<a className="underline"
                                                                                       href="https://developers.google.com/fonts/faq">{t("privacy-google-fonts-text-1-section-4")}</a>{t("privacy-google-fonts-text-1-section-5")}<a
                        className="underline"
                        href="https://policies.google.com/privacy">{t("privacy-google-fonts-text-1-section-6")}</a></p>
                    <div className="text-2xl font-semibold pb-8">{t("privacy-payment-services-title-section-1")}</div>
                    <p className="pb-8">{t("privacy-payment-services-text-1-section-1")}</p>
                    <p className="pb-8">{t("privacy-payment-services-text-1-section-2")}</p>
                </div>
            </div>
        </>
    )
}
export default PrivacyPolicy
