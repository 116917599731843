import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useGSAP} from '@gsap/react';
import gsap from 'gsap';
import {animateWithGsap} from '../utils/animations';
import {Cloudinary} from '@cloudinary/url-gen';
import {format, quality} from '@cloudinary/url-gen/actions/delivery';
import TrackableButton from "./TrackableButton.tsx";

interface FaceLiftProps {
    windowWidth: number;
}

const FaceLift: React.FC<FaceLiftProps> = ({windowWidth}) => {
    const {t} = useTranslation();

    useGSAP(() => {
        gsap.to('#title3', {opacity: 1, y: 0, delay: 0.2});
        animateWithGsap('.stagger5', {y: -10, opacity: 1, delay: 0.3});
        animateWithGsap('.stagger6', {y: -10, opacity: 1, delay: 0.4});
        gsap.to('.cta3', {opacity: 1, y: -50, delay: 0.5});
    }, []);

    const cld = new Cloudinary({
        cloud: {
            cloudName: import.meta.env.VITE_CLOUDINARY_CLOUD_NAME as string,
        },
    });

    const myImage = cld.image(import.meta.env.VITE_CLOUDINARY_RESPONSIVE_LARGER_PUBLIC_ID as string);
    myImage.delivery(format('auto')).delivery(quality('auto'));

    const [selectedOption, setSelectedOption] = useState<string>('c1');
    const [isMdOrSmaller, setIsMdOrSmaller] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMdOrSmaller(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        // Show all descriptions if on medium or smaller screens
        if (isMdOrSmaller) {
            gsap.to('.video-description', {opacity: 1, transform: 'translateY(0)'});
        }
    }, [isMdOrSmaller]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!isMdOrSmaller) {
            setSelectedOption(event.target.value);
        }
    };

    return (
        <>
            {/*<Helmet>*/}
            {/*    /!*<title>{t("helmet-home-FaceLift-title")}</title>*!/*/}
            {/*    <meta*/}
            {/*        name="description"*/}
            {/*        content={t("helmet-home-FaceLift-description")}*/}
            {/*    />*/}
            {/*    <meta*/}
            {/*        name="keywords"*/}
            {/*        content={t("helmet-home-FaceLift-keywords")}*/}
            {/*    />*/}
            {/*</Helmet>*/}

            <section className="w-full overflow-hidden font-poppins relative h-max">
                <video
                    src={cld.video('/2XWD/Video/modern-bg_sik7sz.mp4').quality('auto').toURL()}
                    autoPlay
                    loop
                    muted
                    className="absolute top-0 left-0 w-full object-cover h-[calc(100%+2rem)]"
                />
                <div className="flex flex-col screen-max-width common-padding items-center justify-center">
                    <div className="flex flex-col items-center w-full">
                        <h1 id="title3" className="section-heading pb-4 xl:pb-16 uppercase">
                            {t('what-we-do-header-text')}
                        </h1>
                    </div>

                    <div
                        className={
                            windowWidth < 1024
                                ? 'flex flex-col items-center text-left w-full h-[100%] pt-20 text-green_brand'
                                : 'flex flex-col lg:text-left lg:items-left lg:mr-[2rem] w-full h-[100%] lg:w-[45rem] lg:pt-20 pt-20 text-green_brand'
                        }
                    >
                        <h2 className="stagger5 opacity-0 poppins-semibold pb-1 xl:pb-6 mt-[-1rem] text-center lg:text-left">
                            {t('face-lift-sub-header-text')}
                        </h2>
                        <p className="stagger6 opacity-0 text-zinc mt-2 lg:mt-5 text-md lg:text-xl leading-loose lg:leading-[3.25rem]">
                            {t('face-lift-text')}
                        </p>
                        <div className="video-wrapper pt-[5rem]">
                            <div className="video-container">
                                {['c1', 'c2', 'c3', 'c4'].map((id) => (
                                    <React.Fragment key={id}>
                                        <input
                                            type="radio"
                                            name="slide"
                                            id={id}
                                            value={id}
                                            checked={!isMdOrSmaller && selectedOption === id}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor={id} className="video-card">
                                            <div className="video-row glass-facelift">
                                                <div className="video-icon">{id.slice(1)}</div>
                                                <div className="video-description">
                                                    <h4 className="text-green_brand">
                                                        {t(`face-lift-card-${id.slice(1)}-header`)}
                                                    </h4>
                                                    <p>{t(`face-lift-card-${id.slice(1)}-text`)}</p>
                                                </div>
                                            </div>
                                        </label>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                        <div
                            className="cta3 flex opacity-0 items-center justify-center text-white w-full translate-y-20 pt-40">
                            <Link to={`/${t("route-contact")}`}><TrackableButton category="CTA" action="Click"
                                                                                 label="Responsive cta button"
                                                                                 className="cta3 btn">
                                {t('cta-button-text')}</TrackableButton>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FaceLift;
