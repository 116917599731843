// Home.tsx

import HeroHomepage from "../components/HeroHomepage.tsx";
import WhatWeDo from "../components/WhatWeDo.tsx";
import ResponsiveWebsite from "../components/ResponsiveWebsite.tsx";
import FaceLift from "../components/FaceLift.tsx";
import {AuroraHero} from "../components/AuroraHero.tsx";
import {useEffect, useState} from "react";
import Lenis from "lenis";
import ProjectStructure from "../components/ProjectStructure.tsx";
import ProjectFlow from "../components/ProjectFlow.tsx";
import WhatSetsUsApart from "../components/WhatSetsUsApart.tsx";
import ContactForm from "../components/ContactForm.tsx";
import AlmostThereFiller from "../components/AlmostThereFiller.tsx";
import {CountUpStats} from "../components/CountUpStats.tsx";
import WhyWeHelpSmallBusinesses from "../components/WhyWeHelpSmallBusinesses.tsx";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";
import {AnimatedText} from "../components/AnimatedText";
import OurProjects from "../components/OurProjects.tsx"; // Import the new AnimatedText component

const Home = () => {
    const {t} = useTranslation();
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    useEffect(() => {
        const lenis = new Lenis();

        function raf(time: DOMHighResTimeStamp) {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }

        requestAnimationFrame(raf);

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            <Helmet>
                {/* General SEO */}
                <title>{t("helmet-home-page-title", "Home - 2xWD - We Do Web Design")}</title>
                <meta name="description" content={t("helmet-home-page-description")}/>
                <meta name="keywords" content={t("helmet-home-page-keywords")}/>

                {/* Open Graph for social media sharing */}
                <meta property="og:title" content={t("helmet-home-page-title", "Home - 2xWD - We Do Web Design")}/>
                <meta property="og:description" content={t("helmet-home-page-description")}/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://www.2xwd.com"/>
                <meta property="og:image"
                      content="https://res.cloudinary.com/dtnai7zbz/image/upload/v1723115548/2XWD/Images/contact-en-company_xbvid2.png"/>
                <meta property="og:site_name" content="2XWD"/>

                {/* Twitter Card for Twitter sharing */}
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content={t("helmet-home-page-title", "Home - 2xWD - We Do Web Design")}/>
                <meta name="twitter:description" content={t("helmet-home-page-description")}/>
                <meta name="twitter:image"
                      content="https://res.cloudinary.com/dtnai7zbz/image/upload/v1723115548/2XWD/Images/contact-en-company_xbvid2.png"/>
                <meta name="twitter:site" content="@YourTwitterHandle"/>
                {/* Add your Twitter handle */}

                {/* Structured Data with JSON-LD for better SERP visibility */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "2XWD - We Do Web Design",
                        "url": "https://www.2xwd.com",
                        "logo": "https://res.cloudinary.com/dtnai7zbz/image/upload/v1723115548/2XWD/Images/contact-en-company_xbvid2.png",
                        "description": t("helmet-home-page-description"),
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "telephone": "+49 1575 8215780",
                            "contactType": t("helmet-home-contact-type"),
                            "areaServed": [
                                t("helmet-home-area-served-us"),
                                t("helmet-home-area-served-ca"),
                                t("helmet-home-area-served-de"),
                                t("helmet-home-area-served-europe")
                            ],
                            "availableLanguage": [
                                t("helmet-home-available-languages-en"),
                                t("helmet-home-available-languages-de")
                            ]
                        },
                        "service": {
                            "@type": "Service",
                            "name": t("helmet-home-service-name"),
                            "description": t("helmet-home-service-description"),
                            "additionalType": "Service",
                            "featureList": [
                                {
                                    "@type": "CreativeWork",
                                    "name": t("helmet-home-page-name-1"),
                                    "text": t("helmet-home-page-text-1")
                                },
                                {
                                    "@type": "CreativeWork",
                                    "name": t("helmet-home-page-name-2"),
                                    "text": t("helmet-home-page-text-2")
                                },
                                {
                                    "@type": "CreativeWork",
                                    "name": t("helmet-home-page-name-3"),
                                    "text": t("helmet-home-page-text-3")
                                },
                                {
                                    "@type": "CreativeWork",
                                    "name": t("helmet-home-page-name-4"),
                                    "text": t("helmet-home-page-text-4")
                                }
                            ]
                        }
                    })}
                </script>

                {/* Additional SEO and Meta Tags */}
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://www.2xwd.com/"/>
                <link rel="alternate" href="https://www.2xwd.de/" hrefLang="de"/>
                <link rel="alternate" href="https://www.2xwd.com/" hrefLang="en"/>
            </Helmet>


            <div className="w-full bg-black relative">
                {/* Animated Text between HeroHomepage and AuroraHero */}
                {/*<div className="text-center overflow-hidden pt-48 md:pt-64">*/}
                {/*    <h1 className="h-full poppins-light pb-24 md:pb-36 lg:pb-40">*/}
                {/*        {t("bing-home-h1")}*/}
                {/*    </h1>*/}
                {/*</div>*/}
                <HeroHomepage windowWidth={windowWidth}/>

                <div className="text-center overflow-hidden">
                    <h1 className="h-full poppins-light py-24 md:py-36 lg:py-40">
                        <AnimatedText
                            text={t("about-hero-text-5")}
                            className="staggeredTextAnimationColor text-5xl md:text-7xl"
                        />
                    </h1>
                </div>

                <AuroraHero/>

                <WhyWeHelpSmallBusinesses/>
                <OurProjects/>
                <WhatWeDo windowWidth={windowWidth}/>
                <ResponsiveWebsite/>
                <FaceLift windowWidth={windowWidth}/>
                <CountUpStats/>
                <ProjectStructure/>
                <ProjectFlow/>
                <WhatSetsUsApart/>
                <AlmostThereFiller/>

                <div className="bg-white pb-32">
                    <div className="max-w-screen-2xl mx-auto">
                        <ContactForm/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
