import {IoCheckmark, IoTimeOutline} from 'react-icons/io5';
import {LiaHandsHelpingSolid} from 'react-icons/lia';
import {useTranslation} from "react-i18next";
import {useGSAP} from "@gsap/react";
import {animateWithGsap} from "../utils/animations.tsx";

const Diy3Cards = () => {
    const {t} = useTranslation();

    useGSAP(() => {
        animateWithGsap('.diy-card', {y: -10, opacity: 1, delay: 0.5, stagger: 0.5})
    }, [])

    return (
        <section className="py-24 bg-[#ededed] font-poppins">
            <div className="container mx-auto px-4">
                <div
                    className="diy-card opacity-0 translate-y-[10px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                        <IoTimeOutline className="text-5xl text-green_brand mb-4 mx-auto"/>
                        <h2 className="text-2xl text-black font-bold mb-4">{t("diy-card-title-1")}</h2>
                        <p className="text-neutral-400">{t("diy-card-text-1")}</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                        <IoCheckmark className="text-5xl text-green_brand mb-4 mx-auto"/>
                        <h2 className="text-2xl text-black font-bold mb-4">{t("diy-card-title-2")}</h2>
                        <p className="text-neutral-400">{t("diy-card-text-2")}</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                        <LiaHandsHelpingSolid className="text-5xl text-green_brand mb-4 mx-auto"/>
                        <h2 className="text-2xl text-black font-bold mb-4">{t("diy-card-title-3")}</h2>
                        <p className="text-neutral-400">{t("diy-card-text-3")}</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Diy3Cards;
