import Seo3Cards from "../components/Seo3Cards.tsx";
import SeoSection from "../components/SeoSection.tsx";
import {Helmet} from "react-helmet-async";
import i18n, {t} from "i18next";

const Seo = () => {

    return (
        <>
            <Helmet>
                {/* General SEO */}
                <title>{t("helmet-seo-title")}</title>
                <meta name="description" content={t("helmet-seo-description")}/>
                <meta name="keywords" content={t("helmet-seo-keywords")}/>

                {/* Open Graph for social media sharing */}
                <meta property="og:title" content={t("helmet-seo-title")}/>
                <meta property="og:description" content={t("helmet-seo-description")}/>
                <meta property="og:type" content="website"/>
                <meta property="og:url"
                      content={i18n.language === 'de'
                          ? 'https://www.2xwd.de/sichtbarkeit/seo'
                          : 'https://www.2xwd.com/visibility/seo'}/>
                <meta property="og:image" content={t("helmet-seo-content-url-image")}/>

                {/* Twitter Card for Twitter sharing */}
                <meta name="twitter:card" content={t("helmet-seo-twitter-card")}/>
                <meta name="twitter:title" content={t("helmet-seo-title")}/>
                <meta name="twitter:description" content={t("helmet-seo-description")}/>
                <meta name="twitter:image" content={t("helmet-seo-content-url-image")}/>

                {/* Additional SEO and Meta Tags */}
                <meta name="robots" content="index, follow"/>
                <link rel="canonical"
                      href={i18n.language === 'de'
                          ? 'https://www.2xwd.de/sichtbarkeit/seo'
                          : 'https://www.2xwd.com/visibility/seo'}/>

                {/* hreflang tags for alternate language versions */}
                <link rel="alternate" href="https://www.2xwd.de/sichtbarkeit/seo" hrefLang="de"/>
                <link rel="alternate" href="https://www.2xwd.com/visibility/seo" hrefLang="en"/>
            </Helmet>


            <section className="font-poppins w-[100%]">
                <div className="relative w-full h-[700px] seo-bg px-4">
                </div>
                <Seo3Cards/>
                <SeoSection/>
            </section>
        </>
    )
}
export default Seo
