import {AdvancedImage} from "@cloudinary/react";
import {Cloudinary} from "@cloudinary/url-gen";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import i18n from "i18next";
import {Helmet} from "react-helmet-async";

const Footer = () => {
    const {t} = useTranslation();
    const cld = new Cloudinary({
        cloud: {
            cloudName: import.meta.env.VITE_CLOUDINARY_CLOUD_NAME,
        }
    });
    const logoImage = cld.image(import.meta.env.VITE_CLOUDINARY_LOGO);
    const myImage = cld.image(import.meta.env.VITE_CLOUDINARY_ABOUT_FOOTER_PHOTO_PUBLIC_ID);
    return (
        <>
            <Helmet>
                {/*<title>{t("helmet-footer-title")}</title>*/}
                <meta
                    name="description"
                    content={t("helmet-footer-description")}
                />
                <meta
                    name="keywords"
                    content={t("helmet-footer-keywords")}
                />
            </Helmet>

            <footer
                className="footer-bg text-white w-screen h-[1500px] md:h-[1075px] lg:h-[650px] flex flex-col justify-between">
                <div className="flex justify-center px-4">
                    <div className="max-w-[1600px] w-full">
                        <div
                            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-14 gap-x-28 h-96 px-10 py-20">
                            <div className="footer-column">
                                <h3 className="text-lg font-semibold mb-4">{t("footer-column-1-header")}</h3>
                                <ul>
                                    <li className="flex items-center pt-5">
                                        <Link to="/" className="flex items-center">
                                            <AdvancedImage cldImg={logoImage} alt="logo" className="w-12 h-12 mr-4"/>
                                            <span className="text-xl">2xWD - We Do Web Design</span>
                                        </Link>
                                    </li>

                                    {/* Conditional rendering based on language */}
                                    {i18n.language !== 'en' && (
                                        <li className="pt-4">
                                            <AdvancedImage cldImg={myImage} alt="my image"
                                                           className="w-full lg:w-40 bg-cover rounded-lg mb-4"/>
                                        </li>
                                    )}

                                    <li className="pt-4">Hinterm Scheibenkamp 1</li>
                                    <li>26169 Friesoythe</li>
                                    <li className="pt-10">+49 1575 8215780</li>
                                    <li>ryan.shade@we-do-web-design.com</li>
                                </ul>
                            </div>
                            <div className="footer-column">
                                <hr className="w-full text-white_smoke mt-5 md:hidden"/>
                                <h3 className="text-lg font-semibold mb-4 pt-10 md:pt-0">{t("footer-column-2-header")}</h3>
                                <ul>
                                    <li className="pt-10"><Link to={`/${t("route-about")}`}
                                                                className="hover:underline">{t("footer-column-2-about")}</Link>
                                    </li>
                                    <li className="pt-10"><Link to={`/${t("route-privacy-policy")}`}
                                                                className="hover:underline">{t("footer-column-2-policy")}</Link>
                                    </li>
                                    <li className="pt-10"><Link to={`/${t("route-imprint")}`}
                                                                className="hover:underline">{t("footer-column-2-imprint")}</Link>
                                    </li>
                                    <li className="pt-10"><Link to={`/${t("route-terms-conditions")}`}
                                                                className="hover:underline">{t("footer-column-2-terms-conditions")}</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-column">
                                <hr className="w-full text-white_smoke mb-10 lg:hidden"/>
                                <h3 className="text-lg font-semibold mb-4">{t("footer-column-3-header")}</h3>
                                <ul>
                                    <li className="pt-10"><Link to={`/${t("route-visibility-seo")}`}
                                                                className="hover:underline">{t("footer-column-3-seo")}</Link>
                                    </li>
                                    <li className="pt-10"><Link to={`/${t("route-builds")}`}
                                                                className="hover:underline">{t("footer-column-3-builds")}</Link>
                                    </li>
                                    <li className="pt-10"><Link to={`/${t("route-support")}`}
                                                                className="hover:underline">{t("footer-column-3-support")}</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-center text-white bg-black w-full h-16 mt-10">
                    © 2025 2xWD - We Do Web Design
                </div>
            </footer>
        </>
    )
}
export default Footer
