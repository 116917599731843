import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import "./i18n.tsx";
import {BrowserRouter} from "react-router-dom";
import {LanguageProvider} from "./ui/LanguageAllOver.tsx";
import CookieConsentBanner from "./components/CookieConsentBanner.tsx";
import {HelmetProvider} from "react-helmet-async";

ReactDOM.createRoot(document.getElementById('root')!).render(
    <HelmetProvider>
        <BrowserRouter>
            <React.Suspense fallback="loading...">
                <LanguageProvider>
                    <App/>
                    <CookieConsentBanner/>
                </LanguageProvider>
            </React.Suspense>
        </BrowserRouter>
    </HelmetProvider>,
);
