import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {routeMapping} from './RouteMapping.tsx';

// Define valid language keys
type LanguageKeys = 'en' | 'de';

const LanguageNavigationHandler = () => {
    const {i18n} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const handleLanguageChange = () => {
            const segments = location.pathname.split('/').filter(Boolean);
            const currentPath = segments.join('/');

            // Explicitly tell TypeScript that the language is one of the keys (en or de)
            const newLang = i18n.language as LanguageKeys;
            let newPath = '/';

            if (segments.length > 0) {
                for (const [, value] of Object.entries(routeMapping)) {
                    // Now TypeScript knows that newLang is either 'en' or 'de'
                    if (value[i18n.language === 'en' ? 'de' : 'en'] === currentPath) {
                        newPath = `/${value[newLang]}`;
                        break;
                    }
                }
                navigate(newPath, {replace: true});
            } else {
                navigate('/', {replace: true});
            }

            // Update the H1 in the static index.html when language changes
            const h1Tag = document.getElementById('dynamic-h1');
            const h1Texts: Record<LanguageKeys, string> = {
                'en': "Custom Websites for Small Business Success",
                'de': "Maßgeschneiderte Websites für den Erfolg kleiner Unternehmen"
            };

            // Only update h1 if we are on the homepage
            const path = window.location.pathname;
            if (path === '/' || path === '/index.html') {
                if (h1Tag) {
                    h1Tag.textContent = h1Texts[newLang];  // Now TypeScript knows newLang is a valid key
                    h1Tag.style.display = 'block'; // Show the h1
                }
            } else {
                if (h1Tag) {
                    h1Tag.style.display = 'none'; // Hide the h1 on other pages
                }
            }
        };

        i18n.on('languageChanged', handleLanguageChange);
        document.body.classList.add(`lang-${i18n.language}`);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [i18n, navigate, location.pathname]);

    return null;
};

export default LanguageNavigationHandler;
