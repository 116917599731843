import {useTranslation} from "react-i18next";
import {useGSAP} from "@gsap/react";
import gsap from "gsap";

const AlmostThereFiller = () => {
    const {t} = useTranslation();

    useGSAP(() => {
        gsap.to(".filler-there", {opacity: 1, y: 0, delay: 0.2, stagger: 0.3})
    }, [])
    return (
        <>
            {/*<Helmet>*/}
            {/*    /!*<title>{t("helmet-home-AlmostThereFiller-title")}</title>*!/*/}
            {/*    <meta*/}
            {/*        name="description"*/}
            {/*        content={t("helmet-home-AlmostThereFiller-description")}*/}
            {/*    />*/}
            {/*    <meta*/}
            {/*        name="keywords"*/}
            {/*        content={t("helmet-home-AlmostThereFiller-keywords")}*/}
            {/*    />*/}
            {/*</Helmet>*/}

            <div
                className="overflow-hidden font-poppins bg-white flex flex-col items-center justify-center common-padding">
                <div className="filler-there opacity-0 w-screen items-center text-center">
                    <h1 className="text-green_brand pb-5">{t("almost-there-title")}</h1>
                    <p className="text-neutral-400 text-left md:text-center pb-20 lg:pb-14 xl:pb-0 mx-16 lg:mx-32 xl:mx-96 leading-loose xl:leading-[3.5rem]">{t("almost-there-text")}</p>
                </div>
            </div>
        </>
    )
}
export default AlmostThereFiller
