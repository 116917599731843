import {useTranslation} from "react-i18next";
import BasicFAQ from "../../components/BasicFAQ.tsx";
import SlidePricing from "../../components/SlidePricing.tsx";
import {Helmet} from "react-helmet-async";
import i18n from "i18next";

const Builds = () => {
    const {t} = useTranslation();

    return (
        <>
            <Helmet>
                {/* General SEO */}
                <title>{t("helmet-builds-title")}</title>
                <meta name="description" content={t("helmet-builds-description")}/>
                <meta name="keywords" content={t("helmet-builds-keywords")}/>

                {/* Open Graph for social media sharing */}
                <meta property="og:title" content={t("helmet-builds-title")}/>
                <meta property="og:description" content={t("helmet-builds-description")}/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={i18n.language === 'de'
                    ? 'https://www.2xwd.de/preise'
                    : 'https://www.2xwd.com/price'}/>
                <meta property="og:image" content={t("helmet-builds-image-url")}/>

                {/* Twitter Card for social sharing */}
                <meta name="twitter:card" content={t("helmet-builds-content-url-image")}/>
                <meta name="twitter:title" content={t("helmet-builds-title")}/>
                <meta name="twitter:description" content={t("helmet-builds-description")}/>
                <meta name="twitter:image" content={t("helmet-builds-image-url")}/>

                {/* Additional SEO and Meta Tags */}
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href={i18n.language === 'de'
                    ? 'https://www.2xwd.de/preise'
                    : 'https://www.2xwd.com/price'}/>

                {/* hreflang tags for alternate language versions */}
                <link rel="alternate" href="https://www.2xwd.de/preise" hrefLang="de"/>
                <link rel="alternate" href="https://www.2xwd.com/price" hrefLang="en"/>
            </Helmet>


            <div className="font-poppins w-[100%]">
                <section className="w-full pb-24 pt-56 pricing-bg px-4">
                    <div className="flex flex-col mx-auto text-center">
                        <h1 className="text-2xl md:text-5xl xl:text-6xl font-bold mb-12 break-words">
                            {t('hero-builds-title')}
                        </h1>
                        <p className="text-md md:text-lg xl:text-xl md:mx-10 m-auto xl:mx-20">
                            {t('hero-builds-text')}
                        </p>
                    </div>
                </section>
                <SlidePricing/>
                <BasicFAQ/>
            </div>
        </>
    )
}
export default Builds
