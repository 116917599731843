import React, {useEffect, useState} from "react";
import {VisibilityScrollSection} from "../components/VisibilityScrollSection";
import {useGSAP} from "@gsap/react";
import gsap from 'gsap';
import WhatSetsUsApart from "../components/WhatSetsUsApart";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";
import i18n from "i18next";

const Visibility = () => {
    const [position, setPosition] = useState({x: 0, y: 0});

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        setPosition({
            x: e.clientX,
            y: e.clientY,
        });
    };

    useEffect(() => {
        const handleMouseMove = (e: MouseEvent) => {
            setPosition({
                x: e.clientX,
                y: e.clientY,
            });
        };
        document.addEventListener('mousemove', handleMouseMove);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    useGSAP(() => {
        gsap.to(".filler-text2", {opacity: 1, y: 0, delay: 0.2, duration: 1});
    }, []);

    const {t} = useTranslation();
    return (
        <>
            <Helmet>
                {/* General SEO */}
                <title>{t("helmet-visibility-title")}</title>
                <meta name="description" content={t("helmet-visibility-description")}/>
                <meta name="keywords" content={t("helmet-visibility-keywords")}/>

                {/* Open Graph for social media sharing */}
                <meta property="og:title" content={t("helmet-visibility-title")}/>
                <meta property="og:description" content={t("helmet-visibility-description")}/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={i18n.language === 'de'
                    ? 'https://www.2xwd.de/sichtbarkeit'
                    : 'https://www.2xwd.com/visibility'}/>
                <meta property="og:image" content={t("helmet-visibility-image-url")}/>

                {/* Twitter Card for social sharing */}
                <meta name="twitter:card" content={t("helmet-visibility-twitter-card")}/>
                <meta name="twitter:title" content={t("helmet-visibility-title")}/>
                <meta name="twitter:description" content={t("helmet-visibility-description")}/>
                <meta name="twitter:image" content={t("helmet-visibility-image-url")}/>

                {/* Additional SEO and Meta Tags */}
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href={i18n.language === 'de'
                    ? 'https://www.2xwd.de/sichtbarkeit'
                    : 'https://www.2xwd.com/visibility'}/>

                {/* hreflang tags for alternate language versions */}
                <link rel="alternate" href="https://www.2xwd.de/sichtbarkeit" hrefLang="de"/>
                <link rel="alternate" href="https://www.2xwd.com/visibility" hrefLang="en"/>
            </Helmet>
            `

            <div>
                <div
                    className="relative h-screen bg-white text-black overflow-hidden"
                    onMouseMove={handleMouseMove}
                    style={{height: '100vh', overflow: 'hidden'}}
                >
                    <div
                        className="absolute inset-0 bg-black"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'black',
                            WebkitMask: `radial-gradient(circle 2500px at ${position.x}px ${position.y}px, transparent 20%, black 25%)`,
                            mask: `radial-gradient(circle 2500px at ${position.x}px ${position.y}px, transparent 20%, black 25%)`,
                            transition: 'mask-position 0.1s, -webkit-mask-position 0.1s',
                        }}
                    ></div>
                    <div
                        className="relative z-10 flex flex-col justify-center items-center h-full text-center">
                        <h1 className="text-5xl md:text-7xl font-bold pb-28 leading-normal">{t("visibility-hero-text-1")}
                            <span
                                className="text-green_brand"> {t("visibility-hero-text-2")}</span></h1>
                        <p className="text-3xl md:text-4xl pb-16 mx-10">{t("visibility-hero-text-3")}</p>
                        <p className="text-3xl md:text-4xl pb-16 mx-10">{t("visibility-hero-text-4")}</p>
                        <p className="text-3xl md:text-4xl pb-16 mx-10">{t("visibility-hero-text-5")}</p>
                    </div>
                </div>
                <div className="gradient-section h-48"></div>
                <div
                    className="bg-white flex flex-col items-center justify-center h-[20rem] w-full text-neutral-400 text-2xl py-56 px-6 md:px-10 lg:px-14"
                >
                    <div className="max-w-[1600px]">
                        <h2 className="filler-text2 opacity-0 text-green_brand text-center pb-16 leading-normal">{t("visibility-title")}</h2>
                        <p className="filler-text2 opacity-0 text-neutral-400 pb-48 md:pb-16">{t("visibility-title-text")}</p>
                    </div>
                </div>
                <VisibilityScrollSection/>
                <WhatSetsUsApart/>
            </div>
        </>
    );
};

export default Visibility;
