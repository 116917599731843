import {IoMdCheckmark} from "react-icons/io";
import {useTranslation} from "react-i18next";
import ContactForm from "../components/ContactForm.tsx";
import {Helmet} from "react-helmet-async";
import i18n from "i18next";

const Contact = () => {
    const {t} = useTranslation();
    return (
        <>
            <Helmet>
                {/* General SEO */}
                <title>{t("helmet-contact-page-title")}</title>
                <meta name="description" content={t("helmet-contact-page-description")}/>
                <meta name="keywords" content={t("helmet-contact-page-keywords")}/>

                {/* Open Graph (og) for social media */}
                <meta property="og:title" content={t("helmet-contact-page-title")}/>
                <meta property="og:description" content={t("helmet-contact-page-description")}/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={t("helmet-contact-content-url")}/>
                <meta property="og:image" content={t("helmet-contact-content-url-image")}/>

                {/* Twitter Card for Twitter sharing */}
                <meta name="twitter:card" content={t("helmet-contact-twitter-card")}/>
                <meta name="twitter:title" content={t("helmet-contact-page-title")}/>
                <meta name="twitter:description" content={t("helmet-contact-page-description")}/>
                <meta name="twitter:image" content={t("helmet-contact-content-url-image")}/>

                {/* Additional SEO and Meta Tags */}
                <meta name="robots" content="index, follow"/>
                <link rel="canonical"
                      href={i18n.language === 'de'
                          ? 'https://www.2xwd.de/kontakt'
                          : 'https://www.2xwd.com/contact'}/>

                {/* hreflang tags for alternate language versions */}
                <link rel="alternate" href="https://www.2xwd.de/kontakt" hrefLang="de"/>
                <link rel="alternate" href="https://www.2xwd.com/contact" hrefLang="en"/>
            </Helmet>


            <section className="h-auto common-padding overflow-hidden bg-gray-50">
                <div className="w-full text-center pt-24 pb-8 md:pt-12 md:py-12 lg:pt-36">
                    <h1 className="text-green_brand text-3xl md:text-4xl font-semibold pb-4">{t("contact-us-title")}</h1>
                </div>
                <div className="flex flex-col py-8 md:py-16 lg:py-24 px-4 lg:px-8 max-w-screen-2xl mx-auto">
                    {/* Info Section */}
                    <div className="flex-1 bg-opacity-75 lg:mr-8 mb-8 lg:mb-0 order-2 lg:order-1">
                        <h2 className="text-2xl md:text-3xl text-green_brand font-semibold mb-4 text-center md:text-left leading-tight md:leading-tight">
                            {t("contact-us-header")}
                        </h2>
                        <p className="text-neutral-500 text-base md:text-lg text-left leading-relaxed">
                            {t("contact-us-text")}
                        </p>
                        <div className="flex justify-start pt-4 items-left text-neutral-500 gap-x-3">
                            <IoMdCheckmark className="text-green_brand text-2xl md:text-3xl"/>
                            <span className="text-base md:text-lg">{t("contact-us-bullet-point-1")}</span>
                        </div>
                        <div className="flex justify-start pt-4 items-left text-neutral-500 gap-x-3">
                            <IoMdCheckmark className="text-green_brand text-2xl md:text-3xl"/>
                            <span className="text-base md:text-lg">{t("contact-us-bullet-point-2")}</span>
                        </div>
                        <div className="flex justify-start pt-4 items-center text-neutral-500 gap-x-3">
                            <IoMdCheckmark className="text-green_brand text-2xl md:text-3xl"/>
                            <span className="text-base md:text-lg">{t("contact-us-bullet-point-3")}</span>
                        </div>
                        <div
                            className="flex justify-start pt-4 items-center text-neutral-500 gap-x-3 mb-10">
                            <IoMdCheckmark className="text-green_brand text-2xl md:text-3xl"/>
                            <span className="text-base md:text-lg">{t("contact-us-bullet-point-4")}</span>
                        </div>
                    </div>

                    {/* ContactForm Section */}
                    <div className="flex-1 order-2 lg:order-2">
                        <ContactForm/>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Contact;
