import axios from 'axios';

export const submitUrlsToBing = async () => {
    try {
        const response = await axios.get('/api/bing/submit');
        if (import.meta.env.VITE_LOGGING_ENABLED === 'true') {
            console.log('URLs submitted:', response.data);
        }

    } catch (error) {
        console.error('Error submitting URLs:', error);
    }
};
