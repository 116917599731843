import DIY3Cards from "../../components/DIY3Cards";
import {useGSAP} from "@gsap/react";
import {animateFromWithGsap, animateWithGsap} from "../../utils/animations";
import {useTranslation} from "react-i18next";
import StarterCard from "../../components/Cards/StarterCard";
import AddOnsStarter from "../../components/AddOnsStarter.tsx";
import WhatSetsUsApart from "../../components/WhatSetsUsApart.tsx";
import BasicFAQ from "../../components/BasicFAQ.tsx";
import {Helmet} from "react-helmet-async";
import i18n from "i18next";

const StarterBuild = () => {
    const {t} = useTranslation();
    useGSAP(() => {
        animateWithGsap(".card-plan-text1", {opacity: 1, y: -20, delay: 0.5, stagger: 0.5, duration: 1});
        animateWithGsap(".filler-text1", {opacity: 1, y: 0, delay: 0.5, duration: 1});
        animateFromWithGsap('.card-plan-card1', {x: 0, opacity: 1, delay: 0.5, duration: 1});
    }, []);

    return (
        <>
            <Helmet>
                {/* General SEO */}
                <title>{t("helmet-starter-build-title")}</title>
                <meta name="description" content={t("helmet-starter-build-description")}/>
                <meta name="keywords" content={t("helmet-starter-build-keywords")}/>

                {/* Open Graph for social media sharing */}
                <meta property="og:title" content={t("helmet-starter-build-title")}/>
                <meta property="og:description" content={t("helmet-starter-build-description")}/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={i18n.language === 'de'
                    ? 'https://www.2xwd.de/preis/starter'
                    : 'https://www.2xwd.com/price/starter'}/>
                <meta property="og:image" content={t("helmet-starter-build-image-url")}/>

                {/* Twitter Card for social sharing */}
                <meta name="twitter:card" content={t("helmet-starter-build-twitter-card")}/>
                <meta name="twitter:title" content={t("helmet-starter-build-title")}/>
                <meta name="twitter:description" content={t("helmet-starter-build-description")}/>
                <meta name="twitter:image" content={t("helmet-starter-build-image-url")}/>

                {/* Additional SEO and Meta Tags */}
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href={i18n.language === 'de'
                    ? 'https://www.2xwd.de/preis/starter'
                    : 'https://www.2xwd.com/price/starter'}/>

                {/* hreflang tags for alternate language versions */}
                <link rel="alternate" href="https://www.2xwd.de/preis/starter" hrefLang="de"/>
                <link rel="alternate" href="https://www.2xwd.com/price/starter" hrefLang="en"/>
            </Helmet>


            <div className="font-poppins w-[100%] bg-white">
                <section className="w-full pb-24 pt-56 pricing-bg px-4">
                    <div className="flex flex-col mx-auto text-center">
                        <h1 className="text-2xl md:text-5xl xl:text-6xl font-bold mb-12 break-words">
                            {t("starter-page-title")}
                        </h1>
                        <p className="text-md md:text-lg xl:text-xl md:mx-10 m-auto xl:mx-20">
                            {t("starter-page-title-text")}
                        </p>
                    </div>
                </section>
                <DIY3Cards/>
                <section
                    className="w-full h-full flex flex-col lg:flex-row items-center justify-center pb-20 pt-24 px-6 md:px-12 lg:px-20 xl:px-32">
                    <div
                        className="card-plan-text1 opacity-0 max-w-7xl w-full flex flex-col lg:flex-row items-center justify-between mx-auto">
                        <div className="lg:w-1/2 mb-8 lg:mb-0 text-center lg:text-left">
                            <h1 className="text-4xl text-green_brand font-bold mb-10">{t("starter-page-text-title")}</h1>
                            <p className="text-lg text-neutral-400 mb-4 md:mb-6 text-left md:w-full lg:w-[26rem] xl:w-[36rem]">{t("starter-page-text-paragraph-1")}</p>
                            <p className="text-lg text-neutral-400 mb-4 md:mb-6 text-left md:w-full lg:w-[26rem] xl:w-[36rem]">{t("starter-page-text-paragraph-2")}</p>
                            <p className="text-lg text-neutral-400 mb-10 lg:mb-6 text-left md:w-full lg:w-[26rem] xl:w-[36rem]">{t("starter-page-text-paragraph-3")}</p>
                            <ul className="text-lg font-semibold text-green_brand"> {t("starter-page-ul-text")}
                                <li className="text-lg text-neutral-400 text-left font-normal my-4 md:ml-4"><span
                                    className="font-semibold text-neutral-500">{t("starter-page-li-text-bold-1")}</span>{t("starter-page-li-text-1")}
                                </li>
                                <li className="text-lg text-neutral-400 text-left font-normal my-4 md:ml-4"><span
                                    className="font-semibold text-neutral-500">{t("starter-page-li-text-bold-2")}</span>{t("starter-page-li-text-2")}
                                </li>
                                <li className="text-lg text-neutral-400 text-left font-normal my-4 md:ml-4"><span
                                    className="font-semibold text-neutral-500">{t("starter-page-li-text-bold-3")}</span>{t("starter-page-li-text-3")}
                                </li>
                                <li className="text-lg text-neutral-400 text-left font-normal my-4 md:ml-4"><span
                                    className="font-semibold text-neutral-500">{t("starter-page-li-text-bold-4")}</span>{t("starter-page-li-text-4")}
                                </li>
                                <li className="text-lg text-neutral-400 text-left font-normal my-4 md:ml-4"><span
                                    className="font-semibold text-neutral-500">{t("starter-page-li-text-bold-5")}</span>{t("starter-page-li-text-5")}
                                </li>
                                <li className="text-lg text-neutral-400 text-left font-normal my-4 md:ml-4"><span
                                    className="font-semibold text-neutral-500">{t("starter-page-li-text-bold-6")}</span>{t("starter-page-li-text-6")}
                                </li>
                                <li className="text-lg text-neutral-400 text-left font-normal my-4 md:ml-4"><span
                                    className="font-semibold text-neutral-500">{t("starter-page-li-text-bold-7")}</span>{t("starter-page-li-text-7")}
                                </li>
                            </ul>
                        </div>
                        <div
                            className="card-plan-card1 opacity-0 lg:w-1/2 flex justify-center lg:justify-end lg:sticky lg:top-24">
                            <StarterCard/>
                        </div>
                    </div>
                </section>
                <div
                    className="filler-text1 opacity-0 flex items-center justify-center h-[20rem] w-full bg-green_light_brand text-white_smoke text-2xl px-6 md:px-10 lg:px-14">
                    {t("starter-card-filler-text")}
                </div>
                <AddOnsStarter/>
                <WhatSetsUsApart/>
                <BasicFAQ/>
            </div>
        </>
    );
};

export default StarterBuild;
