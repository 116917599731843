import {useEffect, useMemo, useState} from 'react';
import {Cloudinary, CloudinaryImage} from '@cloudinary/url-gen';
import {AdvancedImage} from '@cloudinary/react';
import {Link, NavLink} from 'react-router-dom';
import {IoIosArrowDown} from 'react-icons/io';
import LanguageSelector from './LanguageSelector.tsx';
import {HiOutlineMenuAlt3} from 'react-icons/hi';
import {AiOutlineClose} from 'react-icons/ai';
import {useAutoAnimate} from '@formkit/auto-animate/react';
import {useTranslation} from 'react-i18next';

type NavItem = {
    label: string;
    link: string;
    children?: NavItem[];
    iconImage?: CloudinaryImage;
};

const cld = new Cloudinary({
    cloud: {
        cloudName: import.meta.env.VITE_CLOUDINARY_CLOUD_NAME,
    },
});
const basicImage = cld.image(import.meta.env.VITE_CLOUDINARY_PRICING_BASIC_PUBLIC_ID);
const standardImage = cld.image(import.meta.env.VITE_CLOUDINARY_PRICING_STANDARD_PUBLIC_ID);
const professionalImage = cld.image(import.meta.env.VITE_CLOUDINARY_PRICING_PROFESSIONAL_PUBLIC_ID);
const enterpriseImage = cld.image(import.meta.env.VITE_CLOUDINARY_PRICING_ENTERPRISE_PUBLIC_ID);
const seoImage = cld.image(import.meta.env.VITE_CLOUDINARY_NAVBAR_SEO);

const Navbar = () => {
    const {t} = useTranslation();
    const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [animationParent] = useAutoAnimate();

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            if (currentScrollY > lastScrollY) {
                setIsNavbarVisible(false); // Hide navbar on scroll down
            } else {
                setIsNavbarVisible(true); // Show navbar on scroll up
            }

            setLastScrollY(currentScrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

    const navItems: NavItem[] = useMemo(
        () => [
            {
                label: t('nav-builds'),
                link: t('route-builds'),
                children: [
                    {
                        label: t('nav-builds-starter'),
                        link: t('route-builds-starter'),
                        iconImage: basicImage,
                    },
                    {
                        label: t('nav-builds-standard'),
                        link: t('route-builds-standard'),
                        iconImage: standardImage,
                    },
                    {
                        label: t('nav-builds-professional'),
                        link: t('route-builds-professional'),
                        iconImage: professionalImage,
                    },
                    {
                        label: t('nav-builds-enterprise'),
                        link: t('route-builds-enterprise'),
                        iconImage: enterpriseImage,
                    },
                ],
            },
            {
                label: t('nav-visibility'),
                link: t('route-visibility'),
                children: [
                    {
                        label: t('nav-visibility-seo'),
                        link: t('route-visibility-seo'),
                        iconImage: seoImage,
                    },
                ],
            },
            {
                label: t('nav-support'),
                link: t('route-support'),
            },
            {
                label: t('nav-about'),
                link: t('route-about'),
            },
            {
                label: t('nav-contact'),
                link: t('route-contact'),
            },
        ],
        [t]
    );

    function openSideMenu() {
        setIsSideMenuOpen(true);
    }

    function closeSideMenu() {
        setIsSideMenuOpen(false);
    }

    const logoImage = cld.image(import.meta.env.VITE_CLOUDINARY_LOGO);
    const logoTextImage = cld.image(import.meta.env.VITE_CLOUDINARY_LOGO_TEXT);

    return (
        <header
            className={`mx-auto flex w-full max-w-full justify-center px-4 py-5 text-sm glass-dark-nav fixed top-0 left-0 right-0 z-50 poppins-medium transition-transform duration-300 ${
                isNavbarVisible ? 'translate-y-0' : '-translate-y-full'
            }`}
        >
            <div className="flex w-full max-w-[1600px] justify-between">
                <section ref={animationParent} className="flex items-center gap-10">
                    <Link to="/" className="flex items-center">
                        <AdvancedImage cldImg={logoImage} alt="logo" className="w-12 h-12"/>
                        <AdvancedImage cldImg={logoTextImage} alt="logo text" className="w-20 h-12"/>
                    </Link>
                    {isSideMenuOpen && <MobileNav navItems={navItems} closeSideMenu={closeSideMenu}/>}
                </section>
                <section
                    className="hidden lg:flex justify-center items-center gap-10 w-full text-neutral-400 transition-all hover:text-green_brand">
                    <div className="flex items-center gap-4 transition-all hover:text-green_brand">
                        {navItems.map((d, i) => (
                            <div key={i} className="relative group px-2 py-3 transition-all">
                                <Link
                                    to={d.link}
                                    className="flex cursor-pointer items-center gap-2 text-neutral-400 group-hover:text-green_brand"
                                >
                                    <span className="text-white hover:text-green_brand">{d.label}</span>
                                    {d.children && (
                                        <IoIosArrowDown className="text-white transition-all group-hover:rotate-180"/>
                                    )}
                                </Link>
                                {d.children && (
                                    <div
                                        className="absolute right-0 top-10 hidden w-auto flex-col gap-1 rounded-lg bg-white py-3 shadow-md transition-all group-hover:flex">
                                        {d.children.map((ch, j) => (
                                            <NavLink
                                                key={j}
                                                to={ch.link}
                                                className="flex cursor-pointer items-center py-1 pl-6 pr-8 text-neutral-400 hover:text-black"
                                            >
                                                {ch.iconImage && (
                                                    <AdvancedImage cldImg={ch.iconImage} alt={ch.label}
                                                                   className="w-8 h-8"/>
                                                )}
                                                <span className="whitespace-nowrap pl-3 pr-5">{ch.label}</span>
                                            </NavLink>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                        <LanguageSelector/>
                    </div>
                </section>
            </div>
            <HiOutlineMenuAlt3 onClick={openSideMenu}
                               className="cursor-pointer text-4xl mr-4 md:mr-8 text-green_brand lg:hidden"/>
        </header>
    );
};


function MobileNav({closeSideMenu, navItems}: { closeSideMenu: () => void; navItems: NavItem[] }) {
    return (
        <div className="fixed left-0 top-0 flex h-full min-h-screen w-full justify-end bg-black/60 lg:hidden">
            <div className="h-full w-[65%] bg-white px-4 py-4">
                <section className="flex justify-end">
                    <AiOutlineClose onClick={closeSideMenu} className="cursor-pointer text-4xl text-green_brand"/>
                </section>
                <section
                    className="flex justify-center items-center gap-10 w-full text-neutral-400 transition-all hover:text-green_brand">
                    <div className="flex flex-col text-base gap-2 transition-all hover:text-green_brand">
                        {navItems.map((d, i) => (
                            <SingleNavItem key={i} {...d} closeSideMenu={closeSideMenu}/>
                        ))}
                        <div className="-ml-[20px]">
                            <LanguageSelector closeSideMenu={closeSideMenu}/>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

function SingleNavItem({label, link, children, closeSideMenu}: NavItem & { closeSideMenu: () => void }) {
    const [isItemOpen, setIsItemOpen] = useState(false);
    const [animationParent] = useAutoAnimate();

    function toggleItem() {
        setIsItemOpen(!isItemOpen);
    }

    function handleLinkClick() {
        setIsItemOpen(false);
        closeSideMenu();
    }

    return (
        <div ref={animationParent} className="relative px-2 py-3 transition-all">
            <p className="flex cursor-pointer items-center gap-2 text-neutral-400 group-hover:text-green_brand"
               onClick={toggleItem}>
                <Link to={link} onClick={handleLinkClick}>
                    <span className="hover:text-green_brand">{label}</span>
                </Link>
                {children && <IoIosArrowDown className={`text-xs transition-all ${isItemOpen ? 'rotate-180' : ''}`}/>}
            </p>
            {isItemOpen && children && (
                <div className="w-auto flex-col gap-1 rounded-lg bg-white py-3 transition-all flex">
                    {children.map((ch, i) => (
                        <Link
                            key={i}
                            to={ch.link}
                            className="flex cursor-pointer items-center py-1 pl-6 pr-8 text-neutral-400 hover:text-black"
                            onClick={handleLinkClick}
                        >
                            {ch.iconImage && <AdvancedImage cldImg={ch.iconImage} alt={ch.label} className="w-8 h-8"/>}
                            <span className="whitespace-nowrap pl-3 pr-5">{ch.label}</span>
                        </Link>
                    ))}
                </div>
            )}
        </div>
    );
}

export default Navbar;
