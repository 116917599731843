import {Swiper, SwiperSlide} from "swiper/react";
import {EffectFade, Navigation, Pagination} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import {Cloudinary} from "@cloudinary/url-gen";

import "./OurProjects.css";
import {BackgroundGradientAnimation} from "../ui/BackgroundGradientAnimation.tsx";
import {useGSAP} from "@gsap/react";
import gsap from "gsap";
import {animateWithGsap} from "../utils/animations.tsx";
import {useTranslation} from "react-i18next";

const cld = new Cloudinary({
    cloud: {
        cloudName: import.meta.env.VITE_CLOUDINARY_CLOUD_NAME,
    },
});

const elevationLLCImage = cld.image(import.meta.env.VITE_CLOUDINARY_OUR_PROJECTS_ELEVATION_LLC_PUBLIC_ID).toURL();
// const secondImage = cld.image(import.meta.env.VITE_CLOUDINARY_CONTACT_INDIVIDUAL_PHOTO_PUBLIC_ID).toURL();
// const thirdImage = cld.image(import.meta.env.VITE_CLOUDINARY_CONTACT_COMPANY_PHOTO_PUBLIC_ID).toURL();

const OurProjects = () => {

    const {t} = useTranslation();

    const projects = [
        {
            id: 1,
            image: elevationLLCImage,
            title: t("projects.elevation.title"),
            build: t("projects.elevation.build"),
            description:
                t("projects.elevation.description"),
            url: "https://www.elevationcontractingllc.com/",
        },
        // {
        //     id: 2,
        //     image: secondImage,
        //     title: "Project Beta",
        //     build: "Build: 2022",
        //     description: "Project Beta is a modern construction example with advanced features.",
        //     url: "https://project-beta.example.com",
        // },
        // {
        //     id: 3,
        //     image: thirdImage,
        //     title: "Project Gamma",
        //     build: "Build: 2021",
        //     description: "An exemplary project demonstrating sustainable practices and creativity.",
        //     url: "https://project-gamma.example.com",
        // },
    ];

    useGSAP(() => {
        gsap.to("#title-our-projects", {opacity: 1, y: 0, delay: 0.2})
        animateWithGsap('.stagger-our-projects', {y: -10, opacity: 1, delay: 0.3})
    }, [])

    return (
        <BackgroundGradientAnimation size="100vw">
            <section className="py-24 font-poppins common-padding-no-bottom-padding z-10">
                <div className="max-w-[1600px] mx-auto">
                    <h1 id="title-our-projects"
                        className="text-center text-gray lg:text-6xl md:text-5xl text-3xl mb-0 pb-4 lg:pb-8 xl:pb-16 uppercase font-medium opacity-0">
                        {t("projects-heading")}
                    </h1>
                    <div className="flex justify-center items-center">
                        <Swiper
                            modules={[Navigation, EffectFade, Pagination]}
                            effect="fade"
                            navigation
                            pagination={{
                                clickable: true, // Makes the dots clickable
                            }}
                            className="w-full h-full"
                            fadeEffect={{
                                crossFade: true,
                            }}
                        >
                            {projects.map((project) => (
                                <SwiperSlide key={project.id} className="swiper-slide">
                                    <div
                                        className="flex flex-col lg:flex-row items-center gap-8 py-12 stagger-our-projects opacity-0">
                                        <div className="w-full md:w-1/2 glass ml-4">
                                            <img
                                                src={project.image}
                                                alt={project.title}
                                                className="rounded-lg shadow-lg w-full object-cover"
                                            />
                                        </div>
                                        <div className="w-full md:w-1/2 text-center md:text-left">
                                            <h3 className="text-green_brand poppins-semibold mb-4">{project.title}</h3>
                                            <p className="text-2xl text-gray-600 text-left mb-2">{project.build}</p>
                                            <p className="text-neutral-400 text-md xl:text-xl leading-loose xl:leading-[3.25rem] text-left mb-4">{project.description}</p>
                                            <a
                                                href={project.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="btn inline-block rounded transition"
                                            >
                                                {t("projects-button")}
                                            </a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </section>
        </BackgroundGradientAnimation>
    );
};

export default OurProjects;
