import {Cloudinary} from "@cloudinary/url-gen";
import {useGSAP} from "@gsap/react";
import gsap from "gsap";
import {
    animateWithGsapSeoText1,
    animateWithGsapSeoText2,
    animateWithGsapSeoText3,
    animateWithGsapSeoText4
} from "../utils/animations.tsx";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

const SeoSection = () => {
    useGSAP(() => {
        gsap.to(".title", {opacity: 1, y: 0, delay: 1})
        animateWithGsapSeoText1('.seo-text-animation1', {y: 0, opacity: 1, scale: 1, duration: 1})
        animateWithGsapSeoText2('.seo-text-animation2', {y: 0, opacity: 1, scale: 1, duration: 1})
        animateWithGsapSeoText3('.seo-text-animation3', {y: 0, opacity: 1, scale: 1, duration: 1})
        animateWithGsapSeoText4('.seo-text-animation4', {y: 0, opacity: 1, scale: 1, duration: 1})
    }, [])

    useEffect(() => {
        const smoothScrollLinks = document.querySelectorAll<HTMLAnchorElement>(".smooth-scroll");

        smoothScrollLinks.forEach(link => {
            link.addEventListener("click", (event: MouseEvent) => {
                event.preventDefault();
                const targetId = link.getAttribute("href")!.substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                    targetElement.scrollIntoView({
                        behavior: "smooth"
                    });
                }
            });
        });

        return () => {
            smoothScrollLinks.forEach(link => {
                link.removeEventListener("click", (event: MouseEvent) => {
                    event.preventDefault();
                });
            });
        };
    }, []);

    const {t} = useTranslation();

    const cld = new Cloudinary({
        cloud: {
            cloudName: import.meta.env.VITE_CLOUDINARY_CLOUD_NAME,
        }
    });

    const seoTextVideo = cld.video(import.meta.env.VITE_CLOUDINARY_SEO_TEXT_GRAPHIC_PUBLIC_ID).format('mp4').quality('auto');
    const seoToolsVideo = cld.video(import.meta.env.VITE_CLOUDINARY_SEO_TOOLS_GRAPHIC_PUBLIC_ID).format('mp4').quality('auto');
    const siteMapVideo = cld.video(import.meta.env.VITE_CLOUDINARY_SEO_SITE_MAP_GRAPHIC_PUBLIC_ID).format('mp4').quality('auto');
    const loadingSpeedVideo = cld.video(import.meta.env.VITE_CLOUDINARY_SEO_LOADING_SPEED_GRAPHIC_PUBLIC_ID).format('mp4').quality('auto');

    return (
        <section
            className="bg-white w-full overflow-hidden font-poppins flex flex-col items-center justify-center common-padding-no-bottom-padding">
            <div id="seo" className="flex items-center justify-center w-full">
                <h1 className="title section-heading opacity-0 text-neutral-400">{t("seo-section-title")}</h1>
            </div>
            <div className="container mx-auto px-4 py-8">
                <div
                    className="seo-text-animation1 opacity-0 scale-[0.3] translate-y-[200px] flex flex-col md:flex-row mb-8 items-start">
                    <div className="md:w-1/2 p-4">
                        <video
                            src={seoTextVideo.toURL()}
                            className="h-[350px] w-[600px]"
                            autoPlay={true}
                            muted={true}
                            loop={true}
                        />
                    </div>
                    <div className="md:w-1/2 p-4 flex items-start text-neutral-400">
                        <div className="flex flex-col">
                            <h4 className="text-green_brand font-semibold pb-6">{t("seo-section-image-1-title")}</h4>
                            <p className="text-lg">{t("seo-section-image-1-text")}</p>
                        </div>
                    </div>
                </div>
                <div
                    className="seo-text-animation2 opacity-0 scale-[0.3] translate-y-[200px] flex flex-col md:flex-row-reverse mb-8 items-start">
                    <div className="md:w-1/2 p-4">
                        <video
                            src={seoToolsVideo.toURL()}
                            className="h-[350px] w-[600px]"
                            autoPlay={true}
                            muted={true}
                            loop={true}
                        />
                    </div>
                    <div className="md:w-1/2 p-4 flex items-start text-neutral-400">
                        <div className="flex flex-col">
                            <h4 className="text-green_brand font-semibold pb-6 break-words">{t("seo-section-image-2-title")}</h4>
                            <p className="text-lg">{t("seo-section-image-2-text")}</p>
                        </div>
                    </div>
                </div>
                <div
                    className="seo-text-animation3 opacity-0 scale-[0.3] translate-y-[200px] flex flex-col md:flex-row mb-8 items-start">
                    <div className="md:w-1/2 p-4">
                        <video
                            src={siteMapVideo.toURL()}
                            className="h-[350px] w-[600px]"
                            autoPlay={true}
                            muted={true}
                            loop={true}
                        />
                    </div>
                    <div className="md:w-1/2 p-4 flex items-start text-neutral-400">
                        <div className="flex flex-col">
                            <h4 className="text-green_brand font-semibold pb-6">{t("seo-section-image-3-title")}</h4>
                            <p className="text-lg">{t("seo-section-image-3-text")}</p>
                        </div>
                    </div>
                </div>
                <div
                    className="seo-text-animation4 opacity-0 scale-[0.3] translate-y-[200px] flex flex-col md:flex-row-reverse mb-8 items-start">
                    <div className="md:w-1/2 p-4">
                        <video
                            src={loadingSpeedVideo.toURL()}
                            className="h-[350px] w-[600px]"
                            autoPlay={true}
                            muted={true}
                            loop={true}
                        />
                    </div>
                    <div className="md:w-1/2 p-4 flex items-start text-neutral-400">
                        <div className="flex flex-col">
                            <h4 className="text-green_brand font-semibold pb-6">{t("seo-section-image-4-title")}</h4>
                            <p className="text-lg">{t("seo-section-image-4-text")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SeoSection;
