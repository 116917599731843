import {useState} from "react";
import {FiChevronDown} from "react-icons/fi";
import {motion} from "framer-motion";
import useMeasure from "react-use-measure";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";

const BasicFAQ = () => {
    const {t} = useTranslation();

    return (
        <>
            <Helmet>
                {/*<title>{t("helmet-basic-faq-title")}</title>*/}
                <meta name="description"
                      content={t("helmet-basic-faq-description")}/>
                <meta name="keywords"
                      content={t("helmet-basic-faq-keywords")}/>
            </Helmet>

            <div className="px-4 py-36 font-poppins bg-white">
                <div className="mx-auto max-w-3xl">
                    <h3 className="mb-4 text-neutral-400 text-center text-3xl font-semibold">
                        {t("BasicFAQ-title")}
                    </h3>
                    <Question title={t("BasicFAQ-question-1")} defaultOpen>
                        <div className="text-sm mx-4">
                            {t("BasicFAQ-answer-1")}
                        </div>
                    </Question>
                    <Question title={t("BasicFAQ-question-2")}>
                        <div className="text-sm mx-4">
                            {t("BasicFAQ-answer-2")}
                        </div>
                    </Question>
                    <Question title={t("BasicFAQ-question-3")}>
                        <div className="text-sm mx-4">
                            {t("BasicFAQ-answer-3")}
                        </div>
                    </Question>
                    <Question title={t("BasicFAQ-question-4")}>
                        <div className="text-sm mx-4">
                            {t("BasicFAQ-answer-4")}
                        </div>
                    </Question>
                    <Question title={t("BasicFAQ-question-5")}>
                        <div className="text-sm mx-4">
                            {t("BasicFAQ-answer-5")}
                        </div>
                    </Question>
                    <Question title={t("BasicFAQ-question-6")}>
                        <div className="text-sm mx-4">
                            {t("BasicFAQ-answer-6")}
                        </div>
                    </Question>
                    <Question title={t("BasicFAQ-question-7")}>
                        <div className="text-sm mx-4">
                            {t("BasicFAQ-answer-7")}
                        </div>
                    </Question>
                    <Question title={t("BasicFAQ-question-8")}>
                        <div className="text-sm mx-4">
                            {t("BasicFAQ-answer-8")}
                        </div>
                    </Question>
                    <Question title={t("BasicFAQ-question-9")}>
                        <div className="text-sm mx-4">
                            {t("BasicFAQ-answer-9")}
                        </div>
                    </Question>
                    <Question title={t("BasicFAQ-question-10")}>
                        <div className="text-sm mx-4">
                            {t("BasicFAQ-answer-10")}
                        </div>
                    </Question>
                    <Question title={t("BasicFAQ-question-11")}>
                        <div className="text-sm mx-4">
                            {t("BasicFAQ-answer-11")}
                        </div>
                    </Question>
                    <Question title={t("BasicFAQ-question-12")}>
                        <div className="text-sm mx-4">
                            {t("BasicFAQ-answer-12")}
                        </div>
                    </Question>
                    <Question title={t("BasicFAQ-question-13")}>
                        <div className="text-sm mx-4">
                            {t("BasicFAQ-answer-13")}
                        </div>
                    </Question>

                </div>
            </div>
        </>
    );
};

const Question = ({
                      title,
                      children,
                      defaultOpen = false,
                  }: {
    title: string;
    children: JSX.Element;
    defaultOpen?: boolean;
}) => {
    const [ref, {height}] = useMeasure();
    const [open, setOpen] = useState(defaultOpen);

    return (
        <motion.div
            animate={open ? "open" : "closed"}
            className="border-b-[1px] border-b-slate-300"
        >
            <button
                onClick={() => setOpen((pv) => !pv)}
                className="flex w-full items-center justify-between gap-4 py-6"
            >
                <motion.span
                    variants={{
                        open: {
                            color: "rgb(0, 173, 181)",
                        },
                        closed: {
                            color: "rgba(3, 6, 23, 1)",
                        },
                    }}
                    className="bg-gradient-to-r from-violet-600 to-indigo-600 bg-clip-text text-left text-lg font-medium"
                >
                    {title}
                </motion.span>
                <motion.span
                    variants={{
                        open: {
                            rotate: "180deg",
                            color: "rgb(0, 173, 181)",
                        },
                        closed: {
                            rotate: "0deg",
                            color: "#030617",
                        },
                    }}
                >
                    <FiChevronDown className="text-2xl"/>
                </motion.span>
            </button>
            <motion.div
                initial={false}
                animate={{
                    height: open ? height : "0px",
                    marginBottom: open ? "24px" : "0px",
                }}
                className="overflow-hidden text-slate-600"
            >
                <div ref={ref}>{children}</div>
            </motion.div>
        </motion.div>
    );
};

export default BasicFAQ;