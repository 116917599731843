import {Cloudinary} from "@cloudinary/url-gen";
import {AdvancedImage} from "@cloudinary/react";
import {motion, useMotionValue, useTransform} from "framer-motion";
import {IoMdCheckmark} from "react-icons/io";
import {Link} from "react-router-dom";
import React, {useRef} from "react";
import {useTranslation} from "react-i18next";
import TrackableButton from "../TrackableButton.tsx";

const StarterCard = () => {
    const x = useMotionValue(0);
    const y = useMotionValue(0);
    const rotateX = useTransform(y, [-50, 50], [15, -15]);
    const rotateY = useTransform(x, [-50, 50], [-15, 15]);

    const cardRef = useRef<HTMLDivElement>(null);

    const handleMouseMove = (event: React.MouseEvent) => {
        if (cardRef.current) {
            const rect = cardRef.current.getBoundingClientRect();
            const xPos = event.clientX - rect.left - rect.width / 2;
            const yPos = event.clientY - rect.top - rect.height / 2;
            x.set(xPos / 10);
            y.set(yPos / 10);
        }
    };

    const handleMouseLeave = () => {
        x.set(0);
        y.set(0);
    };

    const {t} = useTranslation();

    const cld = new Cloudinary({
        cloud: {
            cloudName: import.meta.env.VITE_CLOUDINARY_CLOUD_NAME as string,
        },
    });

    const basicImage = cld.image(import.meta.env.VITE_CLOUDINARY_PRICING_BASIC_PUBLIC_ID as string);

    return (
        <div className="flex justify-center items-center">
            <div style={{perspective: 2000}}>
                <motion.div
                    ref={cardRef}
                    style={{x, y, rotateX, rotateY, z: 100}}
                    onMouseMove={(e) => {
                        if (window.innerWidth > 768) handleMouseMove(e);
                    }}
                    onMouseLeave={() => {
                        if (window.innerWidth > 768) handleMouseLeave();
                    }}
                    animate={{
                        x: 0,
                        y: 0,
                        rotateX: 0,
                        rotateY: 0,
                        transition: {type: 'spring', stiffness: 100, damping: 20, mass: 2.5},
                    }}
                    className="card-price w-[300px] md:w-[400px] min-h-[500px] bg-white rounded-[20px] border-gray px-[40px] py-[24px] mb-16 lg:mt-8 relative cursor-pointer"
                >
                    <motion.div style={{x, y, rotateX, rotateY, z: 100}} className="card-price-icon">
                        <div className="card-price-circle"></div>
                        <AdvancedImage cldImg={basicImage} className="card-price-image"/>
                    </motion.div>
                    <motion.div style={{x, y, rotateX, rotateY, z: 100}}>
                        <div className="text-[1.3rem] xl:text-[2rem] text-center text-green_dark_brand font-semibold">
                            Starter
                        </div>
                        <div className="text-[2rem] xl:text-[2.5rem] text-center text-neutral-400 font-semibold">
                            {t("starter-card-price")}
                        </div>
                        <hr className="text-green_darker_brand w-full my-4"/>
                        <div className="flex flex-col items-start text-gray">
                            <div className="flex justify-center items-center gap-x-3">
                                <IoMdCheckmark className="text-green_brand"/> {t("starter-card-text-1")}
                            </div>
                            <div className="flex justify-center items-center gap-x-3">
                                <IoMdCheckmark className="text-green_brand"/> {t("starter-card-text-2")}
                            </div>
                            <div className="flex justify-center items-center gap-x-3">
                                <IoMdCheckmark className="text-green_brand"/> {t("starter-card-text-3")}
                            </div>
                            <div className="flex justify-center items-center gap-x-3">
                                <IoMdCheckmark className="text-green_brand"/> {t("starter-card-text-4")}
                            </div>
                            <div className="flex justify-center items-center gap-x-3">
                                <IoMdCheckmark className="text-green_brand"/> {t("starter-card-text-5")}
                            </div>
                            <div className="flex justify-center items-center gap-x-3">
                                <IoMdCheckmark className="text-green_brand"/> {t("starter-card-text-6")}
                            </div>
                            <div className="flex justify-center items-center gap-x-3">
                                <IoMdCheckmark className="text-green_brand"/> {t("starter-card-text-7")}
                            </div>
                            <div className="flex justify-center items-center gap-x-3">
                                <IoMdCheckmark className="text-green_brand"/> {t("starter-card-text-8")}
                            </div>
                            <div className="flex justify-center items-center gap-x-3">
                                <IoMdCheckmark className="text-green_brand"/> {t("starter-card-text-9")}
                            </div>
                        </div>
                    </motion.div>
                    <div className="flex justify-center items-center text-white pt-4">
                        <Link to={`/${t("route-contact")}`}>
                            <TrackableButton
                                category="CTA"
                                action="Click"
                                label="Starter CTA button"
                                className="btn">{t("starter-card-button")}
                            </TrackableButton>
                        </Link>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default StarterCard;
