import {FlipWords} from "../ui/FlipWords";
import {Cloudinary} from "@cloudinary/url-gen";
import {useTranslation} from "react-i18next";
import {AdvancedVideo} from "@cloudinary/react";

export function SupportChangingText() {
    const {t} = useTranslation();

    const cld = new Cloudinary({
        cloud: {
            cloudName: import.meta.env.VITE_CLOUDINARY_CLOUD_NAME as string,
        },
    });

    const checkListVideo = cld.video(import.meta.env.VITE_CLOUDINARY_SUPPORT_CHECK_LIST_GRAPHIC_PUBLIC_ID).quality('auto');

    const words = [
        t("support-changing-text-word-1"),
        t("support-changing-text-word-2"),
        t("support-changing-text-word-3"),
        t("support-changing-text-word-4"),
        t("support-changing-text-word-5"),
        t("support-changing-text-word-6")
    ];

    return (
        <div className="bg-white pt-10 pb-20 sm:py-40 lg:py-20 xl:py-20 sm:px-10 px-10 w-full flex justify-center">
            <div className="max-w-[1600px] w-full relative flex flex-col md:flex-row md:items-center">
                <div
                    className="text-black dark:text-black leading-relaxed md:leading-relaxed lg:leading-relaxed xl:leading-relaxed text-center md:text-left mb-96 md:mb-0 order-1 md:order-1">

                    {/* Wrapper for H1 and FlipWords */}
                    <div className="inline-elements-wrapper">
                        {/* H1 element */}
                        <h1 className="inline-block align-middle text-3xl md:text-4xl lg:text-4xl xl:text-6xl 2xl:text-6xl font-semibold pb-6">
                            {t("support-changing-text-before")}&nbsp;
                        </h1>

                        {/* FlipWords element */}
                        <FlipWords
                            words={words}
                            className="inline-block align-middle text-3xl md:text-4xl lg:text-4xl xl:text-6xl 2xl:text-6xl leading-relaxed -ml-2 pb-6"
                        />
                    </div>

                    {/* Paragraph content */}
                    <p className="text-xl md:text-2xl lg:text-3xl xl:text-4xl leading-relaxed">
                        {t("support-changing-text-after")} <span className="text-green_brand">
                            {t("support-changing-text-after-green")}
                        </span>
                        {t("support-changing-text-after-green-text")}
                    </p>
                </div>

                {/* Video section */}
                <div
                    className="absolute right-0 -bottom-[60px] md:relative md:order-2 md:ml-4 flex-shrink-1 w-full md:w-auto">
                    <AdvancedVideo className="w-[600px] h-[350px] object-cover" cldVid={checkListVideo} autoPlay muted
                                   loop/>
                </div>
            </div>
        </div>
    );
}
