import {useTranslation} from "react-i18next";
import {useGSAP} from "@gsap/react";
import gsap from "gsap";
import {AiOutlineCheckSquare} from "react-icons/ai";

const ProjectStructure = () => {
    const {t} = useTranslation();
    useGSAP(() => {
        gsap.to("#title4", {opacity: 1, y: 0, delay: 1})
        gsap.to('.stagger7', {y: -10, opacity: 1, delay: 0.5})
        gsap.to('.stagger8', {y: -10, opacity: 1, delay: 1})
        gsap.to(".cta3", {opacity: 1, y: -50, delay: 2})
    }, [])

    return (
        <>
            {/*<Helmet>*/}
            {/*    /!*<title>{t("helmet-home-ProjectStructure-title")}</title>*!/*/}
            {/*    <meta*/}
            {/*        name="description"*/}
            {/*        content={t("helmet-home-ProjectStructure-description")}*/}
            {/*    />*/}
            {/*    <meta*/}
            {/*        name="keywords"*/}
            {/*        content={t("helmet-home-ProjectStructure-keywords")}*/}
            {/*    />*/}
            {/*</Helmet>*/}

            <section className="font-poppins flex flex-col bg-black h-auto text-white items-center w-screen">
                <div className="flex flex-col how-our-projects-work-bg items-center justify-center w-[100%] h-[25rem]">
                    <h1 id="title4"
                        className="flex text-center opacity-0 py-20 text-white_smoke px-3 uppercase">{t("project-structure-title")}</h1>
                </div>
                <div className="flex flex-col text-center items-center w-full common-padding-no-bottom-padding">
                    <h2 className="stagger7 opacity-0 flex flex-col items-center justify-center text-green_brand pb-8">
                        {t("project-structure-header")}
                    </h2>
                    <div className="flex opacity-0 text-3xl -mt-6 stagger8">
                        <p className="flex items-center"><AiOutlineCheckSquare
                            className="mr-1"/>{t("project-structure-subheader")}</p>
                    </div>


                </div>

            </section>
        </>
    )
}
export default ProjectStructure
