import {useTranslation} from 'react-i18next';
import {useGSAP} from '@gsap/react';
import gsap from 'gsap';
import {BackgroundBeams} from '../ui/BackgroundBeams.tsx';
import 'tailwindcss/tailwind.css';
import {TextRevealCard} from '../ui/TextRevealCard.tsx';

const WhatSetsUsApart = () => {
    const {t} = useTranslation();
    useGSAP(() => {
        gsap.to('.title-contact', {opacity: 1, y: 0, delay: 1});
    }, []);

    return (
        <>
            {/*<Helmet>*/}
            {/*    /!*<title>{t("helmet-home-WhatSetsUsApart-title")}</title>*!/*/}
            {/*    <meta*/}
            {/*        name="description"*/}
            {/*        content={t("helmet-home-WhatSetsUsApart-description")}*/}
            {/*    />*/}
            {/*    <meta*/}
            {/*        name="keywords"*/}
            {/*        content={t("helmet-home-WhatSetsUsApart-keywords")}*/}
            {/*    />*/}
            {/*</Helmet>*/}

            <div className="relative flex flex-col items-center min-h-screen bg-white pt-10 pb-32">
                <h1 className="title-contact section-heading pt-20">{t("why-choose-us-title")}</h1>
                <div
                    className="relative w-full max-w-6xl glass p-8 rounded-lg shadow-lg z-10">
                    <h2 className="text-4xl font-semibold mb-4 text-green_brand text-center">{t("why-choose-us-card-header")}</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 justify-items-center">
                        <div className="flex items-center justify-center w-full">
                            <TextRevealCard
                                text={t("why-choose-us-card-1-front-text")}
                                revealText={t("why-choose-us-card-1-back-text")}
                            >
                            </TextRevealCard>
                        </div>
                        <div className="flex items-center justify-center w-full">
                            <TextRevealCard
                                text={t("why-choose-us-card-2-front-text")}
                                revealText={t("why-choose-us-card-2-back-text")}
                            >
                            </TextRevealCard>
                        </div>
                        <div className="flex items-center justify-center w-full">
                            <TextRevealCard
                                text={t("why-choose-us-card-3-front-text")}
                                revealText={t("why-choose-us-card-3-back-text")}
                            >
                            </TextRevealCard>
                        </div>
                        <div className="flex items-center justify-center w-full">
                            <TextRevealCard
                                text={t("why-choose-us-card-4-front-text")}
                                revealText={t("why-choose-us-card-4-back-text")}
                            >
                            </TextRevealCard>
                        </div>
                        <div className="flex items-center justify-center w-full">
                            <TextRevealCard
                                text={t("why-choose-us-card-5-front-text")}
                                revealText={t("why-choose-us-card-5-back-text")}
                            >
                            </TextRevealCard>
                        </div>
                        <div className="flex items-center justify-center w-full">
                            <TextRevealCard
                                text={t("why-choose-us-card-6-front-text")}
                                revealText={t("why-choose-us-card-6-back-text")}
                            >
                            </TextRevealCard>
                        </div>
                    </div>
                </div>
                <BackgroundBeams/>
            </div>
        </>
    );
};

export default WhatSetsUsApart;
