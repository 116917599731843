import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";
import i18n from "i18next";

const Imprint = () => {
    const {t} = useTranslation();
    return (
        <>
            <Helmet>
                {/* General SEO */}
                <title>{t("helmet-imprint-title")}</title>
                <meta name="description" content={t("helmet-imprint-description")}/>
                <meta name="keywords" content={t("helmet-imprint-keywords")}/>

                {/* Open Graph for social media sharing */}
                <meta property="og:title" content={t("helmet-imprint-title")}/>
                <meta property="og:description" content={t("helmet-imprint-description")}/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={t("helmet-imprint-content-url")}/>
                <meta property="og:image" content={t("helmet-imprint-content-url-image")}/>

                {/* Twitter Card for Twitter sharing */}
                <meta name="twitter:card" content={t("helmet-imprint-twitter-card")}/>
                <meta name="twitter:title" content={t("helmet-imprint-title")}/>
                <meta name="twitter:description" content={t("helmet-imprint-description")}/>
                <meta name="twitter:image" content={t("helmet-contact-content-url-image")}/>

                {/* Additional SEO and Meta Tags */}
                <meta name="robots" content="index, follow"/>
                <link rel="canonical"
                      href={i18n.language === 'de'
                          ? 'https://www.2xwd.de/impressum'
                          : 'https://www.2xwd.com/imprint'}/>

                {/* hreflang tags for alternate language versions */}
                <link rel="alternate" href="https://www.2xwd.de/impressum" hrefLang="de"/>
                <link rel="alternate" href="https://www.2xwd.com/imprint" hrefLang="en"/>
            </Helmet>


            <div className="common-padding bg-white flex flex-col items-center text-black w-full">

                <h1 className="py-12 font-semibold">{t("imprint-header")}</h1>
                <div className="items-start w-full">
                    <div className="text-2xl font-semibold">{t("imprint-title-1")}</div>
                    <p>{t("imprint-text-1")}</p>
                    <p><span>{t("imprint-text-1-2-1")}</span>{t("imprint-text-1-2-2")}</p>
                    <div className="text-2xl font-semibold pt-8">{t("imprint-title-2")}</div>
                    <p>{t("imprint-text-2-1")}</p>
                    <p>{t("imprint-text-2-2")}</p>
                    <div className="text-2xl font-semibold pt-8">{t("imprint-title-3")}</div>
                    <p>{t("imprint-text-3-1")}</p>
                    <p>{t("imprint-text-3-2")}</p>
                    <div className="text-2xl font-semibold pt-8">{t("imprint-title-4")}</div>
                    <p>{t("imprint-text-4-1")}</p>
                </div>
            </div>
        </>
    )
}
export default Imprint
