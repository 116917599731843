import gsap from "gsap";
import {ScrollTrigger} from "gsap/all";
import React, {RefObject, useEffect} from "react";

gsap.registerPlugin(ScrollTrigger);

export const animateWithGsap = (
    target: string,
    animationProps: gsap.TweenVars,
    scrollProps?: ScrollTrigger.Vars
) => {
    gsap.to(target, {
        ...animationProps,
        scrollTrigger: {
            trigger: target,
            toggleActions: 'restart reverse restart reverse',
            start: 'top 85%',
            markers: false,
            ...scrollProps,
        }
    });
};

export const animateFromWithGsap = (
    target: string,
    animationProps: gsap.TweenVars,
    scrollProps?: ScrollTrigger.Vars
) => {
    gsap.to(target, {
        ...animationProps,
        scrollTrigger: {
            trigger: target,
            toggleActions: 'restart reverse restart reverse',
            start: 'top 85%',
            markers: false,
            ...scrollProps,
        }
    });
};

export const animateWithGsapText = (
    target: string,
    animationProps: gsap.TweenVars,
    scrollProps?: ScrollTrigger.Vars
) => {
    gsap.to(target, {
        ...animationProps,
        scrollTrigger: {
            trigger: target,
            toggleActions: 'restart reverse restart reverse',
            start: 'top 90%',
            markers: false,
            ...scrollProps,
        }
    });
};

export const animateWithGsapSeoText1 = (
    target: string,
    animationProps: gsap.TweenVars,
    scrollProps?: ScrollTrigger.Vars
) => {
    gsap.to(target, {
        ...animationProps,
        scrollTrigger: {
            trigger: target,
            toggleActions: 'restart reverse restart reverse',
            start: 'top 90%',
            markers: false,
            ...scrollProps,
        }
    });
};

export const animateWithGsapSeoText2 = (
    target: string,
    animationProps: gsap.TweenVars,
    scrollProps?: ScrollTrigger.Vars
) => {
    gsap.to(target, {
        ...animationProps,
        scrollTrigger: {
            trigger: target,
            toggleActions: 'restart reverse restart reverse',
            start: 'top 90%',
            markers: false,
            ...scrollProps,
        }
    });
};

export const animateWithGsapSeoText3 = (
    target: string,
    animationProps: gsap.TweenVars,
    scrollProps?: ScrollTrigger.Vars
) => {
    gsap.to(target, {
        ...animationProps,
        scrollTrigger: {
            trigger: target,
            toggleActions: 'restart reverse restart reverse',
            start: 'top 90%',
            markers: false,
            ...scrollProps,
        }
    });
};

export const animateWithGsapSeoText4 = (
    target: string,
    animationProps: gsap.TweenVars,
    scrollProps?: ScrollTrigger.Vars
) => {
    gsap.to(target, {
        ...animationProps,
        scrollTrigger: {
            trigger: target,
            toggleActions: 'restart reverse restart reverse',
            start: 'top 90%',
            markers: false,
            ...scrollProps,
        }
    });
};

export const animateWithGsapTimeline = (
    timeline: gsap.core.Timeline,
    rotationRef: React.RefObject<{ rotation: { y: number } }>,
    rotationState: number,
    firstTarget: gsap.TweenTarget,
    secondTarget: gsap.TweenTarget,
    animationProps: gsap.TweenVars
) => {
    timeline.to(rotationRef.current!.rotation, {
        y: rotationState,
        duration: 1,
        ease: 'power2.inOut'
    });

    timeline.to(
        firstTarget,
        {
            ...animationProps,
            ease: 'power2.inOut'
        },
        '<'
    );

    timeline.to(
        secondTarget,
        {
            ...animationProps,
            ease: 'power2.inOut'
        },
        '<'
    );
};

export const useGsapChangingCards = (refs: RefObject<HTMLElement>[], colors: string[]) => {
    useEffect(() => {
        const updateColors = () => {
            refs.forEach((ref, index) => {
                if (ref.current) {
                    gsap.to(ref.current, {
                        backgroundColor: colors[(index + Math.floor(window.scrollY / 200)) % colors.length],
                        duration: 1.5,
                    });
                }
            });
        };

        ScrollTrigger.create({
            trigger: '.why-help',
            start: 'top center',
            end: 'bottom center',
            onUpdate: updateColors,
        });

        refs.forEach((ref, index) => {
            if (ref.current) {
                gsap.fromTo(ref.current, {
                    y: 300,
                    opacity: 0,
                    rotate: index % 2 === 0 ? -5 : 5,
                }, {
                    y: 0,
                    opacity: 1,
                    rotate: 0,
                    duration: 0.8,
                    ease: 'power2.out',
                    scrollTrigger: {
                        trigger: ref.current,
                        start: 'top bottom',
                        end: 'top center',
                        scrub: true,
                    },
                });
            }
        });

        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
    }, [refs, colors]);
};
