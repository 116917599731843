type RouteMapping = {
    [key: string]: { en: string, de: string }
};

export const routeMapping: RouteMapping = {
    about: {en: 'about', de: 'ueber-uns'},
    builds: {en: 'pricing', de: 'preis'},
    'builds/starter': {en: 'pricing/starter', de: 'preis/starter'},
    'builds/standard': {en: 'pricing/standard', de: 'preis/standard'},
    'builds/professional': {en: 'pricing/professional', de: 'preis/professional'},
    'builds/enterprise': {en: 'pricing/enterprise', de: 'preis/enterprise'},
    visibility: {en: 'visibility', de: 'sichtbarkeit'},
    'visibility/seo': {en: 'visibility/seo', de: 'sichtbarkeit/seo'},
    support: {en: 'support', de: 'support'},
    contact: {en: 'contact', de: 'kontakt'},
    imprint: {en: 'imprint', de: 'impressum'},
    'terms-conditions': {en: 'terms-conditions', de: 'agb'},
    'privacy-policy': {en: 'privacy-policy', de: 'datenschutz'},
    'thank-you': {en: 'thank-you', de: 'danke'},
};
