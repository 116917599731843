import {useCallback, useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useTranslation} from "react-i18next";
import ReactGA from "react-ga4";
import {submitUrlsToBing} from "../utils/submitUrlToBing.tsx";

type CookieNames =
    'cookieConsent'
    | '__cfruid'
    | '_cfuvid'
    | 'intercom-device-id-avmr5b6h'
    | 'intercom-id-avmr5b6h'
    | 'next-auth.callback-url'
    | 'NEXT_LOCALE'
    | '_ga'
    | '_gid'
    | '_gat';

const CookieConsentBanner: React.FC = () => {
    const {t} = useTranslation();
    const [cookies, setCookie, removeCookie] = useCookies<CookieNames>(['cookieConsent']);
    const [isVisible, setIsVisible] = useState(true);

    const initializeNonEssentialCookies = useCallback(async () => {
        // Initialize non-essential cookies like analytics
        ReactGA.initialize(import.meta.env.VITE_GOOGLE_ANALYTICS_ID as string);
        ReactGA.send("pageview");
        ReactGA.set({anonymize_ip: true});

        // Submit URLs to Bing and wait for completion
        try {
            await submitUrlsToBing();
            if (import.meta.env.VITE_LOGGING_ENABLED === 'true') {
                console.log('URLs submitted successfully');
            }
        } catch (error) {
            console.error('Error submitting URLs:', error);
        }
    }, []);

    const initializeEssentialCookies = useCallback(() => {
        if (import.meta.env.VITE_LOGGING_ENABLED === 'true') {
            console.log('Essential cookies enabled');
        }
        setCookie('NEXT_LOCALE', 'en', {path: '/', expires: new Date('2025-07-24T14:16:53.193Z'), sameSite: 'lax'});
        setCookie('next-auth.callback-url', 'website-2xwd.vercel.app', {path: '/', maxAge: 60 * 60 * 24 * 7}); // Example: 1 week
        // Add logic to initialize essential cookies
    }, [setCookie]);

    const GA_TRACKING_ID = import.meta.env.VITE_GOOGLE_ANALYTICS_ID as string;

    const removeNonEssentialCookies = useCallback(() => {
        // List of non-essential cookies to remove
        const nonEssentialCookies: CookieNames[] = ['intercom-device-id-avmr5b6h', 'intercom-id-avmr5b6h', '_ga', '_gid', '_gat'];
        nonEssentialCookies.forEach(cookie => removeCookie(cookie, {path: '/'}));
        if (typeof window !== 'undefined') {
            (window as any)[`ga-disable-${GA_TRACKING_ID}`] = true;
        }
    }, [removeCookie, GA_TRACKING_ID]);

    useEffect(() => {
        if (import.meta.env.VITE_LOGGING_ENABLED === 'true') {
            console.log('Current cookie consent:', cookies.cookieConsent);
        }
        if (cookies.cookieConsent) {
            setIsVisible(false);
            if (cookies.cookieConsent === 'accepted') {
                if (import.meta.env.VITE_LOGGING_ENABLED === 'true') {
                    console.log('Initializing non-essential cookies...');
                }
                initializeNonEssentialCookies();
            } else if (cookies.cookieConsent === 'essential') {
                if (import.meta.env.VITE_LOGGING_ENABLED === 'true') {
                    console.log('Initializing essential cookies only...');
                }
                initializeEssentialCookies();
                removeNonEssentialCookies();
            }
        }
    }, [cookies.cookieConsent, initializeEssentialCookies, initializeNonEssentialCookies, removeNonEssentialCookies]);

    const handleAcceptAll = () => {
        console.log('Accepting all cookies...');
        setCookie('cookieConsent', 'accepted', {path: '/', maxAge: 31536000}); // 1 year = 31536000
        setIsVisible(false);
        initializeNonEssentialCookies();
        ReactGA.event({
            category: 'User',
            action: 'Accepted All Cookies'
        });
    };

    const handleEssentialOnly = () => {
        setCookie('cookieConsent', 'essential', {path: '/', maxAge: 31536000}); // 1 year = 31536000
        setIsVisible(false);
        initializeEssentialCookies();
        removeNonEssentialCookies();
    };

    if (!isVisible) return null;

    return (
        <div
            className="fixed bottom-0 left-0 w-full bg-gradient-to-r from-gray-800 via-gray-600 to-gray-400 text-white p-6 flex flex-col md:flex-row items-center justify-between z-50 shadow-lg animate-slide-up">
            <p className="mb-4 md:mb-0 text-center md:text-left">
                {t("consent-banner-text")}
            </p>
            <div className="flex space-x-4">
                <button
                    onClick={handleAcceptAll}
                    className="bg-green_brand hover:bg-green_dark_brand text-white font-bold py-2 px-4 rounded-lg transition duration-300 transform hover:scale-105"
                >
                    {t("consent-all-button")}
                </button>
                <button
                    onClick={handleEssentialOnly}
                    className="bg-green_dark_brand hover:bg-green_darker_brand text-white font-bold py-2 px-4 rounded-lg transition duration-300 transform hover:scale-105"
                >
                    {t("consent-essential-button")}
                </button>
            </div>
        </div>
    );
};

export default CookieConsentBanner;
