import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";
import i18n from "i18next";

const TermsConditions = () => {
    const {t} = useTranslation();

    return (
        <>
            <Helmet>
                {/* General SEO */}
                <title>{t("helmet-terms-title")}</title>
                <meta name="description" content={t("helmet-terms-description")}/>
                <meta name="keywords" content={t("helmet-terms-keywords")}/>

                {/* Open Graph for social media sharing */}
                <meta property="og:title" content={t("helmet-terms-title")}/>
                <meta property="og:description" content={t("helmet-terms-description")}/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={i18n.language === 'de'
                    ? 'https://www.2xwd.de/agb'
                    : 'https://www.2xwd.com/terms-conditions'}/>
                <meta property="og:image" content={t("helmet-terms-image-url")}/>

                {/* Twitter Card for social sharing */}
                <meta name="twitter:card" content={t("helmet-terms-twitter-card")}/>
                <meta name="twitter:title" content={t("helmet-terms-title")}/>
                <meta name="twitter:description" content={t("helmet-terms-description")}/>
                <meta name="twitter:image" content={t("helmet-terms-image-url")}/>

                {/* Additional SEO and Meta Tags */}
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href={i18n.language === 'de'
                    ? 'https://www.2xwd.de/agb'
                    : 'https://www.2xwd.com/terms-conditions'}/>

                {/* hreflang tags for alternate language versions */}
                <link rel="alternate" href="https://www.2xwd.de/agb" hrefLang="de"/>
                <link rel="alternate" href="https://www.2xwd.com/terms-conditions" hrefLang="en"/>
            </Helmet>


            <div className="common-padding bg-white flex flex-col items-center text-black w-full">
                <h1 className="py-12 font-semibold break-words">{t("terms-conditions-header")}</h1>
                <div className="items-start w-full">
                    <div className="text-3xl font-semibold py-8">{t("terms-conditions-title-section-1")}</div>
                    <div className="text-2xl font-semibold pb-8">{t("terms-conditions-section-1-title")}</div>
                    <p className="pb-8">{t("terms-conditions-section-1-text")}</p>
                    <div className="text-2xl font-semibold pb-8">{t("terms-conditions-section-1.2-title")}</div>
                    <p className="pb-8">{t("terms-conditions-section-1.2-text")}</p>
                    <div className="text-2xl font-semibold pb-8">{t("terms-conditions-section-1.3-title")}</div>
                    <p className="pb-8">{t("terms-conditions-section-1.3-text")}</p>

                    {/*Contract*/}
                    <div className="text-3xl font-semibold py-8">{t("terms-conditions-title-section-2")}
                    </div>
                    <div className="text-2xl font-semibold pb-8">{t("terms-conditions-contract-section-2-title")}</div>
                    <p className="pb-8">{t("terms-conditions-contract-section-2-text")}</p>
                    <div className="text-2xl font-semibold pb-8">{t("terms-conditions-payment-section-2-title")}</div>
                    <p className="pb-8">{t("terms-conditions-payment-section-2-text")}</p>
                    <div className="text-2xl font-semibold pb-8">{t("terms-conditions-pricing-section-2-title")}</div>
                    <p className="pb-8">{t("terms-conditions-pricing-section-2-text-1")}</p>
                    <p className="pb-8">{t("terms-conditions-pricing-section-2-text-2")}</p>
                    <p className="pb-8">{t("terms-conditions-pricing-section-2-text-3")}</p>
                    <p className="pb-8">{t("terms-conditions-pricing-section-2-text-4")}</p>
                    <div
                        className="text-2xl font-semibold pb-8">{t("terms-conditions-payment-default-section-2-title")}</div>
                    <p className="pb-8">{t("terms-conditions-payment-default-section-2-text")}</p>
                    <div
                        className="text-2xl font-semibold pb-8">{t("terms-conditions-service-suspension-section-2-title")}</div>
                    <p className="pb-8">{t("terms-conditions-service-suspension-section-2-text")}</p>
                    <div
                        className="text-2xl font-semibold pb-8">{t("terms-conditions-contract-duration-section-2-title")}</div>
                    <p className="pb-8">{t("terms-conditions-contract-duration-section-2-text-1")}</p>
                    <p className="pb-8">{t("terms-conditions-contract-duration-section-2-text-2")}</p>

                    {/*Subject of performance, defects*/}
                    <div className="text-3xl font-semibold py-8">{t("terms-conditions-title-section-3")}</div>
                    <div
                        className="text-2xl font-semibold pb-8">{t("terms-conditions-scope-performance-section-3-title")}</div>
                    <p className="pb-8">{t("terms-conditions-scope-performance-section-3-text")}</p>
                    <div className="text-2xl font-semibold pb-8">{t("terms-conditions-state-art-section-3-title")}</div>
                    <p className="pb-8">2{t("terms-conditions-state-art-section-3-title")}</p>
                    <div
                        className="text-2xl font-semibold pb-8">{t("terms-conditions-service-timing-section-3-title")}</div>
                    <p className="pb-8">{t("terms-conditions-service-timing-section-3-text")}</p>
                    <div
                        className="text-2xl font-semibold pb-8">{t("terms-conditions-partial-services-section-3-title")}</div>
                    <p className="pb-8">{t("terms-conditions-partial-services-section-3-text")}</p>
                    <div
                        className="text-2xl font-semibold pb-8">{t("terms-conditions-subcontractors-section-3-title")}</div>
                    <p className="pb-8">{t("terms-conditions-subcontractors-section-3-text")}</p>
                    <div
                        className="text-2xl font-semibold pb-8">{t("terms-conditions-defect-reporting-section-3-title")}</div>
                    <p className="pb-8">{t("terms-conditions-defect-reporting-section-3-text")}</p>
                    <div
                        className="text-2xl font-semibold pb-8">{t("terms-conditions-customer-reminder-section-3-title")}</div>
                    <p className="pb-8">{t("terms-conditions-customer-reminder-section-3-text")}</p>

                    {/*Customer duties*/}
                    <div className="text-3xl font-semibold py-8">{t("terms-conditions-title-section-4")}</div>
                    <div
                        className="text-2xl font-semibold pb-8">{t("terms-conditions-accuracy-data-section-4-title")}</div>
                    <p className="pb-8">{t("terms-conditions-accuracy-data-section-4-text")}</p>
                    <div
                        className="text-2xl font-semibold pb-8">{t("terms-conditions-lawful-use-section-4-title")}</div>
                    <p className="pb-8">{t("terms-conditions-lawful-use-section-4-text")}</p>
                    <div
                        className="text-2xl font-semibold pb-8">{t("terms-conditions-cooperation-section-4-title")}</div>
                    <p className="pb-8">{t("terms-conditions-cooperation-section-4-text")}</p>
                    <div
                        className="text-2xl font-semibold pb-8">{t("terms-conditions-notification-3rd-party-section-4-title")}</div>
                    <p className="pb-8">{t("terms-conditions-notification-3rd-party-section-4-text")}</p>
                    <div
                        className="text-2xl font-semibold pb-8">{t("terms-conditions-data-backup-section-4-title")}</div>
                    <p className="pb-8">{t("terms-conditions-data-backup-section-4-text")}</p>

                    {/*Customer duties*/}
                    <div className="text-3xl font-semibold py-8">{t("terms-conditions-title-section-5")}</div>
                    <div className="text-base pb-8">{t("terms-conditions-title-section-5-text-1")}
                        <ul className="list-disc pl-5">
                            <li className="py-8">{t("terms-conditions-title-section-5-text-2")}
                            </li>
                            <li className="pb-8">{t("terms-conditions-title-section-5-text-3")}
                            </li>
                            <li className="pb-8">{t("terms-conditions-title-section-5-text-4")}
                            </li>
                            <li className="pb-8">{t("terms-conditions-title-section-5-text-5")}
                            </li>
                            <li>{t("terms-conditions-title-section-5-text-6")}
                            </li>
                        </ul>
                    </div>
                    <p className="pb-8">{t("terms-conditions-title-section-5-text-7")}</p>

                    {/*Intellectual Property*/}
                    <div className="text-3xl font-semibold py-8">{t("terms-conditions-title-section-6")}</div>
                    <p className="pb-8">{t("terms-conditions-title-section-6-text-1")}</p>
                    <p className="pb-8">{t("terms-conditions-title-section-6-text-2")}</p>
                    <p className="pb-8">{t("terms-conditions-title-section-6-text-3")}</p>
                    <p className="pb-8">{t("terms-conditions-title-section-6-text-4")}</p>

                    {/*Websites and Support*/}
                    <div className="text-3xl font-semibold py-8">{t("terms-conditions-title-section-7")}</div>
                    <p className="pb-8">{t("terms-conditions-title-section-7-text")}</p>

                    {/*Websites and Support*/}
                    <div className="text-3xl font-semibold py-8">{t("terms-conditions-title-section-8")}</div>
                    <div className="text-2xl font-semibold pb-8">{t("terms-conditions-title-section-8-title")}</div>
                    <p className="pb-8">{t("terms-conditions-title-section-8-text")}</p>

                    {/*Service Level*/}
                    <div className="text-3xl font-semibold py-8">{t("terms-conditions-title-section-9")}</div>
                    <p className="pb-8">{t("terms-conditions-title-section-9-text")}</p>

                    {/*Final Provisions*/}
                    <div className="text-3xl font-semibold py-8">{t("terms-conditions-title-section-10")}</div>
                    <div className="text-2xl font-semibold pb-8">{t("terms-conditions-title-section-10-title-1")}</div>
                    <p className="pb-8">{t("terms-conditions-title-section-10-text-1")}</p>
                    <div className="text-2xl font-semibold pb-8">{t("terms-conditions-title-section-10-title-2")}
                    </div>
                    <p className="pb-8">{t("terms-conditions-title-section-10-text-2")}</p>
                    <div className="text-2xl font-semibold pb-8">{t("terms-conditions-title-section-10-title-3")}</div>
                    <p className="pb-8">{t("terms-conditions-title-section-10-text-3")}</p>
                    <div className="text-2xl font-semibold pb-8">{t("terms-conditions-title-section-10-title-4")}</div>
                    <p className="pb-8">{t("terms-conditions-title-section-10-text-4")}</p>
                </div>
            </div>
        </>
    )
}
export default TermsConditions
