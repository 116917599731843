import React, {useState} from "react";
import {AnimatePresence, motion} from "framer-motion";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import TrackableButton from "./TrackableButton.tsx";
import {Helmet} from "react-helmet-async";

export default function SlidePricing() {
    const {t} = useTranslation();
    const [selected, setSelected] = useState<"M" | "A">("M");

    return (
        <>
            <Helmet>
                {/*<title>{t("helmet-sliding-pricing-title")}</title>*/}
                <meta name="description"
                      content={t("helmet-sliding-pricing-description")}/>
                <meta name="keywords"
                      content={t("helmet-sliding-pricing-keywords")}/>
            </Helmet>

            <section className="w-full text-black bg-white px-4 lg:px-8 py-12 lg:py-24 relative overflow-hidden">
                <Heading selected={selected} setSelected={setSelected}/>
                <PriceCards selected={selected}/>
                <TopLeftCircle/>
                <BottomRightCircle/>
            </section>
        </>
    );
}

const SELECTED_STYLES = "text-white font-medium rounded-lg py-3 w-28 relative";
const DESELECTED_STYLES =
    "font-medium text-gray shadow-lg rounded-lg py-3 w-28 hover:bg-slate-100 transition-colors relative";

interface HeadingProps {
    selected: "M" | "A";
    setSelected: React.Dispatch<React.SetStateAction<"M" | "A">>;
}

const Heading = ({selected, setSelected}: HeadingProps) => {
    const {t} = useTranslation();
    return (
        <div className="mb-12 lg:mb-24 relative z-10">
            <h3 className="font-semibold text-5xl lg:text-7xl text-center mb-6">
                {t("pricing-compare-title")}
            </h3>
            <p className="text-center mx-auto max-w-lg mb-8">
                {t("pricing-compare-title-text")}
            </p>
            <div className="flex items-center justify-center gap-3">
                <TrackableButton
                    category="CTA"
                    action="Click"
                    label="Price monthly button"
                    onClick={() => setSelected("M")}
                    className={selected === "M" ? SELECTED_STYLES : DESELECTED_STYLES}
                >
                    {t("pricing-compare-monthly")}
                    {selected === "M" && <BackgroundShift/>}
                </TrackableButton>
                <div className="relative">
                    <TrackableButton
                        category="CTA"
                        action="Click"
                        label="Price once time button"
                        onClick={() => setSelected("A")}
                        className={selected === "A" ? SELECTED_STYLES : DESELECTED_STYLES}
                    >
                        {t("pricing-compare-once")}
                        {selected === "A" && <BackgroundShift/>}
                    </TrackableButton>
                    <CTAArrow/>
                </div>
            </div>
        </div>
    );
};

const BackgroundShift = () => (
    <motion.span
        layoutId="bg-shift"
        className="absolute inset-0 bg-black rounded-lg -z-10"
    />
);

const CTAArrow = () => {
    const {t} = useTranslation();

    return (
        <div className="absolute -right-[90px] md:-right-[100px] top-2 sm:top-0">
            <motion.svg
                width="95"
                height="62"
                viewBox="0 0 95 62"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="scale-50 sm:scale-75"
                initial={{scale: 0.7, rotate: 5}}
                animate={{scale: 0.75, rotate: 0}}
                transition={{
                    repeat: Infinity,
                    repeatType: "mirror",
                    duration: 1,
                    ease: "easeOut",
                }}
            >
                <path
                    d="M14.7705 15.8619C33.2146 15.2843 72.0772 22.1597 79.9754 54.2825"
                    stroke="#00ADB5"
                    strokeWidth="3"
                />
                <path
                    d="M17.7987 7.81217C18.0393 11.5987 16.4421 15.8467 15.5055 19.282C15.2179 20.3369 14.9203 21.3791 14.5871 22.4078C14.4728 22.7608 14.074 22.8153 13.9187 23.136C13.5641 23.8683 12.0906 22.7958 11.7114 22.5416C8.63713 20.4812 5.49156 18.3863 2.58664 15.9321C1.05261 14.6361 2.32549 14.1125 3.42136 13.0646C4.37585 12.152 5.13317 11.3811 6.22467 10.7447C8.97946 9.13838 12.7454 8.32946 15.8379 8.01289"
                    stroke="#00ADB5"
                    strokeWidth="3"
                    strokeLinecap="round"
                />
            </motion.svg>
            <span
                className="block text-xs w-fit bg-green_brand text-white shadow px-1.5 py-0.5 rounded -mt-1 ml-8 -rotate-2 font-light italic animate-pulse">
      {t("pricing-compare-arrow-text")}
    </span>
        </div>
    )
}


interface PriceCardProps {
    selected: "M" | "A";
}

const PriceCards = ({selected}: PriceCardProps) => {
    const {t} = useTranslation();
    return (
        <div
            className="flex flex-col xl:flex-row gap-8 lg:gap-4 w-full max-w-[88rem] mx-auto relative z-10 font-poppins">
            {/* FREE */}
            <div className="w-full xl:w-1/4 bg-white p-6 border-[1px] border-slate-300 rounded-xl">
                <p className="text-2xl font-bold mb-2">Starter</p>
                <p className="text-lg text-neutral-400 mb-6">{t("pricing-starter-compare-text")}</p>
                <div className="">
                    <AnimatePresence mode="wait">
                        {selected === "M" ? (
                            <motion.p
                                key="monthly1"
                                initial={{y: -50, opacity: 0}}
                                animate={{y: 0, opacity: 1}}
                                exit={{y: 50, opacity: 0}}
                                transition={{ease: "linear", duration: 0.25}}
                                className="text-6xl lg:text-5xl font-bold text-gray-600 mb-0 flex items-center"
                            >
                                <span className="text-gray-600">{t("pricing-starter-compare-price-monthly")}</span>
                                <span
                                    className="font-normal text-gray-600 text-xl">/{t("pricing-compare-monthly")}</span>
                                <button className="tooltip__price_trigger relative ml-2">
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        role="img"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                            fill="#A2A2A2"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                            fill="#A2A2A2"
                                        />
                                        <path
                                            d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                            fill="#A2A2A2"
                                        />
                                    </svg>
                                    <motion.div
                                        className="tooltip__price_dialog"
                                        initial={{opacity: 0, scale: 0.8}}
                                        animate={{opacity: 1, scale: 1}}
                                        exit={{opacity: 0, scale: 0.8}}
                                        transition={{duration: 0.2}}>
                                        <p>{t("pricing-starter-compare-price-monthly-popup-text")}</p>
                                    </motion.div>
                                </button>
                            </motion.p>
                        ) : (
                            <motion.p
                                key="monthly2"
                                initial={{y: -50, opacity: 0}}
                                animate={{y: 0, opacity: 1}}
                                exit={{y: 50, opacity: 0}}
                                transition={{ease: "linear", duration: 0.25}}
                                className="text-6xl lg:text-5xl font-bold text-indigo-500 flex items-center"
                            >
                                <span
                                    className="bg-clip-text text-transparent bg-gradient-to-r from-black to-green_brand">{t("pricing-starter-compare-price-once")}</span>
                                <span className="font-normal text-black text-xl">/{t("pricing-compare-once")}</span>
                                <button className="tooltip__price_trigger relative ml-2">
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        role="img"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                            fill="#A2A2A2"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                            fill="#A2A2A2"
                                        />
                                        <path
                                            d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                            fill="#A2A2A2"
                                        />
                                    </svg>
                                    <motion.div
                                        className="tooltip__price_dialog"
                                        initial={{opacity: 0, scale: 0.8}}
                                        animate={{opacity: 1, scale: 1}}
                                        exit={{opacity: 0, scale: 0.8}}
                                        transition={{duration: 0.2}}>
                                        <p>{t("pricing-starter-compare-price-once-popup-text")}</p>
                                    </motion.div>
                                </button>
                            </motion.p>
                        )}
                    </AnimatePresence>
                </div>

                <Link to={`/${t("route-builds-starter")}`}>
                    <motion.button
                        whileHover={{scale: 1.015}}
                        whileTap={{scale: 0.985}}
                        className="w-full py-4 my-4 font-semibold bg-black text-white rounded-lg hover:bg-gradient-to-b from-green_darker_brand to-green_lighter_brand duration-500"
                    >
                        {t("pricing-compare-starter-button")}
                    </motion.button>
                </Link>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-compare-list-1")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-compare-popup-text-1")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-compare-list-2")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-compare-popup-text-2")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-compare-list-3")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-compare-popup-text-3")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-starter-compare-list-1")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-starter-popup-text-1")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-starter-compare-list-2")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-starter-popup-text-2")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-starter-compare-list-3")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-starter-popup-text-3")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-starter-compare-list-4")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-starter-popup-text-4")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-starter-compare-list-5")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-starter-popup-text-5")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-starter-compare-list-6")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-starter-popup-text-6")}</p>
                        </div>
                    </button>
                </div>
            </div>

            {/* STANDARD  */}
            <div className="w-full xl:w-1/4 bg-white p-6 border-[1px] border-slate-300 rounded-xl">
                <p className="text-2xl font-bold mb-2">Standard</p>
                <p className="text-lg text-neutral-400 mb-6">{t("pricing-standard-compare-text")}</p>
                <div className="">
                    <AnimatePresence mode="wait">
                        {selected === "M" ? (
                            <motion.p
                                key="monthly1"
                                initial={{y: -50, opacity: 0}}
                                animate={{y: 0, opacity: 1}}
                                exit={{y: 50, opacity: 0}}
                                transition={{ease: "linear", duration: 0.25}}
                                className="text-6xl lg:text-5xl font-bold text-gray-600 mb-0 flex items-center"
                            >
                                <span className="text-gray-600">{t("pricing-standard-compare-price-monthly")}</span>
                                <span
                                    className="font-normal text-gray-600 text-xl">/{t("pricing-compare-monthly")}</span>
                                <button className="tooltip__price_trigger relative ml-2">
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        role="img"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                            fill="#A2A2A2"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                            fill="#A2A2A2"
                                        />
                                        <path
                                            d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                            fill="#A2A2A2"
                                        />
                                    </svg>
                                    <motion.div
                                        className="tooltip__price_dialog"
                                        initial={{opacity: 0, scale: 0.8}}
                                        animate={{opacity: 1, scale: 1}}
                                        exit={{opacity: 0, scale: 0.8}}
                                        transition={{duration: 0.2}}>
                                        <p>{t("pricing-standard-compare-price-monthly-popup-text")}</p>
                                    </motion.div>
                                </button>
                            </motion.p>
                        ) : (
                            <motion.p
                                key="monthly2"
                                initial={{y: -50, opacity: 0}}
                                animate={{y: 0, opacity: 1}}
                                exit={{y: 50, opacity: 0}}
                                transition={{ease: "linear", duration: 0.25}}
                                className="text-6xl lg:text-5xl font-bold text-indigo-500 flex items-center"
                            >
                                <span
                                    className="bg-clip-text text-transparent bg-gradient-to-r from-black to-green_brand">{t("pricing-standard-compare-price-once")}</span>
                                <span className="font-normal text-black text-xl">/{t("pricing-compare-once")}</span>
                                <button className="tooltip__price_trigger relative ml-2">
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        role="img"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                            fill="#A2A2A2"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                            fill="#A2A2A2"
                                        />
                                        <path
                                            d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                            fill="#A2A2A2"
                                        />
                                    </svg>
                                    <motion.div
                                        className="tooltip__price_dialog"
                                        initial={{opacity: 0, scale: 0.8}}
                                        animate={{opacity: 1, scale: 1}}
                                        exit={{opacity: 0, scale: 0.8}}
                                        transition={{duration: 0.2}}>
                                        <p>{t("pricing-standard-compare-price-once-popup-text")}</p>
                                    </motion.div>
                                </button>
                            </motion.p>
                        )}
                    </AnimatePresence>
                </div>

                <Link to={`/${t("route-builds-standard")}`}>
                    <motion.button
                        whileHover={{scale: 1.015}}
                        whileTap={{scale: 0.985}}
                        className="w-full py-4 my-4 font-semibold bg-black text-white rounded-lg hover:bg-gradient-to-b from-green_darker_brand to-green_lighter_brand duration-500"
                    >
                        {t("pricing-compare-standard-button")}
                    </motion.button>
                </Link>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-compare-list-1")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-compare-popup-text-1")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-compare-list-2")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-compare-popup-text-2")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-compare-list-3")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-compare-popup-text-3")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-standard-compare-list-1")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-standard-popup-text-1")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-standard-compare-list-2")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-standard-popup-text-2")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-standard-compare-list-3")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-standard-popup-text-3")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-standard-compare-list-4")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-standard-popup-text-4")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-standard-compare-list-5")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-standard-popup-text-5")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-standard-compare-list-6")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-standard-popup-text-6")}</p>
                        </div>
                    </button>
                </div>
            </div>

            {/* PRO  */}
            <div className="w-full xl:w-1/4 bg-white p-6 border-[1px] border-slate-300 rounded-xl">
                <p className="text-2xl font-bold mb-2">Professional</p>
                <p className="text-lg text-neutral-400 mb-6">{t("pricing-professional-compare-text")}</p>
                <div className="">
                    <AnimatePresence mode="wait">
                        {selected === "M" ? (
                            <motion.p
                                key="monthly1"
                                initial={{y: -50, opacity: 0}}
                                animate={{y: 0, opacity: 1}}
                                exit={{y: 50, opacity: 0}}
                                transition={{ease: "linear", duration: 0.25}}
                                className="text-6xl lg:text-5xl font-bold text-gray-600 mb-0 flex items-center"
                            >
                                <span className="text-gray-600">{t("pricing-professional-compare-price-monthly")}</span>
                                <span
                                    className="font-normal text-gray-600 text-xl">/{t("pricing-compare-monthly")}</span>
                                <button className="tooltip__price_trigger relative ml-2">
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        role="img"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                            fill="#A2A2A2"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                            fill="#A2A2A2"
                                        />
                                        <path
                                            d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                            fill="#A2A2A2"
                                        />
                                    </svg>
                                    <motion.div
                                        className="tooltip__price_dialog"
                                        initial={{opacity: 0, scale: 0.8}}
                                        animate={{opacity: 1, scale: 1}}
                                        exit={{opacity: 0, scale: 0.8}}
                                        transition={{duration: 0.2}}>
                                        <p>{t("pricing-professional-compare-price-monthly-popup-text")}</p>
                                    </motion.div>
                                </button>
                            </motion.p>
                        ) : (
                            <motion.p
                                key="monthly2"
                                initial={{y: -50, opacity: 0}}
                                animate={{y: 0, opacity: 1}}
                                exit={{y: 50, opacity: 0}}
                                transition={{ease: "linear", duration: 0.25}}
                                className="text-6xl lg:text-5xl font-bold text-indigo-500 flex items-center"
                            >
                                <span
                                    className="bg-clip-text text-transparent bg-gradient-to-r from-black to-green_brand">{t("pricing-professional-compare-price-once")}</span>
                                <span className="font-normal text-black text-xl">/{t("pricing-compare-once")}</span>
                                <button className="tooltip__price_trigger relative ml-2">
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        role="img"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                            fill="#A2A2A2"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                            fill="#A2A2A2"
                                        />
                                        <path
                                            d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                            fill="#A2A2A2"
                                        />
                                    </svg>
                                    <motion.div
                                        className="tooltip__price_dialog"
                                        initial={{opacity: 0, scale: 0.8}}
                                        animate={{opacity: 1, scale: 1}}
                                        exit={{opacity: 0, scale: 0.8}}
                                        transition={{duration: 0.2}}>
                                        <p>{t("pricing-professional-compare-price-once-popup-text")}</p>
                                    </motion.div>
                                </button>
                            </motion.p>
                        )}
                    </AnimatePresence>
                </div>

                <Link to={`/${t("route-builds-professional")}`}>
                    <motion.button
                        whileHover={{scale: 1.015}}
                        whileTap={{scale: 0.985}}
                        className="w-full py-4 my-4 font-semibold bg-black text-white rounded-lg hover:bg-gradient-to-b from-green_darker_brand to-green_lighter_brand duration-500"
                    >
                        {t("pricing-compare-professional-button")}
                    </motion.button>
                </Link>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-compare-list-1")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-compare-popup-text-1")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-compare-list-2")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-compare-popup-text-2")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-compare-list-3")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-compare-popup-text-3")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-professional-compare-list-1")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-professional-popup-text-1")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-professional-compare-list-2")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-professional-popup-text-2")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-professional-compare-list-3")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-professional-popup-text-3")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-professional-compare-list-4")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-professional-popup-text-4")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-professional-compare-list-5")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-professional-popup-text-5")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-professional-compare-list-6")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-professional-popup-text-6")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-professional-compare-list-7")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-professional-popup-text-7")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-professional-compare-list-8")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-professional-popup-text-8")}</p>
                        </div>
                    </button>
                </div>
            </div>

            {/* ENTERPRISE */}
            <div className="w-full xl:w-1/4 bg-white p-6 border-[1px] border-slate-300 rounded-xl">
                <p className="text-2xl font-bold mb-2">Enterprise</p>
                <p className="text-lg text-neutral-400 mb-6">{t("pricing-enterprise-compare-text")}</p>
                <div className="">
                    <AnimatePresence mode="wait">
                        {selected === "M" ? (
                            <motion.p
                                key="monthly1"
                                initial={{y: -50, opacity: 0}}
                                animate={{y: 0, opacity: 1}}
                                exit={{y: 50, opacity: 0}}
                                transition={{ease: "linear", duration: 0.25}}
                                className="text-6xl lg:text-5xl font-bold text-gray-600 mb-0 flex items-center"
                            >
                                <span className="text-gray-600">{t("pricing-enterprise-compare-price-monthly")}</span>
                                <span
                                    className="font-normal text-gray-600 text-xl">/{t("pricing-compare-monthly")}</span>
                                <button className="tooltip__price_trigger relative ml-2">
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        role="img"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                            fill="#A2A2A2"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                            fill="#A2A2A2"
                                        />
                                        <path
                                            d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                            fill="#A2A2A2"
                                        />
                                    </svg>
                                    <motion.div
                                        className="tooltip__price_dialog"
                                        initial={{opacity: 0, scale: 0.8}}
                                        animate={{opacity: 1, scale: 1}}
                                        exit={{opacity: 0, scale: 0.8}}
                                        transition={{duration: 0.2}}>
                                        <p>{t("pricing-enterprise-compare-price-monthly-popup-text")}</p>
                                    </motion.div>
                                </button>
                            </motion.p>
                        ) : (
                            <motion.p
                                key="monthly2"
                                initial={{y: -50, opacity: 0}}
                                animate={{y: 0, opacity: 1}}
                                exit={{y: 50, opacity: 0}}
                                transition={{ease: "linear", duration: 0.25}}
                                className="text-6xl lg:text-5xl font-bold text-black flex items-center"
                            >
                                <span
                                    className="bg-clip-text text-transparent bg-gradient-to-r from-black to-green_brand">{t("pricing-enterprise-compare-price-once")}
                                </span>
                                <span className="font-normal text-black text-xl">/{t("pricing-compare-once")}</span>
                                <button className="tooltip__price_trigger relative ml-2">
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        role="img"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                            fill="#A2A2A2"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                            fill="#A2A2A2"
                                        />
                                        <path
                                            d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                            fill="#A2A2A2"
                                        />
                                    </svg>
                                    <motion.div
                                        className="tooltip__price_dialog"
                                        initial={{opacity: 0, scale: 0.8}}
                                        animate={{opacity: 1, scale: 1}}
                                        exit={{opacity: 0, scale: 0.8}}
                                        transition={{duration: 0.2}}>
                                        <p>{t("pricing-enterprise-compare-price-once-popup-text")}</p>
                                    </motion.div>
                                </button>
                            </motion.p>
                        )}
                    </AnimatePresence>
                </div>

                <Link to={`/${t("route-builds-enterprise")}`}>
                    <motion.button
                        whileHover={{scale: 1.015}}
                        whileTap={{scale: 0.985}}
                        className="w-full py-4 my-4 font-semibold bg-black text-white rounded-lg hover:bg-gradient-to-b from-green_darker_brand to-green_lighter_brand duration-500"
                    >
                        {t("pricing-compare-enterprise-button")}
                    </motion.button>
                </Link>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-compare-list-1")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-compare-popup-text-1")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-compare-list-2")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-compare-popup-text-2")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-compare-list-3")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-compare-popup-text-3")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-enterprise-compare-list-1")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-enterprise-popup-text-1")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-enterprise-compare-list-2")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-enterprise-popup-text-2")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-enterprise-compare-list-3")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-enterprise-popup-text-3")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-enterprise-compare-list-4")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-enterprise-popup-text-4")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-enterprise-compare-list-5")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-enterprise-popup-text-5")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-enterprise-compare-list-6")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-enterprise-popup-text-6")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-enterprise-compare-list-7")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-enterprise-popup-text-7")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-enterprise-compare-list-8")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-enterprise-popup-text-8")}</p>
                        </div>
                    </button>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0"
                    >
                        <path
                            d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z"
                            fill="black"
                        />
                    </svg>
                    <span className="text-base">{t("pricing-enterprise-compare-list-9")}</span>
                    <button className="tooltip__trigger relative">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 14.2222C11.4364 14.2222 14.2222 11.4364 14.2222 8C14.2222 4.56356 11.4364 1.77778 8 1.77778C4.56356 1.77778 1.77778 4.56356 1.77778 8C1.77778 11.4364 4.56356 14.2222 8 14.2222ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                                fill="#A2A2A2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.88889 6.22222V12.4444H7.11111V6.22222H8.88889Z"
                                fill="#A2A2A2"
                            />
                            <path
                                d="M8.88889 5.33333H7.11111V3.55556H8.88889V5.33333Z"
                                fill="#A2A2A2"
                            />
                        </svg>
                        <div className="tooltip__dialog absolute hidden">
                            <p>{t("pricing-enterprise-popup-text-9")}</p>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
}

const TopLeftCircle = () => {
    return (
        <motion.div
            initial={{rotate: "0deg"}}
            animate={{rotate: "360deg"}}
            transition={{duration: 100, ease: "linear", repeat: Infinity}}
            className="w-[450px] h-[450px] rounded-full border-2 border-slate-500 border-dotted absolute z-0 -left-[250px] -top-[200px]"
        />
    );
};

const BottomRightCircle = () => {
    return (
        <motion.div
            initial={{rotate: "0deg"}}
            animate={{rotate: "-360deg"}}
            transition={{duration: 100, ease: "linear", repeat: Infinity}}
            className="w-[450px] h-[450px] rounded-full border-2 border-slate-500 border-dotted absolute z-0 -right-[250px] -bottom-[200px]"
        />
    );
};