import {MouseEventHandler, useEffect, useState} from "react";
import {useAnimate} from "framer-motion";
import TrackableButton from "../components/TrackableButton.tsx";
import i18n, {t} from "i18next";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet-async";


export const Thankyou = () => {
    const [scope, animate] = useAnimate();

    const [size, setSize] = useState({columns: 0, rows: 0});

    useEffect(() => {
        generateGridCount();
        window.addEventListener("resize", generateGridCount);

        return () => window.removeEventListener("resize", generateGridCount);
    }, []);

    const generateGridCount = () => {
        const columns = Math.floor(document.body.clientWidth / 75);
        const rows = Math.floor(document.body.clientHeight / 75);

        setSize({
            columns,
            rows,
        });
    };

    const handleMouseLeave: MouseEventHandler<HTMLDivElement> = (e) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const id = `#${e.target.id}`;
        animate(id, {background: "rgba(0, 173, 181, 0)"}, {duration: 1.5});
    };

    const handleMouseEnter: MouseEventHandler<HTMLDivElement> = (e) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const id = `#${e.target.id}`;
        animate(id, {background: "rgba(0, 173, 181, 1)"}, {duration: 0.15});
    };

    return (
        <>
            <Helmet>
                {/* General SEO */}
                <title>{t("helmet-thank-you-title")}</title>
                <meta name="description" content={t("helmet-thank-you-description")}/>
                <meta name="keywords" content={t("helmet-thank-you-keywords")}/>

                {/* Open Graph for social media sharing */}
                <meta property="og:title" content={t("helmet-thank-you-title")}/>
                <meta property="og:description" content={t("helmet-thank-you-description")}/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={i18n.language === 'de'
                    ? 'https://www.2xwd.de/danke'
                    : 'https://www.2xwd.com/thank-you'}/>
                <meta property="og:image" content={t("helmet-thank-you-image-url")}/>

                {/* Twitter Card for social sharing */}
                <meta name="twitter:card" content={t("helmet-thank-you-twitter-card")}/>
                <meta name="twitter:title" content={t("helmet-thank-you-title")}/>
                <meta name="twitter:description" content={t("helmet-thank-you-description")}/>
                <meta name="twitter:image" content={t("helmet-thank-you-image-url")}/>

                {/* Additional SEO and Meta Tags */}
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href={i18n.language === 'de'
                    ? 'https://www.2xwd.de/danke'
                    : 'https://www.2xwd.com/thank-you'}/>

                {/* hreflang tags for alternate language versions */}
                <link rel="alternate" href="https://www.2xwd.de/danke" hrefLang="de"/>
                <link rel="alternate" href="https://www.2xwd.com/thank-you" hrefLang="en"/>
            </Helmet>


            <div className="bg-neutral-950 font-poppins">
                <div
                    ref={scope}
                    className="grid h-screen w-full grid-cols-[repeat(auto-fit,_minmax(75px,_1fr))] grid-rows-[repeat(auto-fit,_minmax(75px,_1fr))]"
                >
                    {[...Array(size.rows * size.columns)].map((_, i) => (
                        <div
                            key={i}
                            id={`square-${i}`}
                            onMouseLeave={handleMouseLeave}
                            onMouseEnter={handleMouseEnter}
                            className="h-full w-full border-[1px] border-neutral-900"
                        />
                    ))}
                </div>
                <div className="pointer-events-none absolute inset-0 flex flex-col items-center justify-center p-8">
                    <h1 className="text-center text-7xl font-black uppercase text-white sm:text-8xl md:text-9xl">
                        {t("thank-you-title")}
                    </h1>
                    <p className="mb-6 mt-4 max-w-3xl text-center text-lg font-light text-neutral-500 md:text-xl">
                        {t("thank-you-text")}
                    </p>
                    <Link to="/">
                        <TrackableButton
                            category="CTA"
                            action="Click"
                            label="Thank you page - Home"
                            className="pointer-events-auto bg-green_brand px-4 py-2 text-xl font-bold text-white mix-blend-difference">
                            {t("thank-you-button")}
                        </TrackableButton>
                    </Link>
                </div>

            </div>
        </>
    );
};