import {CiCirclePlus} from "react-icons/ci";
import {useGSAP} from "@gsap/react";
import {animateFromWithGsap} from "../utils/animations.tsx";
import {useTranslation} from "react-i18next";
import gsap from "gsap";

const AddOnsStarter = () => {
    const {t} = useTranslation();

    useGSAP(() => {
        gsap.to(".pricing-title", {opacity: 1, y: 0, delay: 1})
        animateFromWithGsap('.add-ons-starter', {x: 0, opacity: 1, delay: 0.5, duration: 1});
    }, []);
    return (
        <section className="add-ons-starter opacity-0 py-24 bg-[#ededed] font-poppins">
            <div className="container mx-auto px-4 ">
                <div className="flex flex-col items-center w-full">
                    <h1
                        className="pricing-title section-heading text-white_smoke text-3xl md:mb-4 md:text-4xl xl:text-5xl font-bold text-center mb-12 opacity-0">{t("add-on-starter-plan-title")}</h1>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    <div className="bg-white p-6 rounded-lg shadow-lg text-left">
                        <div className="flex items-center justify-center">
                            <CiCirclePlus className="text-4xl text-green_brand mb-8 mr-4"/>
                            <h2 className="text-2xl text-black font-bold mb-8">{t("add-on-starter-plan-card-1-title")}</h2>
                        </div>
                        <p className="text-3xl text-neutral-500 text-center mb-8">{t("add-on-starter-plan-card-1-price")}<span
                            className="text-xl">{t("add-on-starter-plan-card-1-price-per")}</span></p>
                        <p className="text-neutral-400 text-left">
                            {t("add-on-starter-plan-card-1-text")}</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                        <div className="flex items-center justify-center">
                            <CiCirclePlus className="text-4xl text-green_brand mb-8 mr-4"/>
                            <h2 className="text-2xl text-black font-bold mb-8">{t("add-on-starter-plan-card-2-title")}</h2>
                        </div>
                        <p className="text-3xl text-neutral-500 text-center mb-8">{t("add-on-starter-plan-card-2-price")}<span
                            className="text-xl">{t("add-on-starter-plan-card-2-price-per")}</span></p>
                        <p className="text-neutral-400 text-left">{t("add-on-starter-plan-card-2-text")}</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                        <div className="flex items-center justify-center">
                            <CiCirclePlus className="text-4xl text-green_brand mb-8 mr-4"/>
                            <h2 className="text-2xl text-black font-bold mb-8">{t("add-on-starter-plan-card-3-title")}</h2>
                        </div>
                        <p className="text-3xl text-neutral-500 text-center mb-8">{t("add-on-starter-plan-card-3-price")}</p>
                        <p className="text-neutral-400 text-left">{t("add-on-starter-plan-card-3-text")}</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                        <div className="flex items-center justify-center">
                            <CiCirclePlus className="text-4xl text-green_brand mb-8 mr-4"/>
                            <h2 className="text-2xl text-black font-bold mb-8">{t("add-on-starter-plan-card-4-title")}</h2>
                        </div>
                        <p className="text-3xl text-neutral-500 text-center mb-8">{t("add-on-starter-plan-card-4-price")}<span
                            className="text-xl">{t("add-on-starter-plan-card-4-price-per")}</span></p>
                        <p className="text-neutral-400 text-left">{t("add-on-starter-plan-card-4-text")}</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                        <div className="flex items-center justify-center">
                            <CiCirclePlus className="text-4xl text-green_brand mb-8 mr-4"/>
                            <h2 className="text-2xl text-black font-bold mb-8">{t("add-on-starter-plan-card-5-title")}</h2>
                        </div>
                        <p className="text-3xl text-neutral-500 text-center mb-8">{t("add-on-starter-plan-card-5-price")}<span
                            className="text-xl">{t("add-on-starter-plan-card-5-price-per")}</span></p>
                        <p className="text-neutral-400 text-left">{t("add-on-starter-plan-card-5-text")}</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                        <div className="flex items-center justify-center">
                            <CiCirclePlus className="text-4xl text-green_brand mb-8 mr-4"/>
                            <h2 className="text-2xl text-black font-bold mb-8">{t("add-on-starter-plan-card-6-title")}</h2>
                        </div>
                        <p className="text-3xl text-neutral-500 text-center mb-8">{t("add-on-starter-plan-card-6-price")}</p>
                        <p className="text-neutral-400 text-left">{t("add-on-starter-plan-card-6-text")}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default AddOnsStarter
