import {useGSAP} from "@gsap/react";
import gsap from "gsap";
import {Cloudinary} from "@cloudinary/url-gen";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {animateWithGsap} from "../utils/animations.tsx";
import React from "react";
import {AdvancedVideo} from "@cloudinary/react";
import TrackableButton from "./TrackableButton.tsx";

interface WhatWeDoProps {
    windowWidth: number;
}

const WhatWeDo: React.FC<WhatWeDoProps> = ({windowWidth}) => {
    const {t} = useTranslation();

    useGSAP(() => {
        gsap.to("#title", {opacity: 1, y: 0, delay: 0.2})
        animateWithGsap('.stagger1', {y: -10, opacity: 1, delay: 0.3})
    }, [])

    const cld = new Cloudinary({
        cloud: {
            cloudName: import.meta.env.VITE_CLOUDINARY_CLOUD_NAME,
        }
    });

    const smallElevateVideo = cld.video(import.meta.env.VITE_CLOUDINARY_ELEVATE_VIDEO).quality('auto')
    const elevateVideo = cld.video(import.meta.env.VITE_CLOUDINARY_SMALL_ELEVATE_VIDEO).quality('auto')

    return (
        <>
            {/*<Helmet>*/}
            {/*    /!*<title>{t("helmet-home-WhatWeDo-title")}</title>*!/*/}
            {/*    <meta*/}
            {/*        name="description"*/}
            {/*        content={t("helmet-home-WhatWeDo-description")}*/}
            {/*    />*/}
            {/*    <meta*/}
            {/*        name="keywords"*/}
            {/*        content={t("helmet-home-WhatWeDo-keywords")}*/}
            {/*    />*/}
            {/*</Helmet>*/}


            <section
                className="w-full overflow-auto font-poppins bg-white">
                <div className="flex flex-col screen-max-width common-padding-no-bottom-padding">
                    <div className="flex flex-col items-center w-full">
                        <h1 id="title"
                            className="section-heading opacity-0">{t("what-we-do-header-text")}</h1>
                    </div>
                    <div className="stagger1 opacity-0 flex pt-12 flex-col lg:flex-row lg:w-full">
                        <div
                            className={windowWidth < 1025 ? "flex flex-col text-center items-center text-green_brand" : "flex flex-col items-left w-[50%] mr-20 text-green_brand"}>
                            <h2 className="stagger1 opacity-0 poppins-semibold pb-1 lg:pb-6 mt-[-1rem]">{t("what-we-do-sub-header-text")}</h2>
                            {windowWidth < 1025 ?
                                <AdvancedVideo
                                    className="rounded-xl"
                                    cldVid={smallElevateVideo}
                                    autoPlay={true}
                                    loop={true}
                                    muted={true}
                                />
                                :
                                <div className="hidden"></div>}
                            <p className="text-gray mt-12 lg:mt-5 text-md text-left lg:text-xl leading-loose lg:leading-[3.25rem]">
                                {t("what-we-do-text")}
                            </p>
                            <div
                                className="flex items-center text-white lg:items-left lg:w-[15rem] translate-y-20 mt-28 lg:my-40">
                                <Link to={`/${t("route-contact")}`}>
                                    <TrackableButton category="CTA"
                                                     action="Click"
                                                     label="WhatWeDo cta button"
                                                     className="cta btn">{t("cta-button-text")}</TrackableButton>
                                </Link>
                            </div>
                        </div>
                        <div className="flex flex-col items-center w-full mt-24 lg:mt-0 lg:w-1/2">
                            {windowWidth < 1025 ?
                                <div className="hidden">

                                </div>
                                :
                                <AdvancedVideo
                                    className="shadow-xl rounded-xl"
                                    cldVid={elevateVideo}
                                    autoPlay={true}
                                    loop={true}
                                    muted={true}
                                />}
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
export default WhatWeDo
